import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import QRCode from "qrcode.react";
import http from "../../../services/backend/http";
import SalesSlip from "./SalesSlip";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import axios from "axios";
import { Buffer } from "buffer";

//import socketIOClient from "socket.io-client";
const generatePayload = require("promptpay-qr");
const SalesTransferModel = (props) => {
  const {
    saleTransferModal,
    setSaleTransferModal,
    saleTableSumCart,
    setSaleListModal,
    setSaleTableSumCart,
    saleTable,
    table,
    sendEmit,
    socketRef,
  } = props;

  const auth = useSelector((state) => state.AuthReducer.auth);
  const [salesSlip, setSalesSlip] = useState(false);
  const [doc, setDoc] = useState("");
  const [change, setChange] = useState(0);
  const [ppIDDetail, setppIDDetail] = useState("");
  const [ppIDDetail1, setppIDDetail1] = useState("");
  const [paymentQR, setPaymentQR] = useState("");
  const [qrCode, setqrCode] = useState("");
  const [transferType, setTransferType] = useState(null);
  const [isLineNoti, setIsLineNoti] = useState(false);
  const [isLoading, setIsLoading] = useState({
    payment: false,
  });
  useEffect(() => {
    if (saleTransferModal === true) {
      getTransfer(saleTableSumCart.grandtotal);

      socketRef.current.on("transferdone", (message) => {
        let referenceNoCheck = `${auth.branch.branch_id.slice(-3)}${
          saleTable[0].so_no
        }`;
        const { company_id, branch_id, referenceNo } = message;
        if (
          company_id === auth.newAuth.user.company_id &&
          branch_id === auth.branch.branch_id &&
          referenceNo === referenceNoCheck
        ) {
          payment();
        }
      });
    } else {
      if (socketRef.current) {
        socketRef.current.removeAllListeners("transferdone");
      }

      setppIDDetail("");
      setppIDDetail1("");
      setqrCode("");
      setPaymentQR("");
    }
  }, [saleTransferModal]);

  const getTransfer = async (amount) => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/settings`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setTransferType(resp.data.data[0].transfer_type);
      setIsLineNoti(resp.data.data[0].line_notify_type);

      if (resp.data.data[0].transfer_type === 0) {
        getPPID(resp.data.data, amount);
      }
      if (resp.data.data[0].transfer_type === 1) {
        getPaymentGateway(resp.data.data, amount);
      }
    }
  };

  const getPaymentGateway = async (setting, amount) => {
    const token = setting[0].payment_token;
    const branchID = auth.branch.branch_id;
    const userID = auth.newAuth.user.id;
    const company_id = auth.newAuth.user.company_id;

    const detail = `${company_id}|${branchID}|${userID}`;
    let referenceNo = `${branchID.slice(-3)}${saleTable[0].so_no}`;

    let data = new FormData();
    data.append("token", token);
    data.append("amount", amount);
    data.append("referenceNo", referenceNo);
    data.append("detail", detail);
    data.append("backgroundUrl", process.env.REACT_APP_PAYMENT_WEBHOOK);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    try {
      const resp = await axios.post(
        process.env.REACT_APP_PAYMENT_URL,
        data,
        { responseType: "arraybuffer" },
        { headers: headers }
      );

      const base64ImageString = Buffer.from(resp.data, "binary").toString(
        "base64"
      );
      setPaymentQR(base64ImageString);
    } catch (error) {
      console.log(error);
    }
  };

  const getPPID = async (setting, amount) => {
    try {
      const ppid = setting[0].ppid;
      if (ppid) {
        const pp = ppid.filter((data) => data.default === true);
        if (pp) {
          const ppID = `${pp[0].ppid.substring(0, pp[0].ppid.length - 5)}xxxxx`;
          const ppName = `${pp[0].name.substring(
            0,
            pp[0].name.length - 5
          )}xxxxx`;
          setppIDDetail(ppID);
          setppIDDetail1(ppName);
          setqrCode(generatePayload(pp[0].ppid, { amount }));
        }
      }
      //
    } catch (error) {
      console.log(error);
    }
  };

  const lineNotify = async () => {
    try {
      let msg = `เงินโอน => ${commaNumber(saleTableSumCart.grandtotal)}`;
      const data = {
        message: msg,
      };
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      await http.post("helper/linenotify", data, { params: newData });
    } catch (error) {
      console.log(error);
    }
  };

  const payment = async () => {
    try {
      let newIsLoading = { ...isLoading, payment: true };
      setIsLoading(newIsLoading);
      const newData = {
        branch_id: auth.branch.branch_id,
        //amount: total,
        payment: "transfer",
      };

      setChange(0);
      const resp = await http.put(`salesline/table/${table}`, newData);

      setDoc(resp.data.data);
      newIsLoading = { ...isLoading, payment: false };
      setIsLoading(newIsLoading);
      setSalesSlip(true);
      setSaleTransferModal(false);
      sendEmit();
      if (isLineNoti === true) {
        lineNotify();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={saleTransferModal}
        onHide={() => setSaleTransferModal(false)}
        backdrop="static"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="ms-3">
              <strong>ชำระเงินโอน</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-0">
            <div className="col-12">
              <div className="paymenttotal fs-1 fw-bold d-flex align-items-center justify-content-end border p-3">
                <div>{commaNumber(saleTableSumCart.grandtotal)}</div>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-12 h6 text-center pt-3">
              <div className="m-2">{ppIDDetail}</div>
              <div className="m-2">{ppIDDetail1}</div>
            </div>
          </div>
          <div className="row g-0">
            {qrCode !== "" ? (
              <div className="col-12 text-center p-4">
                <QRCode value={qrCode} />
              </div>
            ) : (
              transferType === 0 && (
                <div className="col-12 text-center p-4">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              )
            )}

            {paymentQR !== "" ? (
              isLoading && isLoading.payment ? (
                <div className="fw-bold d-flex align-items-center justify-content-center border p-4">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              ) : (
                <div
                  className="col-12 text-center"
                  style={{ paddingLeft: "15%", paddingRight: "15%" }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={`data:image/png;base64,${paymentQR}`}
                  />
                </div>
              )
            ) : (
              transferType === 1 && (
                <div className="col-12 text-center p-4">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              )
            )}
          </div>

          {transferType === 0 && (
            <div className="row g-0">
              <div className="col-12">
                {isLoading && isLoading.payment ? (
                  <div className="fw-bold d-flex align-items-center justify-content-center border p-4">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    />
                  </div>
                ) : (
                  <div
                    className="bg-primary text-white fw-bold d-flex align-items-center justify-content-center border p-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => payment()}
                  >
                    <div className="fs-3">ตกลง</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <SalesSlip
        setSalesSlip={setSalesSlip}
        salesSlip={salesSlip}
        doc={doc}
        setSaleListModal={setSaleListModal}
        setChange={setChange}
        change={change}
      />
    </>
  );
};

export default SalesTransferModel;
