import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import http from "../../../services/backend/http";
import SalesSlip from "./SalesSlip";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
//import socket from "../../../services/socket/socket";
//import socketIOClient from "socket.io-client";

const SalesPaymentModel = (props) => {
  const {
    salePaymentModal,
    setSalePaymentModal,
    saleTableSumCart,
    setSaleListModal,
    setSaleTableSumCart,
    table,
    sendEmit,
  } = props;
  //const socket = socketIOClient(process.env.REACT_APP_BASE_URL_IO);
  const auth = useSelector((state) => state.AuthReducer.auth);
  const setting = useSelector((state) => state.SettingReducer.setting);
  const [total, setTotal] = useState(0);
  const [salesSlip, setSalesSlip] = useState(false);
  const [doc, setDoc] = useState("");
  const [change, setChange] = useState(0);
  const [isLineNoti, setIsLineNoti] = useState(false);
  const [isLoading, setIsLoading] = useState({
    payment: false,
  });

  useEffect(() => {
    if (salePaymentModal === true) {
      getSetting();
      setTotal(0);
    }
  }, [salePaymentModal]);

  const getSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/settings`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setIsLineNoti(resp.data.data[0].line_notify_type);
    }
  };
  const lineNotify = async () => {
    try {
      let msg = `เงินสด => ${commaNumber(saleTableSumCart.grandtotal)}`;

      const data = {
        message: msg,
      };
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      await http.post("helper/linenotify", data, { params: newData });
    } catch (error) {
      console.log(error);
    }
  };

  const payment = async () => {
    if (saleTableSumCart.grandtotal <= total) {
      try {
        let newIsLoading = { ...isLoading, payment: true };
        setIsLoading(newIsLoading);

        const newData = {
          branch_id: auth.branch.branch_id,
          //amount: total,
          payment: "cash",
        };

        setChange(total - saleTableSumCart.grandtotal);
        const resp = await http.put(`salesline/table/${table}`, newData);

        setDoc(resp.data.data);
        newIsLoading = { ...isLoading, payment: false };
        setIsLoading(newIsLoading);
        setSalesSlip(true);
        setSalePaymentModal(false);

        if (isLineNoti === true) {
          lineNotify();
        }
        sendEmit();
        // socket.disconnect();
      } catch (error) {
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "ยอดรับชำระไม่ถูกต้อง",
      });
    }
  };

  const paymentc = () => {
    setTotal(0);
  };
  const paymentnumber = (i) => {
    let paymenttotal = total.toString() + i;
    setTotal(Number(paymenttotal));
  };

  const paymentfull = () => {
    setTotal(saleTableSumCart.grandtotal);
  };

  const paymentcash = (i) => {
    setTotal(total + i);
  };

  return (
    <>
      <Modal
        show={salePaymentModal}
        onHide={() => setSalePaymentModal(false)}
        backdrop="static"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="ms-3">
              <strong>ชำระเงินสด</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-0">
            <div className="col-12">
              <div className="paymenttotal fs-1 fw-bold d-flex align-items-center justify-content-end border p-3">
                <div>{commaNumber(total)}</div>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(7)}
              >
                <div className="fs-3">7</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(8)}
              >
                <div className="fs-3">8</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center  border p-3"
                onClick={() => paymentnumber(9)}
              >
                <div className="fs-3">9</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentcash noselect text-primary d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentcash(1000)}
              >
                <div className="fs-3">1,000</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(4)}
              >
                <div className="fs-3">4</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(5)}
              >
                <div className="fs-3">5</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center  border p-3"
                onClick={() => paymentnumber(6)}
              >
                <div className="fs-3">6</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentcash noselect text-primary d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentcash(500)}
              >
                <div className="fs-3">500</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(1)}
              >
                <div className="fs-3">1</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(2)}
              >
                <div className="fs-3">2</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center  border p-3"
                onClick={() => paymentnumber(3)}
              >
                <div className="fs-3">3</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentcash noselect text-primary d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentcash(100)}
              >
                <div className="fs-3">100</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentc noselect bg-warning text-muted  d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentc()}
              >
                <div className="fs-3">C</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentnumber noselect d-flex align-items-center justify-content-center border p-3"
                onClick={() => paymentnumber(0)}
              >
                <div className="fs-3">0</div>
              </div>
            </div>
            <div className="col-3">
              <div
                className="paymentcash noselect text-primary d-flex align-items-center justify-content-center  border p-3"
                onClick={() => paymentfull()}
              >
                <div className="fs-3">เต็ม</div>
              </div>
            </div>
            <div className="col-3">
              {isLoading && isLoading.payment ? (
                <div className="fw-bold d-flex align-items-center justify-content-center border p-4">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              ) : (
                <div
                  className="bg-primary noselect text-white fw-bold d-flex align-items-center justify-content-center border p-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => payment()}
                >
                  <div className="fs-3">ตกลง</div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SalesSlip
        setSalesSlip={setSalesSlip}
        salesSlip={salesSlip}
        doc={doc}
        setSaleListModal={setSaleListModal}
        setChange={setChange}
        change={change}
      />
    </>
  );
};

export default SalesPaymentModel;
