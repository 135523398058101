import React, { useEffect, useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import LoadingIndicator from "../../../components/loading/LoadingIndicator";
import { useIntl } from "react-intl";
import { trackPromise } from "react-promise-tracker";
import http from "../../../services/backend/http";
import UsersAddModel from "./UsersAddModel";
import UsersEditModel from "./UsersEditModel";

const Users = () => {
  // const auth = useSelector((state) => state.AuthReducer.auth);
  const [loading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [table, setTable] = useState(null);
  const [tableBranch, setTableBranch] = useState(null);
  const [searchText, setSearchText] = useState("");
  const title = useIntl().formatMessage({ id: "menu.users" });
  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const btnEdit = useIntl().formatMessage({ id: "btn.edit" });
  const btnAdd = useIntl().formatMessage({ id: "btn.add" });
  const btnClear = useIntl().formatMessage({ id: "btn.clear" });

  const tbuser_head_username = useIntl().formatMessage({
    id: "tb.user_head_username",
  });

  const tbuser_head_name = useIntl().formatMessage({ id: "tb.user_head_name" });
  const tbuser_head_role = useIntl().formatMessage({ id: "tb.user_head_role" });
  const tbuser_head_status = useIntl().formatMessage({
    id: "tb.user_head_status",
  });

  useEffect(() => {
    getUsers();
    getBranch();
  }, []);

  //---- call API Start ----//
  const getUsers = async () => {
    try {
      setTable(null);

      const resp = await trackPromise(http.get("user/index"));

      setTable(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearch = async () => {
    try {
      setTable(null);

      const resp = await trackPromise(http.get(`user/search/${searchText}`));
      setTable(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText !== "") {
      getSearch();
    } else {
      getUsers();
    }
  };

  const handleClearSearch = () => {
    // if (searchText !== "") {
    setLoading(true);
    setTable(null);
    setSearchText("");
    getUsers();
    // }
  };

  const toggleEditModal = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  const getBranch = async () => {
    try {
      const resp = await http.get("company/branch");
      setTableBranch(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  ///---- Element Start-----------------------------------//
  const TableElement = () => {
    return (
      table &&
      table.map((item, index) => (
        <tr key={index} className="text-wrap">
          <td>{item.email}</td>
          <td>{item.name}</td>
          <td>{item.role}</td>
          <td>{item.status === true ? "ใช้งาน" : "ปิดใช้งาน"}</td>
          <td style={{ textAlign: "right" }}>
            <a
              href="#"
              className="btn btn-sm btn-warning me-2"
              onClick={() => toggleEditModal(item._id)}
            >
              {btnEdit}
            </a>
          </td>
        </tr>
      ))
    );
  };
  ///---- Element End-----------------------------------//

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${btnSearch} : ${tbuser_head_name}`}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="submit"
                          className="btn btn-sm btn-success me-2"
                          onClick={(e) => handleSearch(e)}
                        >
                          {btnSearch}
                        </button>
                        <a
                          type="button"
                          className="btn btn-sm btn-white me-2"
                          onClick={handleClearSearch}
                        >
                          {btnClear}
                        </a>
                        <a
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => setAddModal(true)}
                        >
                          {btnAdd}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table mb-3 table-hover">
              <thead>
                <tr>
                  <th>{tbuser_head_username}</th>
                  <th>{tbuser_head_name}</th>
                  <th>{tbuser_head_role}</th>
                  <th>{tbuser_head_status}</th>
                  <th style={{ textAlign: "right" }}>{btnManage}</th>
                </tr>
              </thead>
              <tbody>
                <TableElement />
              </tbody>
            </table>
            {loading === true && <LoadingIndicator />}
          </div>
        </div>
      </div>
      <UsersAddModel
        addModal={addModal}
        setAddModal={setAddModal}
        getUsers={getUsers}
        tableBranch={tableBranch}
      />
      <UsersEditModel
        editModal={editModal}
        setEditModal={setEditModal}
        toggleEditModal={toggleEditModal}
        editData={editData}
        getUsers={getUsers}
        tableBranch={tableBranch}
      />
    </BackendLayout>
  );
};

export default Users;
