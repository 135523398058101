export const GET_SETTING = "GET_SETTING";

export const updateSetting = (setting) => {
  return {
    type: GET_SETTING,
    payload: {
      setting: setting,
    },
  };
};
