import { useDispatch } from "react-redux";
import { updateAuthCompany } from "../../../redux/actions/AuthCompanyAction";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import http from "../../../services/auth/http";
import LoginBranchModal from "./LoginBranchModal";
import AuthLayout from "../../../components/layouts/auth/AuthLayout";
import { updateAuth } from "../../../redux/actions/AuthAction";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { com } = useParams();
  // const dispatch = useDispatch();

  //---------Taps Start -----------//
  const [justifyActive, setJustifyActive] = useState("tab1");
  const [branchModal, setBranchModal] = useState(false);
  const [newAuth, setNewAuth] = useState(null);
  var [isLoading, setIsLoading] = useState({
    staffLoading: false,
    ownLoading: false,
  });

  useEffect(() => {
    dispatch(updateAuthCompany(com));
  }, []);

  const handleJustifyClick = (value: string) => {
    reset();
    reset1();
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };
  //---------Taps End -----------//

  //---------yub Start -----------//

  // ------- Form Staff
  const schema = yup
    .object({
      staff_username: yup.string().required("The username field is required"),
      staff_password: yup
        .string()
        .required("The password field is required")
        .min(6, "The password field is required 6 characters minimum"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // ------- Form OWN
  const schema1 = yup
    .object({
      own_username: yup
        .string()
        .required("The email field is required")
        .email("The email field Invalid Email Format"),
      own_password: yup
        .string()
        .required("The password field is required")
        .min(6, "The password field is required 6 characters minimum"),
    })
    .required();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
  });

  //---------yub End -----------//

  //---------handle Function Start -----------//
  const handleStaffLogin = (data) => {
    const newIsLoading = { ...isLoading, staffLoading: true };
    setIsLoading(newIsLoading);

    http
      .post("auth/login", {
        email: data.staff_username,
        password: data.staff_password,
        company_id: com,
      })
      .then((resp) => {
        let timerInterval;
        Swal.fire({
          html: "Loading <b></b>",
          timer: 1000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 1000);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            const tokenSet = resp.data.token;
            const token = resp.data.token.access_token;
            const user = jwt_decode(token);
            const newAuth = { user, ...tokenSet };

            const branchArray = user.company_branch;

            const found = branchArray.find((obj) => {
              return obj._id === user.branch_id;
            });

            const branch = {
              branch_id: user.branch_id,
              branch_name: found.name,
            };
            const newAuthLogin = { branch, newAuth };

            const newIsLoading = { ...isLoading, staffLoading: false };
            setIsLoading(newIsLoading);

            localStorage.setItem("userlogin", true);
            dispatch(updateAuth(newAuthLogin));
            navigate("/backend/salestable");
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "Login infomation",
            text: error.response.data.error.message,
            timer: 2500,
          });
        }

        const newIsLoading = { ...isLoading, staffLoading: false };
        setIsLoading(newIsLoading);
      });
  };

  const handleOwnLogin = (data) => {
    const newIsLoading = { ...isLoading, ownLoading: true };
    setIsLoading(newIsLoading);

    http
      .post("auth/loginown", {
        email: data.own_username,
        password: data.own_password,
      })
      .then((resp) => {
        let timerInterval;
        Swal.fire({
          html: "Loading <b></b>",
          timer: 1000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 1000);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            const tokenSet = resp.data.token;
            const token = resp.data.token.access_token;
            const user = jwt_decode(token);
            const newAuth = { user, ...tokenSet };

            setNewAuth(newAuth);
            setBranchModal(true);

            const newIsLoading = { ...isLoading, ownLoading: false };
            setIsLoading(newIsLoading);

            // localStorage.setItem("userlogin", true);
            // dispatch(updateAuth(newAuth));
            // navigate("/backend/dashboard");
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "Login infomation",
            text: error.response.data.error.message,
            timer: 2500,
          });
        }
        const newIsLoading = { ...isLoading, ownLoading: false };
        setIsLoading(newIsLoading);
      });
  };
  //---------handle Function End-----------//

  return (
    <>
      <AuthLayout title={`Login | ${process.env.REACT_APP_NAME}`}>
        <div className="card-body p-5 text-center">
          <MDBTabs justify className="mb-5">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab1")}
                active={justifyActive === "tab1"}
                color={justifyActive === "tab1" ? "primary" : ""}
                className={
                  justifyActive === "tab1"
                    ? "font-weight-bold text-light"
                    : "font-weight-bold text-dark"
                }
              >
                Staff Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab2")}
                active={justifyActive === "tab2"}
                color={justifyActive === "tab2" ? "primary" : ""}
                className={
                  justifyActive === "tab2"
                    ? "font-weight-bold text-light"
                    : "font-weight-bold text-dark"
                }
              >
                Own Login
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={justifyActive === "tab1"}>
              <form onSubmit={handleSubmit(handleStaffLogin)}>
                <div className="form-outline mb-4 text-start">
                  <label className="form-label" htmlFor="staff_username">
                    Username
                  </label>
                  <input
                    type="text"
                    id="staff_username"
                    name="staff_username"
                    {...register("staff_username")}
                    className="form-control form-control"
                    placeholder="Username"
                  />
                  <p className="text-danger mt-1">
                    {errors.staff_username && errors.staff_username.message}
                  </p>
                </div>
                <div className="form-outline mb-4 text-start">
                  <label className="form-label" htmlFor="staff_password">
                    Password
                  </label>
                  <input
                    type="password"
                    id="staff_password"
                    name="staff_password"
                    {...register("staff_password")}
                    className="form-control form-control"
                    placeholder="Password"
                  />
                  <p className="text-danger mt-1">
                    {errors.staff_password && errors.staff_password.message}
                  </p>
                </div>
                {isLoading && isLoading.staffLoading ? (
                  <div className="spinner-border text-primary" role="status" />
                ) : (
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    name="submit"
                    id="submit"
                  >
                    Login
                  </button>
                )}
              </form>
            </MDBTabsPane>
            <MDBTabsPane show={justifyActive === "tab2"}>
              <form onSubmit={handleSubmit1(handleOwnLogin)}>
                <div className="form-outline mb-4 text-start">
                  <label className="form-label" htmlFor="own_username">
                    Email
                  </label>
                  <input
                    type="text"
                    id="own_username"
                    className="form-control form-control"
                    placeholder="Email"
                    {...register1("own_username")}
                  />
                  <p className="text-danger mt-1">
                    {errors1.own_username && errors1.own_username.message}
                  </p>
                </div>
                <div className="form-outline mb-4 text-start">
                  <label className="form-label" htmlFor="own_password">
                    Password
                  </label>
                  <input
                    type="password"
                    id="own_password"
                    className="form-control form-control"
                    placeholder="Password"
                    {...register1("own_password")}
                  />
                  <p className="text-danger mt-1">
                    {errors1.own_password && errors1.own_password.message}
                  </p>
                </div>

                <div className="d-flex justify-content-start mb-1">
                  <Link
                    className="form-check-label"
                    to={`/forgetpassword/${com}`}
                  >
                    Forget password?
                  </Link>
                </div>

                {isLoading && isLoading.ownLoading ? (
                  <div className="spinner-border text-primary" role="status" />
                ) : (
                  <button className="btn btn-primary btn-block" type="submit">
                    OWN Login
                  </button>
                )}
              </form>
            </MDBTabsPane>
          </MDBTabsContent>
        </div>
      </AuthLayout>
      <LoginBranchModal
        branchModal={branchModal}
        setBranchModal={setBranchModal}
        newAuth={newAuth}
      />
    </>
  );
};

export default Login;
