import React, { useEffect, useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import http from "../../../services/backend/http";
import commaNumber from "comma-number";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReportSumMonthCompare = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.reportsum.year.compare" });
  const [startDate, setStartDate] = useState(new Date());
  const [dataGraph, setDataGraph] = useState(null);
  const [reportSalesTable, setReportSalesTable] = useState(null);
  const [dataStep, setDataStep] = useState({
    max: 6000,
    step: 2000,
  });

  useEffect(() => {
    getReport(startDate);
  }, [startDate]);
  const getReport = async (startDate) => {
    const year = moment(startDate).format("YYYY");
    const dateStart = `01-01-${year}`;
    const dateEnd = `31-12-${year}`;

    const newData = {
      branch_id: auth.branch.branch_id,
      reportDate: "",
      sdateSelect: dateStart,
      edateSelect: dateEnd,
    };

    let labels = [];
    let dataAmount = [];
    let dataFront = [];
    let dataFD = [];
    let dataCostRawMat = [];
    let dataCostFix = [];
    let dataCostSalary = [];
    let dataProfit = [];

    const resp = await http.get(`report/sales/summaryyeardetail`, {
      params: newData,
    });
    setReportSalesTable(resp.data.data);
    const maxSales = Math.max(...resp.data.data.amount.map((o) => o.amount));
    calScales(maxSales);

    for (let i = 1; i <= 12; i++) {
      const dateFound =
        i.toString().length === 1 ? `0${i.toString()}` : i.toString();
      let monthName = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ][i - 1];

      labels.push(`${monthName}`);

      const foundAmount = resp.data.data.amount.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const Amount = foundAmount ? foundAmount.amount : 0;
      dataAmount.push(Amount);

      const foundFD = resp.data.data.fd.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const FD = foundFD ? foundFD.amount : 0;
      dataFD.push(FD);

      const foundCash = resp.data.data.cash.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const Cash = foundCash ? foundCash.amount : 0;

      const foundTransfer = resp.data.data.transfer.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const Transfer = foundTransfer ? foundTransfer.amount : 0;
      dataFront.push(Cash + Transfer);

      // Cost
      const foundCostRawMat = resp.data.data.costRawMat.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const costRawMat = foundCostRawMat ? foundCostRawMat.amount : 0;
      dataCostRawMat.push(costRawMat);

      const foundCostFix = resp.data.data.costFix.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const costFix = foundCostFix ? foundCostFix.amount : 0;
      dataCostFix.push(costFix);

      const foundCostSalary = resp.data.data.costSalary.find((obj) => {
        return obj._id.MM === dateFound;
      });
      const costSalary = foundCostSalary ? foundCostSalary.amount : 0;
      dataCostSalary.push(costSalary);

      dataProfit.push(Amount - (costRawMat + costFix + costSalary));

      // Summary
      const sumdata = {
        labels,
        dataAmount,
        dataFD,
        dataFront,
        dataCostRawMat,
        dataCostFix,
        dataCostSalary,
        dataProfit,
      };
      setDataGraph(sumdata);
    }
  };

  const calScales = (maxValue) => {
    const addValue = 10000 - (maxValue % 100);
    const newValue = maxValue + addValue + 10000;

    let scales = {
      max: 0,
      step: 0,
    };

    if (maxValue > 0) {
      scales = {
        max: newValue,
        step: newValue / 4,
      };
    }
    setDataStep(scales);
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
        <div className="col fw-bold">
          <div className="card radius-10 bg-info bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เดือน</p>
                  <div className="my-1 text-white fw-bold">
                    <DatePicker
                      className="form-control"
                      todayButton="Today"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-primary bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เงินรวม</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.amount.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        )
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-warning bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">จ่าย</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.costRawMat.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        ) +
                          reportSalesTable.costFix.reduce(
                            (a, v) => (a = a + v.amount),
                            0
                          ) +
                          reportSalesTable.costSalary.reduce(
                            (a, v) => (a = a + v.amount),
                            0
                          )
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-success bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">กำไร</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.amount.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        ) -
                          (reportSalesTable.costRawMat.reduce(
                            (a, v) => (a = a + v.amount),
                            0
                          ) +
                            reportSalesTable.costFix.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            ) +
                            reportSalesTable.costSalary.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            ))
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {dataGraph && (
          <Bar
            options={{
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "จำนวนเงิน",
                  },
                  ticks: {
                    stepSize: dataStep.step,
                  },
                  min: 0,
                  max: dataStep.max,
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "วันที่",
                  },
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "black",
                  //  formatter: (v) => (v ? `${commaNumber(Math.round(v))}` : ""),
                  formatter: function (value, context) {
                    let percent =
                      dataGraph.dataAmount[context.dataIndex] !== 0
                        ? (value / dataGraph.dataAmount[context.dataIndex]) *
                          100
                        : "";
                    percent = Math.round(percent);
                    percent = percent !== 0 ? percent.toString() + "%" : "";
                    if (value !== dataGraph.dataAmount[context.dataIndex]) {
                      percent = `   ${percent}\n${commaNumber(value)}`;
                    } else {
                      if (context.dataIndex > 0) {
                        let percentVS =
                          dataGraph.dataAmount[context.dataIndex - 1] !== 0
                            ? (dataGraph.dataAmount[context.dataIndex] /
                                dataGraph.dataAmount[context.dataIndex - 1]) *
                              100
                            : 0;
                        percentVS = Math.round(percentVS - 100);
                        percentVS =
                          percentVS !== 0 ? percentVS.toString() + "%" : "";
                        percent = `   ${percentVS}\n${commaNumber(value)}`;
                      } else {
                        percent = `  ${commaNumber(value)}`;
                      }
                    }
                    percent = value === 0 ? "" : percent;

                    return percent;
                  },
                  anchor: "end",
                  offset: -40,
                  align: "start",
                },
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "ยอดขายเปรียบเทียบ",
                },
              },
            }}
            data={{
              labels: dataGraph.labels,
              datasets: [
                {
                  // fill: true,
                  label: "Delivery",
                  data: dataGraph.dataFD,
                  borderColor: "rgb(102, 51, 0 ,0.5)",
                  backgroundColor: "rgba(102, 51, 0, 0.5)",
                  stack: "Stack 3",
                },
                {
                  // fill: true,
                  label: "Front",
                  data: dataGraph.dataFront,
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.8)",
                  stack: "Stack 2",
                },
                {
                  // fill: true,
                  label: "Total",
                  data: dataGraph.dataAmount,
                  borderColor: "rgb(16, 93, 255)",
                  backgroundColor: "rgba(16, 93, 255, 0.8)",
                  stack: "Stack 1",
                },
              ],
            }}
            height={120}
          />
        )}
      </div>

      <div className="row">
        {dataGraph && (
          <Bar
            options={{
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "จำนวนเงิน",
                  },
                  ticks: {
                    stepSize: dataStep.step,
                  },
                  min: 0,
                  max: dataStep.max,
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "วันที่",
                  },
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "black",
                  //  formatter: (v) => (v ? `${commaNumber(Math.round(v))}` : ""),
                  formatter: function (value, context) {
                    let percent =
                      dataGraph.dataAmount[context.dataIndex] !== 0
                        ? (value / dataGraph.dataAmount[context.dataIndex]) *
                          100
                        : "";
                    percent = Math.round(percent);
                    percent = percent !== 0 ? percent.toString() + "%" : "";
                    if (value !== dataGraph.dataAmount[context.dataIndex]) {
                      percent = `   ${percent}\n${commaNumber(value)}`;
                    } else {
                      if (context.dataIndex > 0) {
                        let percentVS =
                          dataGraph.dataAmount[context.dataIndex - 1] !== 0
                            ? (dataGraph.dataAmount[context.dataIndex] /
                                dataGraph.dataAmount[context.dataIndex - 1]) *
                              100
                            : 0;
                        percentVS = Math.round(percentVS - 100);
                        percentVS =
                          percentVS !== 0 ? percentVS.toString() + "%" : "";
                        percent = `   ${percentVS}\n${commaNumber(value)}`;
                      } else {
                        percent = `  ${commaNumber(value)}`;
                      }
                    }
                    percent = value === 0 ? "" : percent;

                    return percent;
                  },
                  anchor: "end",
                  offset: -40,
                  align: "start",
                },
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "ยอดต้นทุนเปรียบเทียบ",
                },
              },
            }}
            data={{
              labels: dataGraph.labels,
              datasets: [
                {
                  label: "Profit",
                  data: dataGraph.dataProfit,
                  borderColor: "rgb(51, 204, 51)",
                  backgroundColor: "rgba(51, 204, 51, 0.8)",
                  stack: "Stack 5",
                },
                {
                  label: "Utility",
                  data: dataGraph.dataCostFix,
                  borderColor: "rgb(255, 127, 15 ,0.8)",
                  backgroundColor: "rgba(255, 127, 15, 0.8)",
                  stack: "Stack 4",
                },
                {
                  label: "Salary",
                  data: dataGraph.dataCostSalary,
                  borderColor: "rgb(236, 20, 185 ,0.8)",
                  backgroundColor: "rgba(236, 20, 185, 0.8)",
                  stack: "Stack 3",
                },
                {
                  label: "Raw Material",
                  data: dataGraph.dataCostRawMat,
                  borderColor: "rgb(236, 20, 20)",
                  backgroundColor: "rgba(236, 20, 20, 0.8)",
                  stack: "Stack 2",
                },
                {
                  // fill: true,
                  label: "Total",
                  data: dataGraph.dataAmount,
                  borderColor: "rgb(16, 93, 255)",
                  backgroundColor: "rgba(16, 93, 255, 0.8)",
                  stack: "Stack 1",
                },
              ],
            }}
            height={120}
          />
        )}
      </div>
    </BackendLayout>
  );
};

export default ReportSumMonthCompare;
