import React, { useState, useEffect } from "react";
import http from "../../../../services/backend/http";
import { useSelector } from "react-redux";
import commaNumber from "comma-number";
const SalesSlipPrint = React.forwardRef((props, ref) => {
  const { doc, change } = props;
  const [branchArray, setBranchArray] = useState(null);
  const auth = useSelector((state) => state.AuthReducer.auth);

  useEffect(() => {
    if (auth) {
      const branch_id = auth.branch.branch_id;
      const branchArray = auth.newAuth.user.company_branch;
      const branch = branchArray.filter((data) => data.id === branch_id);
      setBranchArray(branch);
    }
  }, []);

  //print-source
  return (
    <>
      {doc && (
        <div className="container-fluid fs-5" ref={ref}>
          <div className="row justify-content-center mt-4">
            <div className="col-12 text-center fw-bold">ใบเสร็จรับเงิน</div>
            <div className="col-12 text-center">
              {auth && auth.newAuth.user.company_name}
              {auth && ` (${auth.branch.branch_name})`}
            </div>
            <div className="col-12 text-center">
              {branchArray && `${branchArray[0].address1}`}
              {/* {branchArray && `เบอร์โทรศัพท์ (${branchArray[0].phone})`} */}
            </div>
            <div className="col-12 text-center">
              {branchArray && `${branchArray[0].address2}`}
            </div>
            <div className="col-12 text-center">
              {branchArray &&
                `${branchArray[0].address3} ${branchArray[0].postcode}`}
            </div>
          </div>

          <div
            className="row mt-5 pt-2 pb-0"
            style={{
              borderTop: "dashed",
              borderTopWidth: "2px",
              borderBottom: "dashed",
              borderBottomWidth: "2px",
            }}
          >
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>No.</td>
                  <td className="text-end">{doc.salesHead.doc_no}</td>
                </tr>
                <tr>
                  <td>โต๊ะที่</td>
                  <td className="text-end">{doc.salesHead.table_no}</td>
                </tr>
                <tr>
                  <td>วันที่</td>
                  <td className="text-end">{doc.salesHead.date}</td>
                </tr>
                <tr>
                  <td>พนักงาน</td>
                  <td className="text-end">
                    {auth && `${auth.newAuth.user.id.substring(0, 5)}-XXXX`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="row mt-2 pt-3 pb-0"
            style={{
              borderTop: "dashed",
              borderTopWidth: "2px",
              borderBottom: "dashed",
              borderBottomWidth: "2px",
            }}
          >
            <table className="table table-borderless">
              <tbody>
                {doc.salesLine.map((data, index) => (
                  <tr key={index}>
                    <td>{data._id.item_name}</td>
                    <td className="text-end">{`${data.unitQty} x ${data._id.unitPrice}`}</td>
                    <td className="text-end">{data.unitPriceTotal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row mt-2">
            <table className="table table-borderless">
              <tbody>
                <tr className="fw-bold">
                  <td>จำนวนงินรวม</td>
                  <td className="text-end">
                    {commaNumber(doc.salesHead.amount)}
                  </td>
                </tr>
                <tr>
                  <td>รับเงิน</td>
                  <td className="text-end">
                    {commaNumber(doc.salesHead.amount + change)}
                  </td>
                </tr>
                <tr>
                  <td>เงินทอน</td>
                  <td className="text-end">{commaNumber(change)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row mt-3 mb-5">
            <div className="col-12 text-center">
              xxxxx ขอบคุณที่ใช้บริการ xxxxx
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default SalesSlipPrint;
