/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSwitch,
} from "mdb-react-ui-kit";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NumericFormat } from "react-number-format";
import http from "../../../services/backend/http";
import { useState, useEffect } from "react";
import GroupIndexModal from "./group/GroupIndexModal";
import UnitIndexModal from "./unit/UnitIndexModal";
import { FormattedMessage } from "react-intl";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getBase64 } from "../../../helper/uploadImage";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
const ProductEditModal = (props) => {
  const {
    editModal,
    setEditModal,
    toggleEditModal,
    editData,
    setSorting,
    getProductsSearch,
    getProducts,
    searchText,
    setPageNumber,
  } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [imgURL, setImgURL] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const [base64URL, setBase64URL] = useState("");
  const [tableItemGroup, setTableItemGroup] = useState(null);
  const [tableItemUnit, setTableItemUnit] = useState(null);
  const [id, setId] = useState("");
  const [itemGroup, setItemGroup] = useState();
  const [itemUnit, setItemUnit] = useState();
  const [itemType, setItemType] = useState();

  const [groupModal, setGroupModal] = useState(false);
  const [unitModal, setUnitModal] = useState(false);
  const [togglePrice, setTogglePrice] = useState(false);
  const [toggleStock, setToggleStock] = useState(false);
  const [toggleTopping, setToggleTopping] = useState(false);
  const [toggleVat, setToggleVat] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsTopping, setInputFieldsTopping] = useState([]);
  const [inputFieldsToppingGroup, setInputFieldsToppingGroup] = useState([]);
  const [nameToppingGroup, setNameToppingGroup] = useState("");
  const [typeToppingGroup, setTypeToppingGroup] = useState("");
  const [errorTypeToppingGroup, setErrorTypeToppingGroup] = useState([]);

  // Caption
  const tbItem_head_item = useIntl().formatMessage({
    id: "tb.item_head_item",
  });
  const tbItem_head_price = useIntl().formatMessage({
    id: "tb.item_head_price",
  });
  const tbItem_head_stock = useIntl().formatMessage({
    id: "tb.item_head_stock",
  });
  const tbItem_head_topping = useIntl().formatMessage({
    id: "tb.item_head_topping",
  });
  const tbItem_head_other = useIntl().formatMessage({
    id: "tb.item_head_other",
  });
  const tbItem_head_image = useIntl().formatMessage({
    id: "tb.item_head_image",
  });

  const tbItem_name = useIntl().formatMessage({ id: "tb.item_name" });
  const tbItem_description = useIntl().formatMessage({
    id: "tb.item_description",
  });
  const tbItem_barcode = useIntl().formatMessage({
    id: "tb.item_barcode",
  });
  const tbItem_groupAdd = useIntl().formatMessage({
    id: "tb.item_groupAdd",
  });
  const tbItem_group = useIntl().formatMessage({ id: "tb.item_group" });
  const tbItem_price_unit = useIntl().formatMessage({
    id: "tb.item_price_unit",
  });
  const tbItem_unit_price = useIntl().formatMessage({
    id: "tb.item_unit_price",
  });
  const tbItem_unit_cost = useIntl().formatMessage({
    id: "tb.item_unit_cost",
  });
  const tbItem_unit_size = useIntl().formatMessage({
    id: "tb.item_unit_size",
  });
  const tbItem_no = useIntl().formatMessage({
    id: "tb.item_no",
  });

  const tbItem_type = useIntl().formatMessage({ id: "tb.item_type" });

  const tbItem_discount = useIntl().formatMessage({
    id: "tb.item_discount",
  });

  const tbItem_unit_stock = useIntl().formatMessage({
    id: "tb.item_unit_stock",
  });
  const tbItem_unit_safetystock = useIntl().formatMessage({
    id: "tb.item_unit_safetystock",
  });
  const tbItem_unit = useIntl().formatMessage({
    id: "tb.item_unit",
  });
  const tbItem_unitAdd = useIntl().formatMessage({
    id: "tb.item_unitAdd",
  });

  const tbItem_switch_stock = useIntl().formatMessage({
    id: "tb.item_switch_stock",
  });
  const tbItem_switch_topping = useIntl().formatMessage({
    id: "tb.item_switch_topping",
  });
  const tbItem_switch_vat = useIntl().formatMessage({
    id: "tb.item_switch_vat",
  });

  const tbitem_topping_Group = useIntl().formatMessage({
    id: "tb.item_topping_Group",
  });

  const tbitem_topping_type1 = useIntl().formatMessage({
    id: "tb.item_topping_type1",
  });

  const tbitem_topping_type2 = useIntl().formatMessage({
    id: "tb.item_topping_type2",
  });

  const tbitem_yup_topping_Group = useIntl().formatMessage({
    id: "tb.item_yup_topping_Group",
  });

  const tbitem_yup_topping_GroupType = useIntl().formatMessage({
    id: "tb.item_yup_topping_GroupType",
  });

  const tbItem_yup_name = useIntl().formatMessage({
    id: "tb.item_yup_name",
  });
  const tbItem_yup_description = useIntl().formatMessage({
    id: "tb.item_yup_description",
  });
  const tbItem_yup_itemUnit = useIntl().formatMessage({
    id: "tb.item_yup_itemUnit",
  });
  const tbItem_yup_unit_cost = useIntl().formatMessage({
    id: "tb.item_yup_unit_cost",
  });
  const tbItem_yup_unit_cost_min = useIntl().formatMessage({
    id: "tb.item_yup_unit_cost_min",
  });
  const tbItem_yup_unit_price = useIntl().formatMessage({
    id: "tb.item_yup_unit_price",
  });
  const tbItem_yup_unit_price_min = useIntl().formatMessage({
    id: "tb.item_yup_unit_price_min",
  });

  const tbItem_yup_itemType = useIntl().formatMessage({
    id: "tb.item_yup_itemType",
  });
  const tbItem_yup_nameGroup = useIntl().formatMessage({
    id: "tb.item_yup_nameGroup",
  });

  const tbItem_type_value0 = useIntl().formatMessage({
    id: "tb.item_type_value0",
  });
  const tbItem_type_value1 = useIntl().formatMessage({
    id: "tb.item_type_value1",
  });
  const tbItem_type_value2 = useIntl().formatMessage({
    id: "tb.item_type_value2",
  });
  const tbItem_type_value3 = useIntl().formatMessage({
    id: "tb.item_type_value3",
  });

  const tbSorting = useIntl().formatMessage({
    id: "tb.sorting",
  });
  const tbSorting_yup = useIntl().formatMessage({
    id: "tb.sorting_yup",
  });

  const itemTypeValue = [
    { no: 0, value: tbItem_type_value0 },
    { no: 1, value: tbItem_type_value1 },
    { no: 2, value: tbItem_type_value2 },
    { no: 3, value: tbItem_type_value3 },
  ];

  useEffect(() => {
    setId("");
    if (editData !== null && editData !== undefined) {
      if (editData) {
        setId(editData);
        getDataForEdit(editData);
      }

      //
    }
  }, [editData]);

  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      name: yup.string().required(tbItem_yup_name),
      description: yup.string().required(tbItem_yup_description),
      itemGroup: yup.string().required(tbItem_yup_nameGroup),
      itemUnit: yup.string().required(tbItem_yup_itemUnit),
      itemType: yup.string().required(tbItem_yup_itemType),
      // unit_cost: yup
      //   .number()
      //   .typeError(tbItem_yup_unit_cost)
      //   .required("Please provide plan cost.")
      //   .min(0, tbItem_yup_unit_cost_min),
      // unit_price: yup
      //   .number()
      //   .typeError(tbItem_yup_unit_price)
      //   .required("Please provide plan cost.")
      //   .min(0, tbItem_yup_unit_price_min),
      stock_onhand: yup.number(),
      stock_safety: yup.number(),
      sorting: yup
        .number()
        .typeError(tbSorting_yup)
        .required("Please provide plan cost.")
        .min(0, tbSorting_yup),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  //----- Call API Start-----//

  const getDataForEdit = async (id) => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      const resp = await http.get(`item/${id}`, { params: newData });
      setImgFile(null);
      setImgURL("");
      setValue("name", resp.data.data[0].name, { shouldDirty: true });
      setValue("description", resp.data.data[0].description, {
        shouldDirty: true,
      });
      setValue("sorting", resp.data.data[0].sorting, { shouldDirty: true });
      setValue("barcode", resp.data.data[0].barcode, { shouldDirty: true });
      setValue("itemGroup", resp.data.data[0].group_id, {
        shouldDirty: true,
      });
      setValue("itemUnit", resp.data.data[0].unit_id, {
        shouldDirty: true,
      });
      setValue("itemType", resp.data.data[0].item_type, {
        shouldDirty: true,
      });
      setValue("unit_price", resp.data.data[0].unit_price, {
        shouldDirty: true,
      });
      setValue("unit_cost", resp.data.data[0].unit_cost, { shouldDirty: true });
      setValue("stock_onhand", resp.data.data[0].stock_onhand, {
        shouldDirty: true,
      });
      setValue("stock_safety", resp.data.data[0].stock_safety, {
        shouldDirty: true,
      });
      setValue("discount", resp.data.data[0].discount, { shouldDirty: true });
      setValue("file", null, { shouldDirty: true });
      if (!String(resp.data.data[0].image).includes("no-image.jpg")) {
        setImgURL(resp.data.data[0].image);
      }

      setBase64URL("");
      getItemGroup();
      getItemUnit();
      setItemType(resp.data.data[0].item_type);
      setItemGroup(resp.data.data[0].group_id);
      setItemType(resp.data.data[0].item_type);
      setItemUnit(resp.data.data[0].unit_id);
      setTogglePrice(resp.data.data[0].sizing_type);
      setToggleStock(resp.data.data[0].stock_type);
      setToggleTopping(resp.data.data[0].topping_type);
      setToggleVat(resp.data.data[0].vat_type);

      setInputFields(resp.data.data[0].sizing);

      if (resp.data.data[0].topping_type === true) {
        setInputFieldsToppingGroup(resp.data.data[0].toppinggroup);
      }
      if (resp.data.data[0].photo !== "nopic.png") {
        setImgURL(resp.data.data[0].photo_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getItemGroup = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get("item_group", { params: newData });
      setTableItemGroup(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getItemUnit = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get("item_unit", { params: newData });
      setTableItemUnit(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getItemGroupSize = async (id) => {
    setInputFields([]);
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`item_group/sizing/${id}`, {
        params: newData,
      });

      const arrayTotal = await resp.data.data.map((item) => {
        let newfield = {
          no: item.no,
          name: item.name,
          unit_cost: 0,
          unit_price: 0,
        };
        let arrayTotal = {};
        arrayTotal = { ...newfield, newfield };
        return arrayTotal;
      });
      setInputFields(arrayTotal);
    } catch (error) {
      console.log(error);
    }
  };

  const getItemGroupTopping = async (id) => {
    setInputFieldsToppingGroup([]);
    setInputFieldsTopping([]);
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`item_group/topping_group/${id}`, {
        params: newData,
      });

      const arrayTotal = await resp.data.data[0].toppinggroup.map((item) => {
        let newfield = {
          no: item.no,
          name: item.name,
          type: item.type,
          topping: item.topping,
        };
        let arrayTotal = {};
        arrayTotal = { ...newfield };
        return arrayTotal;
      });

      setInputFieldsToppingGroup(arrayTotal);
    } catch (error) {
      console.log(error);
    }
  };
  //----- Call API End-----//

  //---- Handle Function Start -----------------//
  const handleEdit = (data) => {
    const newData = {
      ...data,
      branch_id: auth.branch.branch_id,
      sizing_type: togglePrice,
      stock_type: toggleStock,
      vat_type: toggleVat,
      topping_type: toggleTopping,
      sizing: inputFields,
      toppinggroup: inputFieldsToppingGroup,
      base64URL: base64URL,
      photo: editData.photo,
    };

    http
      .put(`item/${id}`, newData)
      .then((resp) => {
        if (searchText !== "") {
          getProductsSearch();
        } else {
          getProducts();
        }
        reset();
        toggleEditModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickClose = () => {
    reset();
    reset({
      price: "0",
    });
    setImgURL("");
    setImgFile(null);
    setItemGroup("");
    setItemUnit("");
    setItemType("");
    toggleEditModal();
  };

  const onFileImgChange = async (e) => {
    const file = e.target.files[0];
    const imgUrl = URL.createObjectURL(file);
    const base64File = await getBase64(file);
    setBase64URL(base64File);
    setImgURL(imgUrl);
    setImgFile(file);
  };
  //---- Handle Function End -----------------//

  //----- Toggle Start ----------//
  const toggleGroupModal = () => {
    setGroupModal(!groupModal);
  };

  const toggleUnitModal = () => {
    setUnitModal(!unitModal);
  };

  const handleClickPriceToggle = () => {
    setTogglePrice(!togglePrice);
  };

  const handleClickToppingToggle = async () => {
    setToggleTopping(!toggleTopping);
  };
  //----- Toggle End ----------//

  /******** Size **** */
  const handleAddFields = () => {
    let newfield = {
      no: inputFields.length + 1,
      name: "",
      unit_cost: 0,
      unit_price: 0,
    };
    setInputFields([...inputFields, newfield]);
  };

  const handleRemoveFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const handleClickGetPriceMaster = async () => {
    if (itemGroup) {
      await getItemGroupSize(itemGroup);
    }
  };
  /******** Size **** */

  /******** Topping **** */
  // const handleAddFieldsTopping = async () => {
  //   const toppingdefault = await tableItemGroup.find((e) => {
  //     return e._id === itemGroup;
  //   });

  //   let newfield = {
  //     no: inputFieldsTopping.length + 1,
  //     default: toppingdefault.topping_default,
  //     name: "",
  //     unit_cost: 0,
  //     unit_price: 0,
  //   };
  //   setInputFieldsTopping([...inputFieldsTopping, newfield]);
  // };

  // const handleRemoveFieldsTopping = (index) => {
  //   let data = [...inputFieldsTopping];
  //   data.splice(index, 1);
  //   setInputFieldsTopping(data);
  // };

  // const handleFormChangeTopping = (index, event) => {
  //   let data = [...inputFieldsTopping];
  //   if (event.target.name === "default") {
  //     data[index][event.target.name] = event.target.checked;
  //   } else {
  //     data[index][event.target.name] = event.target.value;
  //   }
  //   setInputFieldsTopping(data);
  // };

  const handleClickGetToppingMaster = async () => {
    if (itemGroup) {
      await getItemGroupTopping(itemGroup);
    }
  };

  /******** Topping End**** */

  /******** ToppingGroup Start **** */
  const checkError = (key) => {
    var result = errorTypeToppingGroup.findIndex((x) => x.name === key);
    if (result !== -1) {
      return errorTypeToppingGroup[result].error;
    }
  };

  const handleAddFieldsToppingGroup = async () => {
    let error = [];
    if (nameToppingGroup === "") {
      error.push({ name: "nameToppingGroup", error: tbitem_yup_topping_Group });
    }

    if (typeToppingGroup === "") {
      error.push({
        name: "typeToppingGroup",
        error: tbitem_yup_topping_GroupType,
      });
    }
    setErrorTypeToppingGroup(error);

    if (error.length !== 0) {
      return;
    }

    let newGroup = {
      no: inputFieldsToppingGroup.length + 1,
      name: nameToppingGroup,
      type: typeToppingGroup,
      topping: [],
    };

    setInputFieldsToppingGroup([...inputFieldsToppingGroup, newGroup]);
    setNameToppingGroup("");
    setTypeToppingGroup("");
  };

  const handleRemoveFieldsToppingGroup = (index) => {
    let data = [...inputFieldsToppingGroup];
    data.splice(index, 1);
    setInputFieldsToppingGroup(data);
  };

  const handleAddFieldsToppingSubGroup = async (index) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[index] };
    let arrayTopping = item.topping;

    let newfield = {
      no: arrayTopping.length + 1,
      default: false,
      name: "",
      unit_cost: 0,
      unit_price: 0,
    };

    arrayTopping = [...arrayTopping, newfield];
    item.topping = arrayTopping;
    items[index] = item;

    //console.log(items);
    setInputFieldsToppingGroup(items);
  };

  const handleRemoveFieldsTopping = (indexs, index) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[indexs] };
    let arrayTopping = item.topping;
    arrayTopping.splice(index, 1);
    item.topping = arrayTopping;
    items[indexs] = item;
    setInputFieldsToppingGroup(items);
  };

  const handleFormChangeTopping = (indexs, index, event) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[indexs] };
    let arrayTopping = item.topping;

    if (event.target.name === "default") {
      if (item.type === "toppingGroupType") {
        arrayTopping.map((i) => {
          i[event.target.name] = false;
        });
      }

      arrayTopping[index][event.target.name] = event.target.checked;
    } else {
      arrayTopping[index][event.target.name] = event.target.value;
    }
    item.topping = arrayTopping;
    items[indexs] = item;
    setInputFieldsToppingGroup(items);
  };

  /******** ToppingGroup End **** */
  return (
    <>
      <MDBModal
        staticBackdrop
        show={editModal}
        setShow={setEditModal}
        tabIndex="-1"
      >
        {" "}
        <MDBModalDialog scrollable>
          <form onSubmit={handleSubmit(handleEdit)}>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  <strong>
                    <FormattedMessage id="modal.edit" />
                  </strong>
                </MDBModalTitle>
                <a
                  className="btn-close"
                  color="none"
                  onClick={handleClickClose}
                ></a>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="card p-2">
                  <div className="h6 ms-0">
                    <strong>{tbItem_head_item}</strong>
                  </div>
                  <div className="form-outline mb-2 text-start">
                    <label className="form-label" htmlFor="name">
                      {tbItem_name}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder={tbItem_name}
                      {...register("name")}
                    />
                    <div className="text-danger mt-1">
                      {errors.name && errors.name.message}
                    </div>
                  </div>
                  <div className="form-outline mb-2 text-start">
                    <label className="form-label" htmlFor="description">
                      {tbItem_description}
                    </label>
                    <input
                      type="text"
                      id="description"
                      name="description"
                      className="form-control"
                      placeholder={tbItem_description}
                      {...register("description")}
                    />
                    <div className="text-danger mt-1">
                      {errors.description && errors.description.message}
                    </div>
                  </div>

                  <div className="form-outline mb-2 text-start">
                    <label className="form-label" htmlFor="sorting">
                      {tbSorting}
                    </label>
                    <input
                      type="text"
                      id="sorting"
                      name="sorting"
                      className="form-control"
                      placeholder={tbSorting}
                      {...register("sorting")}
                    />
                    <div className="text-danger mt-1">
                      {errors.sorting && errors.sorting.message}
                    </div>
                  </div>
                  <div className="form-outline mb-2 text-start">
                    <label className="form-label" htmlFor="barcode">
                      {tbItem_barcode}
                    </label>
                    <input
                      type="text"
                      id="barcode"
                      name="barcode"
                      className="form-control"
                      placeholder={tbItem_barcode}
                      {...register("barcode")}
                    />
                  </div>
                  <div className="form-outline mb-2">
                    <label className="form-label" htmlFor="description">
                      {tbItem_group}
                    </label>
                    <label
                      className="form-label ms-2 link-insert-select pe-auto"
                      onClick={toggleGroupModal}
                    >
                      {tbItem_groupAdd}
                    </label>
                    <select
                      name="itemGroup"
                      id="itemGroup"
                      className="form-control"
                      {...register("itemGroup")}
                      onChange={(e) => setItemGroup(e.target.value)}
                      value={itemGroup}
                    >
                      <option value="">
                        <FormattedMessage id="btn.select" />
                      </option>
                      {tableItemGroup &&
                        tableItemGroup.map((data, index) => (
                          <option key={index} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                    <div className="text-danger mt-1">
                      {errors.itemGroup && errors.itemGroup.message}
                    </div>
                  </div>
                  <div className="form-outline mb-2">
                    <label className="form-label" htmlFor="itemType">
                      {tbItem_type}
                    </label>
                    <select
                      name="itemType"
                      id="itemType"
                      className="form-control"
                      {...register("itemType")}
                      onChange={(e) => setItemType(e.target.value)}
                      value={itemType}
                    >
                      <option value="">
                        <FormattedMessage id="btn.select" />
                      </option>
                      {itemTypeValue &&
                        itemTypeValue.map((data, index) => (
                          <option key={index} value={data.no}>
                            {data.value}
                          </option>
                        ))}
                    </select>
                    <div className="text-danger mt-1">
                      {errors.itemType && errors.itemType.message}
                    </div>
                  </div>
                </div>

                <div className="card p-2">
                  <div className="h6">
                    <strong>{tbItem_head_price}</strong>
                  </div>

                  <div>
                    <div className="mb-2">
                      <Row>
                        <Col>
                          <Button
                            variant="outline-success"
                            size="sm"
                            onClick={handleClickGetPriceMaster}
                          >
                            <FormattedMessage id="btn.loadmaster_group" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-2">
                          <b>{tbItem_no}</b>
                        </Col>
                        <Col>
                          <b>{tbItem_unit_size}</b>
                        </Col>
                        <Col>
                          <b>{tbItem_unit_cost}</b>
                        </Col>
                        <Col>
                          <b>{tbItem_unit_price}</b>
                        </Col>
                        <div
                          className="btn-close insert ms-1 me-2"
                          role="button"
                          onClick={handleAddFields}
                        ></div>
                      </Row>
                    </div>
                    <div>
                      {inputFields &&
                        inputFields.map((item, index) => (
                          <Row key={index} className="mb-1">
                            <Col className="col-2 ">
                              <input
                                type="text"
                                id="no"
                                name="no"
                                className="form-control"
                                placeholder={tbItem_no}
                                style={{ width: "110%" }}
                                value={item.no}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                onFocus={(e) => e.target.select()}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder={tbItem_unit_size}
                                style={{ width: "110%" }}
                                value={item.name}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                onFocus={(e) => e.target.select()}
                                //    {...register("description")}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="unit_cost"
                                name="unit_cost"
                                className="form-control"
                                placeholder={tbItem_unit_cost}
                                style={{ width: "110%" }}
                                value={item.unit_cost}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                onFocus={(e) => e.target.select()}
                                //    {...register("description")}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="unit_price"
                                name="unit_price"
                                className="form-control"
                                placeholder={tbItem_unit_price}
                                style={{ width: "110%" }}
                                value={item.unit_price}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                onFocus={(e) => e.target.select()}
                                //    {...register("description")}
                              />
                            </Col>
                            <div
                              className="btn-close  close mt-2 ms-1 me-2"
                              role="button"
                              onClick={() => handleRemoveFields(index)}
                            ></div>
                          </Row>
                        ))}
                    </div>
                  </div>

                  {/* 
                  <Collapse in={!togglePrice}>
                    <div id="div_price_toggle">
                      <div className="form-outline mb-2 text-start">
                        <label className="form-label" htmlFor="unit_cost">
                          {tbItem_unit_cost}
                        </label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              placeholder={tbItem_unit_cost}
                              onValueChange={(value) =>
                                onChange(value.floatValue)
                              }
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                          name="unit_cost"
                          control={control}
                          fixedDecimalScale
                          defaultValue="0"
                        />
                      </div>
                      <div className="form-outline mb-2 text-start">
                        <label className="form-label" htmlFor="unit_price">
                          {tbItem_unit_price}
                        </label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              placeholder={tbItem_unit_price}
                              onValueChange={(value) =>
                                onChange(value.floatValue)
                              }
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                          name="unit_price"
                          control={control}
                          fixedDecimalScale
                          defaultValue="0"
                        />
                      </div>
                      <div className="form-outline mb-2 text-start">
                        <label className="form-label" htmlFor="discount">
                          {tbItem_discount}
                        </label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              placeholder={tbItem_discount}
                              onValueChange={(value) =>
                                onChange(value.floatValue)
                              }
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                          name="discount"
                          control={control}
                          fixedDecimalScale
                          defaultValue="0"
                        />
                      </div>
                    </div>
                  </Collapse> */}
                </div>

                <div className="card p-2">
                  <div className="h6">
                    <strong>{tbItem_head_stock}</strong>
                  </div>
                  <div>
                    <MDBSwitch
                      onChange={() => setToggleStock(!toggleStock)}
                      id="switch_stock_toggle"
                      label={tbItem_switch_stock}
                      checked={toggleStock}
                    />
                  </div>
                  <Collapse in={!toggleStock}>
                    <div id="div_stock_toggle">
                      <div className="form-outline mb-2 text-start">
                        <label className="form-label" htmlFor="stock_onhand">
                          {tbItem_unit_stock}
                        </label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              placeholder={tbItem_unit_stock}
                              onValueChange={(value) =>
                                onChange(value.floatValue)
                              }
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                          name="stock_onhand"
                          control={control}
                          fixedDecimalScale
                          defaultValue="0"
                        />
                      </div>
                      <div className="form-outline mb-2 text-start">
                        <label className="form-label" htmlFor="stock_safety">
                          {tbItem_unit_safetystock}
                        </label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, name, value },
                          }) => (
                            <NumericFormat
                              className="form-control"
                              thousandSeparator=","
                              allowNegative={false}
                              decimalScale={2}
                              placeholder={tbItem_unit_safetystock}
                              onValueChange={(value) =>
                                onChange(value.floatValue)
                              }
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                          name="stock_safety"
                          control={control}
                          fixedDecimalScale
                          defaultValue="0"
                        />
                      </div>
                    </div>
                  </Collapse>
                  <div className="form-outline mb-2">
                    <label className="form-label" htmlFor="itemUnit">
                      {tbItem_unit}
                    </label>
                    <label
                      className="form-label ms-2 link-insert-select pe-auto"
                      onClick={toggleUnitModal}
                    >
                      {tbItem_unitAdd}
                    </label>
                    <select
                      name="itemUnit"
                      id="itemUnit"
                      className="form-control"
                      {...register("itemUnit")}
                      onChange={(e) => setItemUnit(e.target.value)}
                      value={itemUnit}
                    >
                      <option value="">
                        <FormattedMessage id="btn.select" />
                      </option>
                      {tableItemUnit &&
                        tableItemUnit.map((data, index) => (
                          <option key={index} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                    <div className="text-danger mt-1">
                      {errors.itemUnit && errors.itemUnit.message}
                    </div>
                  </div>
                </div>

                <div className="card p-2">
                  <div className="h6">
                    <strong>{tbItem_head_topping}</strong>
                  </div>
                  <div>
                    <MDBSwitch
                      onChange={handleClickToppingToggle}
                      id="switch_topping_toggle"
                      label={tbItem_switch_topping}
                      checked={toggleTopping}
                    />
                  </div>
                  <Collapse in={toggleTopping}>
                    <div>
                      <div className="mb-2">
                        <Row>
                          <Col>
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={handleClickGetToppingMaster}
                            >
                              <FormattedMessage id="btn.loadmaster_group" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-2">
                        <Row>
                          <Col>
                            <input
                              type="text"
                              id="toppingGroup"
                              name="toppingGroup"
                              className="form-control"
                              placeholder={tbitem_topping_Group}
                              style={{ width: "105%" }}
                              onChange={(e) =>
                                setNameToppingGroup(e.target.value)
                              }
                              value={nameToppingGroup}
                            />
                          </Col>
                          <Col className="d-flex">
                            <select
                              name="toppingGroupType"
                              id="toppingGroupType"
                              className="form-control me-2"
                              onChange={(e) =>
                                setTypeToppingGroup(e.target.value)
                              }
                              value={typeToppingGroup}
                            >
                              <option value="">
                                <FormattedMessage id="btn.select" />
                              </option>
                              <option value="toppingGroupType">
                                {tbitem_topping_type1}
                              </option>
                              <option value="toppingGroupTypes">
                                {tbitem_topping_type2}
                              </option>
                            </select>
                            <div
                              className="btn btn-primary"
                              onClick={handleAddFieldsToppingGroup}
                            >
                              เพิ่ม
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-danger">
                              {checkError("nameToppingGroup")}
                            </div>
                          </Col>
                          <Col>
                            <div className="text-danger">
                              {checkError("typeToppingGroup")}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {inputFieldsToppingGroup &&
                        inputFieldsToppingGroup.map((item, indexs) => (
                          <div className="card " key={indexs}>
                            <div className="card-header d-flex  justify-content-between">
                              <div className="text-left">
                                <strong>{item.name}</strong>
                                <br />
                                <span>
                                  {item.type === "toppingGroupType"
                                    ? tbitem_topping_type1
                                    : tbitem_topping_type2}
                                </span>
                              </div>
                              <div
                                role="button"
                                className="btn-close"
                                color="none"
                                onClick={() =>
                                  handleRemoveFieldsToppingGroup(indexs)
                                }
                              ></div>
                            </div>

                            <div className="p-2 px-3 mb-2">
                              <Row>
                                <Col>
                                  <b>{tbItem_no}</b>
                                </Col>
                                <Col>
                                  <b>{tbItem_switch_topping}</b>
                                </Col>
                                <Col>
                                  <b>{tbItem_unit_cost}</b>
                                </Col>
                                <Col>
                                  <b>{tbItem_unit_price}</b>
                                </Col>
                                <div
                                  className="btn-close insert ms-1 me-2"
                                  role="button"
                                  onClick={() =>
                                    handleAddFieldsToppingSubGroup(indexs)
                                  }
                                ></div>
                              </Row>
                            </div>

                            {item.topping.map((topping, index) => (
                              <Row key={index} className="mb-1">
                                <Col className="d-flex pe-0">
                                  <Form.Check
                                    type="checkbox"
                                    id="default"
                                    name="default"
                                    className="mt-2 ms-2 me-2"
                                    checked={topping.default}
                                    onChange={(event) =>
                                      handleFormChangeTopping(
                                        indexs,
                                        index,
                                        event
                                      )
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="no"
                                    name="no"
                                    className="form-control"
                                    placeholder={tbItem_no}
                                    style={{ width: "110%" }}
                                    value={topping.no}
                                    onChange={(event) =>
                                      handleFormChangeTopping(
                                        indexs,
                                        index,
                                        event
                                      )
                                    }
                                    onFocus={(e) => e.target.select()}
                                  />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    placeholder={tbItem_switch_topping}
                                    style={{ width: "110%" }}
                                    value={topping.name}
                                    onChange={(event) =>
                                      handleFormChangeTopping(
                                        indexs,
                                        index,
                                        event
                                      )
                                    }
                                    onFocus={(e) => e.target.select()}
                                    //    {...register("description")}
                                  />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    id="unit_cost"
                                    name="unit_cost"
                                    className="form-control"
                                    placeholder="ขนาด"
                                    style={{ width: "110%" }}
                                    value={topping.unit_cost}
                                    onChange={(event) =>
                                      handleFormChangeTopping(
                                        indexs,
                                        index,
                                        event
                                      )
                                    }
                                    onFocus={(e) => e.target.select()}
                                  />
                                </Col>
                                <Col>
                                  <input
                                    type="text"
                                    id="unit_price"
                                    name="unit_price"
                                    className="form-control"
                                    placeholder="ขนาด"
                                    style={{ width: "110%" }}
                                    value={topping.unit_price}
                                    onChange={(event) =>
                                      handleFormChangeTopping(
                                        indexs,
                                        index,
                                        event
                                      )
                                    }
                                    onFocus={(e) => e.target.select()}
                                    //    {...register("description")}
                                  />
                                </Col>
                                <div
                                  className="btn-close  close mt-2 ms-1 me-4"
                                  role="button"
                                  onClick={() =>
                                    handleRemoveFieldsTopping(indexs, index)
                                  }
                                ></div>
                              </Row>
                            ))}
                          </div>
                        ))}
                    </div>
                  </Collapse>
                </div>

                <div className="card p-2">
                  <div className="h6">
                    <strong>{tbItem_head_other}</strong>
                  </div>
                  <div>
                    <MDBSwitch
                      id="flexSwitchCheckDefault"
                      label={tbItem_switch_vat}
                      onChange={() => setToggleVat(!toggleVat)}
                      checked={toggleVat}
                    />
                  </div>
                </div>

                <div className="card p-2">
                  <div className="h6">
                    <strong>{tbItem_head_image}</strong>
                  </div>

                  <div className="form-outline mb-2 text-start">
                    {imgURL && (
                      <div className="mb-2">
                        <img
                          crossOrigin="anonymous"
                          src={imgURL}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </div>
                    )}

                    <input
                      type="file"
                      id="file"
                      name="file"
                      className="form-control"
                      accept="image/png, image/jpg, image/jpeg"
                      {...register("file")}
                      onChange={onFileImgChange}
                    />
                  </div>
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <a
                  className="btn btn-sm btn-secondary"
                  onClick={handleClickClose}
                >
                  <FormattedMessage id="btn.close" />
                </a>
                <button
                  className="btn btn-sm btn-primary"
                  type="submit"
                  name="submit"
                  disabled={
                    auth && auth.newAuth.user.role === "user" ? true : false
                  }
                >
                  <FormattedMessage id="btn.save" />
                </button>
              </MDBModalFooter>
            </MDBModalContent>
          </form>
        </MDBModalDialog>
      </MDBModal>

      <GroupIndexModal
        groupModal={groupModal}
        setGroupModal={setGroupModal}
        tableItemGroup={tableItemGroup}
        getItemGroup={getItemGroup}
        toggleGroupModal={toggleGroupModal}
      />
      <UnitIndexModal
        unitModal={unitModal}
        setUnitModal={setUnitModal}
        tableItemUnit={tableItemUnit}
        getItemUnit={getItemUnit}
        toggleUnitModal={toggleUnitModal}
      />
    </>
  );
};

export default ProductEditModal;
