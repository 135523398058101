import React from "react";
import { useSelector } from "react-redux";
const Footer = () => {
  return (
    <>
      <footer id="pagefooter" className="page-footer">
        <p className="mb-0">
          Copyright © 2021 - {new Date().getFullYear()}. All right reserved.
          {/* {auth && auth.user.fullname} */}
        </p>
      </footer>
    </>
  );
};

export default Footer;
