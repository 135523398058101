import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";


const Dashboard = () => {
  const title = useIntl().formatMessage({ id: "menu.dashboards01" });


  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <h1 className="h3 mb-3">Blank Page</h1>
    </BackendLayout>
  );
};

export default Dashboard;
