import React, { useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import AuthLayout from "../../../components/layouts/auth/AuthLayout";
import http from "../../../services/auth/http";

const Resetpassword = () => {
  const authCompany = useSelector((state) => state.AuthCompanyReducer.company);
  let { com, email, userid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  //---------yub Start -----------//
  const schema = yup
    .object({
      password: yup
        .string()
        .required("The password field is required")
        .min(6, "Should be 6 chars minimum"),
      password2: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---------yub End -----------//

  useEffect(() => {
    setValue("username", email, { shouldDirty: true });
  }, [])
  

  //---- Handle Function Start -----------------//
  const handleResetPassword = async (data) => {
    try {
      setIsLoading(true);
        const newData = {
          ...data,
          email: email,
          user_id: userid,
          company_id: com,
          password:data.password,
          branch_id: data.role === "user" ? data.branch : "",
        };

        var alert = document.getElementById("alert").classList;

        if (String(alert.value).includes("d-none")) {
        } else {
          alert.add("d-none");
        }

   
         const resp = await http.post("/auth/resetpassword", newData);
         if (resp.data.message === "Success") {
          setValue("username", "", { shouldDirty: true });
          setValue("password", "", { shouldDirty: true });
          setValue("password2", "", { shouldDirty: true });
          if (String(alert.value).includes("d-none")) {
            alert.remove("d-none");
          }
        } else {
          console.log(resp.data);
        }
        setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Login infomation",
          text: error.response.data.error.message,
          timer: 2500,
        });
      }
      setIsLoading(false);
    }
  };

  const toggleShowPassword = (event) => {
    var x = document.getElementById("password");
    var y = document.getElementById("password2");
    if (event.target.checked === true) {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }
  };

  return (
    <AuthLayout title={`Reset password | ${process.env.REACT_APP_NAME}`}>
      <div className="card-body p-5 text-center">
        <h4 className="mb-3">Reset Password</h4>
        <hr></hr>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="form-outline mb-4 text-start">
            <label className="form-label" htmlFor="username">
              Email
            </label>
            <input
              type="text"
              id="username"
              disabled
              className="form-control form-control"
              placeholder="Email"
              {...register("username")}
            />
          </div>
          <div className="form-outline mb-4 text-start">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="form-control form-control"
              placeholder="Password"
              {...register("password")}
            />
            <p className="text-danger mt-1">
              {errors.password && errors.password.message}
            </p>
          </div>
          <div className="form-outline mb-4 text-start">
            <label className="form-label" htmlFor="password2">
              Password confirm
            </label>
            <input
              type="password"
              id="password2"
              className="form-control form-control"
              placeholder="Password confirm"
              {...register("password2")}
            />
            <p className="text-danger mt-1">
              {errors.password2 && errors.password2.message}
            </p>
          </div>
          <div className="form-outline  mb-2  d-flex">
            <Form.Check
              type="switch"
              className="me-1"
              onChange={(event) => toggleShowPassword(event)}
              defaultChecked={false}
            />
            <label className="form-label" htmlFor="description">
              Show password
            </label>
          </div>
          <div id="alert" className="alert alert-success d-none" role="alert">
              Reset your password success
            </div>
          <div className="d-flex justify-content-start mt-4">
            <Link className="form-check-label" to={`/login/${authCompany}`}>
              Login
            </Link>
          </div>

          {isLoading ? (
              <div className="spinner-border text-primary" role="status" />
            ) : (
              <button className="btn btn-primary btn-block" type="submit">
              Submit
            </button>
            )}


        </form>
      </div>
    </AuthLayout>
  );
};

export default Resetpassword;
