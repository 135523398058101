// import { FormattedMessage } from "react-intl";

export const paginatePerPage = [5, 10, 25, 50, 100];
export const paginatePageDefault = 10;
export const paginateRangeDisplayed = 10;
export const userrole = ["admin", "user"];
export const purchaseType = [
  { _id: 1, _name: "วัตถุดิบ" },
  { _id: 2, _name: "ค่าสาธารณูปโภค" },
  { _id: 3, _name: "ค่าใช้จ่ายในการดำเนินงาน" },
  { _id: 4, _name: "วัตถุดิบ 1" },
];
