import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ReactToPrint from "react-to-print";
import SalesSlipPrint from "./compomentToPrint/SalesSlipPrint";
import http from "../../../services/backend/http";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { ModalBody } from "react-bootstrap";

const SalesSlip = (props) => {
  const { salesSlip, setSalesSlip, doc, setSaleListModal, change, setChange } =
    props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [dimensions, setDimensions] = useState({
    width: 88,
    height: 500,
  });
  const [docTable, setDocTable] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    if (salesSlip === true) {
      getPayment(doc);
    }
  }, [salesSlip]);

  const printPayment = () => {
    // getPayment(doc);
    document.getElementById("print-button").click();
    // window.print();
  };

  const getPayment = async (doc) => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`salesline/doc/${doc}`, { params: newData });
      const data = {
        salesHead: resp.data.salesHead,
        salesLine: resp.data.salesLine,
      };
      //
      setDocTable(data);
    } catch (error) {
      console.log(error);
    }
  };

  const closeSalesSlip = () => {
    setSalesSlip(false);
    setSaleListModal(false);
  };

  return (
    <>
      <Modal
        show={salesSlip}
        onHide={() => closeSalesSlip()}
        backdrop="static"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="ms-3">
              <strong>เงินทอน</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="row pt-2 pb-2">
            <div
              className="col-12 text-primary text-center fw-bold"
              style={{ fontSize: "36px" }}
            >
              เงินทอน
            </div>
            <div
              className="col-12 text-danger text-center fw-bold"
              style={{ fontSize: "64px" }}
            >
              {commaNumber(change)}
            </div>
          </div>

          <div className="row">
            <div className="col text-center">
              <button
                className="btn btn-primary"
                //onClick={() => printPayment()}
                onClick={() => printPayment()}
              >
                ปริ้นใบเสร็จ
              </button>
            </div>
          </div>
          {/* <div
            className="row mt-5"
            style={{
              borderTop: "dashed",
              borderTopWidth: "2px",
            }}
          ></div> */}
          <ReactToPrint
            documentTitle="Receipt"
            pageStyle={`@media print {
              @page {
                size: ${dimensions.width}mm ${dimensions.height}mm;
              }
            }`}
            trigger={() => (
              <button id="print-button" style={{ display: "none" }}>
                Print this out!
              </button>
            )}
            content={() => componentRef.current}
          />
          <SalesSlipPrint ref={componentRef} doc={docTable} change={change} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SalesSlip;
