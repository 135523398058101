import React from "react";
import { Helmet } from "react-helmet";
const AuthLayout = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section
        className="vh-100 auth-backgroud"
        //style={{ backgroundColor: "#f4f4f4" }}
      >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                //style={{ borderRadius: "1rem" }}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthLayout;
