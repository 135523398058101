import { FormattedMessage } from "react-intl";

const data = [
  {
    id: "1",
    icon: "bx bx-user-pin",
    label: <FormattedMessage id="menu.sales" />,
    to: "/backend/salestable",
  },
  {
    id: "10",
    icon: "bx bx-home",
    label: <FormattedMessage id="menu.reports" />,
    subs: [
      {
        id: 11,
        icon: "",
        label: <FormattedMessage id="menu.reports.dashboards" />,
        to: "/backend/reportsdashboard",
      },
      {
        id: 12,
        icon: "",
        label: <FormattedMessage id="menu.reports.sales" />,
        to: "/backend/reportssalesReport",
      },
    ],
  },
  {
    id: "20",
    icon: "bx bx-sitemap",
    label: <FormattedMessage id="menu.stocks" />,
    subs: [
      {
        id: 21,
        icon: "",
        label: <FormattedMessage id="menu.stocks.items" />,
        to: "/backend/products",
      },
      {
        id: 22,
        icon: "",
        label: <FormattedMessage id="menu.stocks.itemsGroup" />,
        to: "/backend/productgroups",
      },
    ],
  },
];
export default data;
