import { useState, useEffect } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import SettingTransferAddModel from "./SettingTransferAddModel";
import SettingTransferEditModel from "./SettingTransferEditModel";

import http from "../../../services/backend/http";

const SettingTransfer = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.setting.payment" });
  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });
  const [settingID, setSettingID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [settingPP, setSettingPP] = useState(false);
  const [settingPPTable, setSettingPPTable] = useState(null);
  const [settingGB, setSettingGB] = useState(false);
  const [settingGBToken, setSettingGBToken] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/settings`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setSettingID(resp.data.data[0]._id);
      setSettingPPTable(resp.data.data[0].ppid);
      setSettingGBToken(resp.data.data[0].payment_token);
      if (resp.data.data[0].transfer_type === 0) {
        setSettingPP(true);
        setSettingGB(false);
      }
      if (resp.data.data[0].transfer_type === 1) {
        setSettingPP(false);
        setSettingGB(true);
      }
    }
  };

  const setSetting = async () => {
    let paymentType = 0;

    if (settingPP === true) {
      paymentType = 0;
    }
    if (settingGB === true) {
      paymentType = 1;
    }

    const newData = {
      branch_id: auth.branch.branch_id,
      settingType: "Payment",
      transfer_type: paymentType,
      payment_token: settingGBToken,
    };

    try {
      setIsLoading(true);
      await http.put(`setting/settings/${settingID}`, newData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const togglePP = () => {
    if (settingPP === true) {
      setSettingGB(true);
    } else {
      setSettingGB(false);
    }
    setSettingPP(!settingPP);
  };

  const toggleGB = () => {
    if (settingGB === true) {
      setSettingPP(true);
    } else {
      setSettingPP(false);
    }
    setSettingGB(!settingGB);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  const handleDeleteClick = (id, ppid) => {
    Swal.fire({
      title: swalDelete,
      html: `${ppid}`,
      // text: "ยืนยันการลบรายการนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnConfrim,
      cancelButtonText: btnCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = {
          branch_id: auth.branch.branch_id,
        };

        http
          .delete(`setting/settings/payment/${id}`, {
            params: newData,
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "ลบรายการเรียบร้อย",
              timer: 1500,
            });

            getSetting();
          })
          .catch((error) => {
            if (error) {
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  text: error.message,
                  timer: 2500,
                });
              }
            }
          });
      }
    });
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3 fs-5">{title}</div>

        <div className="card-body">
          <div className="card">
            <div className="card-header mt-3 fs-5">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <Form.Check
                      type="switch"
                      id="switch_pp_toggle"
                      name="switch_pp_toggle"
                      onChange={() => togglePP()}
                      checked={settingPP}
                      label="พร้อมเพย์"
                    />
                    <Button
                      className="btn btn-sm btn-success"
                      onClick={toggleAddModal}
                    >
                      <FormattedMessage id="btn.add" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped mb-3 table-hover">
                      <thead>
                        <tr>
                          <th className="col-1">ลำดับ</th>
                          <th className="col-2">เปิดใช้งาน</th>
                          <th className="col-3">เลขพร้อมเพย์</th>
                          <th className="col-4">ชื่อพร้อมเพย์</th>
                          <th className="col-2 text-end"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {settingPPTable &&
                          settingPPTable.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {data.default === true
                                  ? "เปิดใช้งาน"
                                  : "ปิดใช้งาน"}
                              </td>
                              <td>{data.ppid}</td>
                              <td>{data.name}</td>
                              <td className="text-end">
                                <Button
                                  className="btn btn-sm btn-warning me-1"
                                  onClick={() => toggleEditModal(data._id)}
                                >
                                  <FormattedMessage id="btn.edit" />
                                </Button>
                                <Button
                                  className="btn btn-sm btn-danger"
                                  onClick={() =>
                                    handleDeleteClick(data._id, data.ppid)
                                  }
                                >
                                  <FormattedMessage id="btn.delete" />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header mt-3 fs-5">
              <Form.Check
                type="switch"
                id="switch_gb_toggle"
                name="switch_gb_toggle"
                onChange={() => toggleGB()}
                checked={settingGB}
                label="GB Prime Pay"
              />
            </div>
            <div className="card-body">
              <div className="row border-bottom py-3">
                <div className="col-4 text-end align-middle">Token : </div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="unitQty"
                        name="unitQty"
                        type="text"
                        className="form-control"
                        value={settingGBToken}
                        onChange={(e) => setSettingGBToken(e.target.value)}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 py-3">
                <div className="col-4 text-end align-middle"></div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-12">
                      {isLoading === false ? (
                        <Button
                          variant="primary"
                          type="submit"
                          name="submit"
                          onClick={() => setSetting()}
                        >
                          <FormattedMessage id="btn.save" />
                        </Button>
                      ) : (
                        <div
                          className="spinner-border text-primary ms-3"
                          role="status"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row g-3">
            <div className="col-sm-12 px-5">
              <div className="row border-bottom py-3">
                <div className="col-4 text-end align-middle">ใช้งาน : </div>
                <div className="col-8 align-middle">
                  <Form.Check
                    type="switch"
                    id="switch_price_toggle"
                    name="switch_price_toggle"
                    // onChange={() => setSettingPrinterType(!settingPrinterType)}
                    // checked={settingPrinterType}
                  />
                </div>
              </div>
              <div className="row border-bottom py-3">
                <div className="col-4 text-end align-middle">Token : </div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-xs-12  col-md-8 col-lg-6">
                      <input
                        id="unitQty"
                        name="unitQty"
                        type="text"
                        className="form-control"
                        // value={settingPrinterSize}
                        // onChange={(e) => setSettingPrinterSize(e.target.value)}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 py-3">
                <div className="col-4 text-end align-middle"></div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-12">
                      {isLoading === false ? (
                        <Button
                          variant="primary"
                          type="submit"
                          name="submit"
                          //   onClick={() => setSetting()}
                        >
                          <FormattedMessage id="btn.save" />
                        </Button>
                      ) : (
                        <div
                          className="spinner-border text-primary ms-3"
                          role="status"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <SettingTransferAddModel
        addModal={addModal}
        setAddModal={setAddModal}
        toggleAddModal={toggleAddModal}
        getSetting={getSetting}
      />

      <SettingTransferEditModel
        editModal={editModal}
        editData={editData}
        toggleEditModal={toggleEditModal}
        getSetting={getSetting}
      />
    </BackendLayout>
  );
};

export default SettingTransfer;
