/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import http from "../../../services/backend/http";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
const Sales = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.setting.table" });
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightFooter, setHeightFooter] = useState(0);
  const [heightRowItemGroup, setHeightRowItemGroup] = useState(0);
  const [heightRowTotal, setHeightRowTotal] = useState(0);
  const [tableItemGroup, setTableItemGroup] = useState(null);
  const [tableItem, setTableItem] = useState(null);
  const [findItemGroup, setFindItemGroup] = useState("");

  useEffect(() => {
    let elementWrapper = document.getElementById("wrapper").classList;
    elementWrapper.add("toggled");

    //document.body.style.overflow = "hidden";
    let pagetopbar = document.getElementById("topbar");
    let pagefooter = document.getElementById("pagefooter");
    let rowItemGroup = document.getElementById("rowItemGroup");
    let rowTotal = document.getElementById("rowTotal");

    setHeightHeader(pagetopbar.offsetHeight + 15);
    setHeightFooter(pagefooter.offsetHeight + 15);
    setHeightRowItemGroup(rowItemGroup.offsetHeight);
    setHeightRowTotal(rowTotal.offsetHeight + 20);

    getItemGroup();
  }, []);

  useEffect(() => {
    // console.log(findItemGroup);
    getItem();
  }, [findItemGroup]);

  //////  call api Start
  const getItemGroup = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      setTableItemGroup(null);
      const resp = await http.get(`item_group`, { params: newData });
      setTableItemGroup(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getItem = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const searchgroup = findItemGroup === "" ? "a" : findItemGroup;
      setTableItem(null);
      const resp = await http.get(`item/searchgroup/${searchgroup}/search/a/`, {
        params: newData,
      });
      setTableItem(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  /////  call api End

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div
        className="container-fluid"
        style={{
          height: `calc(100vh - ${heightHeader}px - ${heightFooter}px )`,
        }}
      >
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <div id="rowItemGroup" className="row">
              <div className="card mb-1">
                <ul className="nav nav-pills " role="tablist">
                  <li className="nav-item" onClick={() => setFindItemGroup("")}>
                    <a
                      className={
                        findItemGroup === "" ? "nav-link active" : "nav-link"
                      }
                      href="#"
                    >
                      <i className="fa fa-tags" /> ทั้งหมด
                    </a>
                  </li>
                  {tableItemGroup &&
                    tableItemGroup.map((data, index) => (
                      <li
                        className="nav-item"
                        key={index}
                        onClick={() => setFindItemGroup(data._id)}
                      >
                        <a
                          className={
                            findItemGroup === data.id
                              ? "nav-link active"
                              : "nav-link"
                          }
                          href="#"
                        >
                          <i className="fa fa-tags " /> {data.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div
              className="flex-column m-0 p-0"
              style={{
                height: `calc(100vh - ${heightHeader}px - ${heightFooter}px - ${heightRowItemGroup}px )`,
                overflowX: "hidden",
                overflowY: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <div className="row g-2 mt-0">
                {tableItem &&
                  tableItem.map((data, index) => (
                    <div key={index} className="col-md-4 col-lg-3">
                      <figure className="card card-product">
                        <div className="img-wrap">
                          <img
                            src={data.photo_url}
                            crossOrigin="anonymous"
                            alt={data.name}
                          />
                          {/* <a className="btn-overlay" href="#">
                            <i className="fa fa-search-plus"></i>เพิ่มสินค้า
                          </a> */}
                        </div>
                        <figcaption className="info-wrap text-center fw-bold fs-5">
                          <a href="#" className="title">
                            {data.name}
                          </a>
                        </figcaption>
                      </figure>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div
              id="rowMainTotal"
              className="row ms-0"
              style={{
                height: `calc(100vh - ${heightHeader}px - ${heightFooter}px + 15px`,
                overflow: "hidden",
              }}
            >
              <div className="card">
                <div id="rowTotal" className="row h4 g-3 mt-0">
                  <div className="col-md-4 col-lg-3  text-start">ลด</div>
                  <div className="col-md-8 col-lg-9  text-end">
                    <input
                      type="text"
                      className="form-control text-end"
                      defaultValue="0"
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-md-4 col-3  text-start">รวม</div>
                  <div className="col-md-8 col-9  text-end">60.00</div>
                  <div className="col-md-4 col-3  text-start">สุทธิ</div>
                  <div className="col-md-8 col-9  text-end">
                    <h3 className="text-primary fw-bold">60.00</h3>
                  </div>

                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-success btn-lg form-control"
                    >
                      <h2 className="text-white">ชำระเงิน</h2>
                    </button>
                  </div>
                </div>
                <div
                  className="flex-column"
                  style={{
                    height: `calc(100vh - ${heightHeader}px - ${heightFooter}px - ${heightRowTotal}px`,
                    overflowX: "hidden",
                    overflowY: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="table-responsive">
                    <table className="table table-striped table-hover fs-6">
                      <tbody>
                        <tr>
                          <td className="text-start align-middle fw-bold">
                            1. ก๋วยเตี๋ยวหมู
                            <br />
                            <div className="input-group">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ width: "55px" }}
                              >
                                ลบ
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ width: "55px" }}
                              >
                                แก้ไข
                              </button>
                            </div>
                          </td>
                          <td className="text-end">
                            20.00
                            <br />
                            x 3
                            <br />
                            <span className="fw-bold">60.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default Sales;
