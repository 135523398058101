import React, { useEffect, useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import http from "../../../services/backend/http";
import commaNumber from "comma-number";
import { Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";

const ReportSumMonth = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.reportsum.month" });
  const [startDate, setStartDate] = useState(new Date());
  const [dataGraph, setDataGraph] = useState(null);
  const [reportSalesTable, setReportSalesTable] = useState(null);
  const [dataStep, setDataStep] = useState({
    max: 6000,
    step: 2000,
  });
  useEffect(() => {
    getReport(startDate);
  }, [startDate]);

  const getReport = async (startDate) => {
    const daysInMonth = moment(startDate, "YYYY-MM").daysInMonth();
    const month = moment(startDate).format("MM");
    const year = moment(startDate).format("YYYY");
    const dateStart = `01-${month}-${year}`;
    const dateEnd = `${daysInMonth}-${month}-${year}`;

    const newData = {
      branch_id: auth.branch.branch_id,
      reportDate: "",
      sdateSelect: dateStart,
      edateSelect: dateEnd,
    };

    const resp = await http.get(`report/sales/summary`, { params: newData });
    setReportSalesTable(resp.data.data);

    // const respPurchase = await http.get(`report/purchase/summary`, {
    //   params: newData,
    // });

    let labels = [];
    let dataFD = [];
    let dataFront = [];
    let dataSummary = [];
    let dataPurchase = [];

    const maxSales = Math.max(...resp.data.data.amount.map((o) => o.amount));

    // const maxPurchase = Math.max(
    //   ...respPurchase.data.data.map((o) => o.amount)
    // );

    calScales(maxSales);

    for (let i = 1; i <= daysInMonth; i++) {
      const dateFound =
        i.toString().length === 1 ? `0${i.toString()}` : i.toString();
      const dateFound1 = `${dateFound}-${month}-${year}`;
      let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
        new Date(moment(`${month}-${dateFound}-${year}`)).getDay()
      ];

      labels.push(`${i.toString()} ${weekday}`);

      // const foundPurchase = respPurchase.data.data.find((obj) => {
      //   return obj._id.date === dateFound1;
      // });

      const foundFD = resp.data.data.fd.find((obj) => {
        return obj._id.date === dateFound1;
      });

      const foundCash = resp.data.data.cash.find((obj) => {
        return obj._id.date === dateFound1;
      });

      const foundTransfer = resp.data.data.transfer.find((obj) => {
        return obj._id.date === dateFound1;
      });

      const Cash = foundCash ? foundCash.amount : 0;
      const Transfer = foundTransfer ? foundTransfer.amount : 0;
      const FD = foundFD ? foundFD.amount : 0;
      //  const purchase = foundPurchase ? foundPurchase.amount : 0;

      // dataPurchase.push(purchase);
      dataFD.push(FD);
      dataFront.push(Cash + Transfer);
      dataSummary.push(Cash + Transfer + FD);
    }
    const sumdata = { labels, dataFD, dataFront, dataSummary };
    setDataGraph(sumdata);
  };

  const calScales = (maxValue) => {
    const addValue = 500 - (maxValue % 100);
    const newValue = maxValue + addValue + 500;

    let scales = {
      max: 0,
      step: 0,
    };

    if (maxValue > 0) {
      scales = {
        max: newValue,
        step: newValue / 4,
      };
    }
    setDataStep(scales);
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
        <div className="col fw-bold">
          <div className="card radius-10 bg-info bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เดือน</p>
                  <div className="my-1 text-white fw-bold">
                    <DatePicker
                      className="form-control"
                      todayButton="Today"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-warning bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">FD</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.fd.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        )
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-success bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">หน้าร้าน</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.transfer.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        ) +
                          reportSalesTable.cash.reduce(
                            (a, v) => (a = a + v.amount),
                            0
                          )
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-primary bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เงินรวม</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportSalesTable &&
                      commaNumber(
                        reportSalesTable.amount.reduce(
                          (a, v) => (a = a + v.amount),
                          0
                        )
                      )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {dataGraph && (
          <Line
            options={{
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "จำนวนเงิน",
                  },
                  ticks: {
                    stepSize: dataStep.step,
                  },
                  min: 0,
                  max: dataStep.max,
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "วันที่",
                  },
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "black",
                  formatter: (v) => (v ? commaNumber(Math.round(v)) : ""),
                  anchor: "end",
                  offset: -30,
                  align: "start",
                },
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
              },
            }}
            data={{
              labels: dataGraph.labels,
              datasets: [
                {
                  fill: true,
                  label: "Sales Total",
                  data: dataGraph.dataSummary,
                  borderColor: "rgb(51, 204, 51)",
                  backgroundColor: "rgba(51, 204, 51, 0.2)",
                },
              ],
            }}
            height={120}
          />
        )}
      </div>
      <div className="row">
        {dataGraph && (
          <Line
            options={{
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "จำนวนเงิน",
                  },
                  ticks: {
                    stepSize: dataStep.step,
                  },
                  min: 0,
                  max: dataStep.max,
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "วันที่",
                  },
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "black",
                  formatter: (v) => (v ? commaNumber(Math.round(v)) : ""),
                  anchor: "end",
                  offset: -30,
                  align: "start",
                },
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
              },
            }}
            data={{
              labels: dataGraph.labels,
              datasets: [
                {
                  fill: true,
                  label: "Cash + Transfer",
                  data: dataGraph.dataFront,
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                // {
                //   fill: true,
                //   label: "Expense",
                //   data: dataGraph.dataPurchase,
                //   borderColor: "rgb(102, 51, 0 ,0.5)",
                //   backgroundColor: "rgba(102, 51, 0, 0.3)",
                // },
              ],
            }}
            height={120}
          />
        )}
      </div>

      <div className="row">
        {dataGraph && (
          <Line
            options={{
              scales: {
                yAxis: {
                  title: {
                    display: true,
                    text: "จำนวนเงิน",
                  },
                  ticks: {
                    stepSize: dataStep.step,
                  },
                  min: 0,
                  max: dataStep.max,
                },
                xAxis: {
                  title: {
                    display: true,
                    text: "วันที่",
                  },
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "black",
                  formatter: (v) => (v ? commaNumber(Math.round(v)) : ""),
                  anchor: "end",
                  offset: -30,
                  align: "start",
                },
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
              },
            }}
            data={{
              labels: dataGraph.labels,
              datasets: [
                {
                  fill: true,
                  label: "FD",
                  data: dataGraph.dataFD,
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                // {
                //   fill: true,
                //   label: "Expense",
                //   data: dataGraph.dataPurchase,
                //   borderColor: "rgb(102, 51, 0 ,0.5)",
                //   backgroundColor: "rgba(102, 51, 0, 0.3)",
                // },
              ],
            }}
            height={120}
          />
        )}
      </div>
    </BackendLayout>
  );
};

export default ReportSumMonth;
