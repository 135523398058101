import { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/login/Login";
import Forgetpassword from "../pages/auth/resetpassword/Forgetpassword";
import Resetpassword from "../pages/auth/resetpassword/Resetpassword";
import ResetpasswordBackend from "../pages/backend/resetpassword/Resetpassword";
import Notfound from "../pages/notfound/Notfound";

import Sales from "../pages/backend/sales/Sales";
import SalesTable from "../pages/backend/sales_table/SalesTable";

import Purchase from "../pages/backend/purchase/Purchase";

import ReportsDashboard from "../pages/backend/reports/dashboard/Dashboard";
import ReportsSalesReport from "../pages/backend/reports/sales_reports/SalesReport";
import BalanceReport from "../pages/backend/reports/balance_reports/BalanceReport";
import PurchaseReportItem from "../pages/backend/reports/purchase_reports_item/PurchaseReportItem";
import SalesReportItem from "../pages/backend/reports/sales_report_item/SalesReportItem";

import ReportSumMonth from "../pages/backend/dashboard/ReportSumMonth";
import ReportSumYear from "../pages/backend/dashboard/ReportSumYear";
import ReportSumMonthCompare from "../pages/backend/dashboard/ReportSumMonthCompare";

import Dashboard from "../pages/backend/dashboard/Dashboard";
import Users from "../pages/backend/users/Users";
import Products from "../pages/backend/product/Products";
import ProductGroups from "../pages/backend/product_group/ProductGroups";

import Setting from "../pages/backend/setting/Setting";
import SettingDocument from "../pages/backend/setting/SettingDocument";
import SettingLineNoti from "../pages/backend/setting/SettingLineNoti";
import SettingPrinter from "../pages/backend/setting/SettingPrinter";
import SettingTable from "../pages/backend/setting/SettingTable";
import SettingTransfer from "../pages/backend/setting/SettingTransfer";

import LayoutTables from "../pages/backend/setting_layout_table/LayoutTables";

import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import AppLocale from "../constants/lang/index";

// const Dashboard = lazy(() => import("../pages/backend/dashboard/Dashboard"));
// const Users = lazy(() => import("../pages/backend/users/Users"));

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Router = () => {
  const LangReducer = useSelector((state) => state.LangReducer.lang);
  const auth = useSelector((state) => state.AuthReducer.auth);
  const currentAppLocale = AppLocale[LangReducer];
  const socketRef = useRef();

  // const socket = socketIO.connect(process.env.REACT_APP_BASE_URL_IO);
  return (
    // <Suspense fallback={<div>Loading...</div>}>
    <IntlProvider locale={LangReducer} messages={currentAppLocale.messages}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/login/:com" element={<Login />} />
          <Route path="/forgetpassword/:com" element={<Forgetpassword />} />
          <Route
            path="/resetpassword/:com/:email/:userid"
            element={<Resetpassword />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/backend/sales" element={<Sales />} />
          <Route
            path="/backend/salestable"
            element={<SalesTable socketRef={socketRef} />}
          />
          <Route path="/backend/purchase" element={<Purchase />} />

          <Route
            path="/backend/reportsdashboard"
            element={<ReportsDashboard socketRef={socketRef} />}
          />
          <Route
            path="/backend/reportssalesReport"
            element={<ReportsSalesReport socketRef={socketRef} />}
          />
          <Route
            path="/backend/reportsbalanceReport"
            element={<BalanceReport socketRef={socketRef} />}
          />
          <Route
            path="/backend/reportspurchasereportitem"
            element={<PurchaseReportItem />}
          />
          <Route
            path="/backend/reportssalesreportitem"
            element={<SalesReportItem />}
          />

          <Route path="/backend/reportsummonth" element={<ReportSumMonth />} />
          <Route path="/backend/reportsumyear" element={<ReportSumYear />} />
          <Route
            path="/backend/reportsumyearcompare"
            element={<ReportSumMonthCompare />}
          />

          <Route path="/backend/dashboard" element={<Dashboard />} />

          {auth && auth.newAuth.user.role === "admin" && (
            <Route path="/backend/users" element={<Users />} />
          )}

          <Route
            path="/backend/resetpassword"
            element={<ResetpasswordBackend />}
          />
          <Route path="/backend/setting" element={<Setting />} />
          <Route
            path="/backend/settingdocument"
            element={<SettingDocument />}
          />
          <Route path="/backend/settingtable" element={<SettingTable />} />
          <Route
            path="/backend/settinglinenoti"
            element={<SettingLineNoti />}
          />
          <Route path="/backend/settingprinter" element={<SettingPrinter />} />
          <Route
            path="/backend/settingtransfer"
            element={<SettingTransfer />}
          />

          <Route path="/backend/products" element={<Products />} />
          <Route path="/backend/productgroups" element={<ProductGroups />} />
          <Route path="/backend/layouttables" element={<LayoutTables />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
    </IntlProvider>
  );
};
export default Router;
