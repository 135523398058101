import { combineReducers } from "redux";
import AuthCompanyReducer from "./AuthCompanyReducer";
import LangReducer from "./LangReducer";
import AuthReducer from "./AuthReducer";
import SettingReducer from "./SettingReducer";

const RootReducer = combineReducers({
  LangReducer,
  AuthReducer,
  AuthCompanyReducer,
  SettingReducer,
});

export default RootReducer;
