import { useState, useEffect } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import http from "../../../services/backend/http";

const SettingTable = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.setting.table" });

  const [settingTableType, setSettingTableType] = useState(false);
  const [settingTableNumber, setSettingTableNumber] = useState(0);
  const [settingID, setSettingID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/settings`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setSettingTableType(resp.data.data[0].table_type);
      setSettingTableNumber(resp.data.data[0].table_number);
      setSettingID(resp.data.data[0]._id);
    }
  };

  const setSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
      settingType: "Table",
      table_type: settingTableType,
      table_number: settingTableNumber,
    };

    try {
      setIsLoading(true);
      await http.put(`setting/settings/${settingID}`, newData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3 fs-5">{title}</div>

        <div className="card-body">
          <div className="row g-3">
            <div className="col-sm-12 px-5">
              <div className="row border-bottom py-3">
                <div className="col-4 text-end align-middle">ใช้งาน : </div>
                <div className="col-8 align-middle">
                  <Form.Check
                    type="switch"
                    id="switch_price_toggle"
                    name="switch_price_toggle"
                    onChange={() => setSettingTableType(!settingTableType)}
                    checked={settingTableType}
                  />
                </div>
              </div>
              <div className="row border-bottom py-3">
                <div className="col-4 text-end align-middle">จำนวนโต๊ะ : </div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-xs-12  col-md-8 col-lg-6">
                      <input
                        min={0}
                        id="unitQty"
                        name="unitQty"
                        type="number"
                        className="form-control text-center"
                        value={settingTableNumber}
                        onChange={(e) => setSettingTableNumber(e.target.value)}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 py-3">
                <div className="col-4 text-end align-middle"></div>
                <div className="col-8 align-middle">
                  <div className="row">
                    <div className="col-12">
                      {isLoading === false ? (
                        <Button
                          variant="primary"
                          type="submit"
                          name="submit"
                          onClick={() => setSetting()}
                        >
                          <FormattedMessage id="btn.save" />
                        </Button>
                      ) : (
                        <div
                          className="spinner-border text-primary ms-3"
                          role="status"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};
export default SettingTable;
