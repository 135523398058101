module.exports = {
  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.Menu */
  "menu.sales": "Orders",
  "menu.purchase": "Purchases",
  "menu.reports": "Reports",
  "menu.reports.dashboards": "Dashboards",
  "menu.reports.sales": "Sales Report",
  "menu.reports.balance": "Balance Report",
  "menu.reports.purchase": "Purchases Report",
  "menu.reports.purchaseitem": "Purchases Report by item",
  "menu.reports.salesitem": "รSales Report by item",

  "menu.reportsum": "Reports Summary",
  "menu.reportsum.month": "Reports by date",
  "menu.reportsum.year": "Reports by month (type)",
  "menu.reportsum.year.compare": "Reports by month (summary)",

  "menu.dashboards": "Dashboards",
  "menu.dashboards01": "Dashboards01",
  "menu.dashboards02": "Dashboards02",
  "menu.users": "Users",
  "menu.users01": "users01",
  "menu.users02": "users02",
  "menu.setting": "Setting",
  "menu.setting.table": "Setting Table",
  "menu.setting.printer": "Setting Printer",
  "menu.setting.document": "Setting Document",
  "menu.setting.payment": "Setting Payment",
  "menu.setting.linenoti": "Setting Line Noti",

  "menu.stocks": "Stocks",
  "menu.stocks.items": "Items",
  "menu.stocks.itemsGroup": "Items Group",

  /* 03.Navbar */
  "navbar.profile": "Profile",
  "navbar.resetpassword": "Reset Password",
  "navbar.logout": "Logout",

  /* 04 btn */
  "btn.add": "Add",
  "btn.edit": "Edit",
  "btn.delete": "Delete",
  "btn.save": "Save",
  "btn.close": "Close",
  "btn.search": "Search",
  "btn.clear": "Clear",
  "btn.cancel": "Cancel",
  "btn.confrim": "Confrim",
  "btn.select": "Select Option",
  "btn.manage": "Manage",
  "btn.loadmaster_group": "Load default data per group",

  /* Sales  */
  "sale.no": "No",
  "sale.amt": "Amount",
  "sale.grandamt": "Total",
  "sale.list": "Description",
  "sale.listno": "Description",
  "sale.listorder": "Description order",

  "sale.table": "Table",
  "sale.tableno": "Table No.",
  "sale.disconut": "Discount",
  "sale.total": "total",
  "sale.grandtotal": "Grand total",
  "sale.pay": "Payment",
  "sale.order": "Order",

  "sale.tabletotal": "Total table",
  "sale.tablefree": "Available",
  "sale.tableuse": "Unavailable",
  "sale.tablecounter": "counter",

  /* Modal alert */
  "modal.add": "Add Data",
  "modal.edit": "Edit Data",

  /* Sweet alert */
  "swal.delete": "Are you sure to delete?",

  "tb.item_type": "Item type",
  "tb.item_type_value0": "Sales",
  "tb.item_type_value1": "Purchases",
  "tb.item_type_value2": "Sales/Purchases",
  "tb.item_type_value3": "FD",

  /* label */
  "tb.sorting": "Sorting",
  "tb.sorting_yup": "Please input sorting",

  "tb.item_head_item": "Item Description",
  "tb.item_head_price": "Item Price",
  "tb.item_head_stock": "Item Stock",
  "tb.item_head_topping": "Item Topping",
  "tb.item_head_other": "Item Other",
  "tb.item_head_image": "Item Image",

  "tb.item_id": "ID",
  "tb.item_name": "Name",
  "tb.item_description": "Description",
  "tb.item_barcode": "Barcode",
  "tb.item_groupAdd": "(+Add Item Groups)",
  "tb.item_group": "Item Group",
  "tb.item_group_name": "Item group name",
  "tb.item_price_unit": "Prices/Sizes",
  "tb.item_unit_price": "Price",
  "tb.item_unit_cost": "Cost",
  "tb.item_unit_size": "Size",
  "tb.item_no": "No",
  "tb.item_discount": "Discount",
  "tb.item_unit_stock": "Onhand",
  "tb.item_unit_safetystock": "Safety stock",
  "tb.item_unit": "Unit",
  "tb.item_unitAdd": "(+Add Units)",

  "tb.item_switch_stock": "Unlimit stock (services job)",
  "tb.item_switch_topping": "Topping",
  "tb.item_switch_vat": "Include VAT",

  "tb.item_topping_Group": "Topping group",
  "tb.item_topping_type1": "Select one option",
  "tb.item_topping_type2": "Select more options",

  "tb.item_yup_topping_Group": "Please input topping group",
  "tb.item_yup_topping_GroupType": "Please input topping type",

  "tb.item_yup_name": "Please input item name",
  "tb.item_yup_description": "Please input item description",
  "tb.item_yup_itemUnit": "Please input item unit",
  "tb.item_yup_itemType": "Please input item type",
  "tb.item_yup_unit_cost": "Please input item cost",
  "tb.item_yup_unit_cost_min": "Please input item cost more than 0",
  "tb.item_yup_unit_price": "Please input item price",
  "tb.item_yup_unit_price_min": "Please input item price more than 0",
  "tb.item_yup_nameGroup": "Please input item group",

  //// Users
  "tb.user_head_username": "Username",
  "tb.user_head_name": "Name",
  "tb.user_head_role": "Role",
  "tb.user_head_status": "Status",
  "tb.user_head_branch": "Branch",

  "tb.user_password": "Password",
  "tb.user_password2": "Password confirm",
  "tb.user_showpassword": "Show password",

  "tb.user_yup_username": "Please input username",
  "tb.user_yup_name": "Please input name",
  "tb.user_yup_role": "Please input role",
  "tb.user_yup_password": "Please input password",
  "tb.user_yup_password2": "Please input password confirm",
  "tb.user_yup_passwordmin": "Should be 6 chars minimum",
  "tb.user_yup_checkpassword": "Password confirm incorrect",
  "tb.user_yup_branch": "Please input branch",
};
