import Pagination from "react-js-pagination";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import {
  paginatePerPage,
  paginateRangeDisplayed,
} from "../../constants/default/default";

const Paginate = (props) => {
  const {
    paginatePage,
    handlePaginatePageChange,
    pageNumber,
    total,
    handlePageChange,
  } = props;

  return (
    <>
      <div className="input-group">
        <MDBDropdown group className="me-2 h-100">
          <MDBDropdownToggle size="sm" tag="a" className="btn btn-light">
            {paginatePage}
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            {paginatePerPage &&
              paginatePerPage.map((data) => (
                <MDBDropdownItem
                  onClick={() => handlePaginatePageChange(data)}
                  key={data}
                  link
                >
                  {data}
                </MDBDropdownItem>
              ))}
          </MDBDropdownMenu>
        </MDBDropdown>

        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={pageNumber}
          itemsCountPerPage={paginatePage}
          totalItemsCount={total}
          pageRangeDisplayed={paginateRangeDisplayed}
          onChange={handlePageChange}
          nextPageText="&gt;"
          prevPageText="&lt;"
          firstPageText="&lt;&lt;"
          lastPageText="&gt;&gt;"
        />
      </div>
    </>
  );
};

export default Paginate;
