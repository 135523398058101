import React, { useEffect, useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PurchaseAddModal from "./PurchaseAddModal";
import PurchaseEditModal from "./PurchaseEditModal";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import http from "../../../services/backend/http";
import commaNumber from "comma-number";
import { purchaseType } from "../../../constants/default/default";
const Purchase = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [sdate, setSdate] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edate, setEdate] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [sdateSelect, setSdateSelect] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edateSelect, setEdateSelect] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );

  const title = useIntl().formatMessage({ id: "menu.purchase" });
  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });
  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const btnEdit = useIntl().formatMessage({ id: "btn.edit" });
  const btnDelete = useIntl().formatMessage({ id: "btn.delete" });
  const btnAdd = useIntl().formatMessage({ id: "btn.add" });
  const btnClear = useIntl().formatMessage({ id: "btn.clear" });

  const [editData, setEditData] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [piTable, setpiTable] = useState(null);
  const [piTableDetail, setpiTableDetail] = useState(null);
  const [reportTableDetailSelect, setReportTableDetailSelect] = useState(null);

  //---- ToggleAddModal Start ----//
  const toggleAddModal = () => {
    setAddModal(!addModal);
  };
  const toggleEditModal = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  const getDateSelect = (start, end, label) => {
    const ddStart = start._d.getDate().toString().padStart(2, "0");
    const mmStart = (start._d.getMonth() + 1).toString().padStart(2, "0");
    const yyStart = start._d.getFullYear();
    const dateStart = `${ddStart}-${mmStart}-${yyStart}`;

    const ddEnd = end._d.getDate().toString().padStart(2, "0");
    const mmEnd = (end._d.getMonth() + 1).toString().padStart(2, "0");
    const yyEnd = end._d.getFullYear();
    const dateEnd = `${ddEnd}-${mmEnd}-${yyEnd}`;

    setSdate(start);
    setEdate(end);
    setSdateSelect(dateStart);
    setEdateSelect(dateEnd);
  };
  const handleSearch = async (e) => {
    try {
      let sd = moment(sdateSelect, "DD-MM-YYYY");
      let ed = moment(edateSelect, "DD-MM-YYYY");

      const newData = {
        branch_id: auth.branch.branch_id,
        sdateSelect: moment(sd).format("DD-MM-YYYY"),
        edateSelect: moment(ed).format("DD-MM-YYYY"),
      };

      const resp = await http.get(`report/purchase/pidate`, {
        params: newData,
      });

      setpiTable(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStringDay = (new_date) => {
    // console.log(moment(new_date, "DD-MM-YYYY"));
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
      new Date(moment(moment(new_date, "DD-MM-YYYY"))).getDay()
    ];
    return "#" + weekday;
  };

  const handleSearchDetail = async (data) => {
    const newData = {
      branch_id: auth.branch.branch_id,
      doc_no: data,
    };
    setReportTableDetailSelect(data);
    const resp = await http.get(`report/purchase/pidatedetail`, {
      params: newData,
    });
    setpiTableDetail(resp.data.data);
  };

  const getPurchaseType = (id) => {
    const findType = purchaseType.find((obj) => {
      return obj._id === id;
    });

    return findType ? findType._name : "";
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col-4">
                    <DateRangePicker
                      initialSettings={{
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                        startDate: sdate,
                        endDate: edate,
                      }}
                      //  onEvent={(event, picker) => console.log(picker.startDate)}
                      onCallback={(start, end, label) =>
                        getDateSelect(start, end, label)
                      }
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </div>
                  <div className="col-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        //   placeholder={`${btnSearch} : ${tbItem_id} ${tbItem_name}  ${tbItem_barcode}`}
                        //   onChange={(e) => setSearchText(e.target.value)}
                        //   value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-success me-2"
                          onClick={() => handleSearch()}
                        >
                          {btnSearch}
                        </button>
                        <a
                          type="button"
                          className="btn btn-sm btn-white me-2"
                          //  onClick={handleClearSearch}
                        >
                          {btnClear}
                        </a>
                        <a
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={toggleAddModal}
                        >
                          {btnAdd}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div className="table-responsive">
                <table className="table table-striped mb-3 table-hover">
                  <thead>
                    <tr>
                      <th className="col-1">ลำดับ</th>
                      <th className="col-2 text-center">วันที่</th>
                      <th className="col-3 text-center">ประเภท</th>
                      <th className="col-3 text-center">เลขเอกสาร</th>
                      <th className="col-3 text-center">จำนวนเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {piTable &&
                      piTable.map((data, index) => (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSearchDetail(data.doc_no)}
                          onDoubleClick={() => toggleEditModal(data._id)}
                        >
                          <td className="col-1">{index + 1}</td>
                          <td className="col-2 text-center">
                            {data.date}
                            {getStringDay(data.date)}
                          </td>
                          <td className="col-3 text-center">
                            {getPurchaseType(data.purchaseType)}
                          </td>
                          <td className="col-3 text-center">{data.doc_no}</td>
                          <td className="col-3 text-center">
                            {commaNumber(data.amount)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="col-1"></th>
                      <th className="col-2"></th>
                      <th className="col-3"> </th>
                      <th className="col-3"></th>
                      <th className="col-3 text-center">
                        {piTable
                          ? commaNumber(
                              piTable.reduce((a, v) => (a = a + v.amount), 0)
                            )
                          : 0}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div className="table-responsive">
                <table className="table table-striped mb-3 table-hover">
                  <thead>
                    <tr>
                      <th className="col-2">ลำดับ</th>
                      <th className="col-4">
                        รายการ#{reportTableDetailSelect}
                      </th>
                      <th className="col-3 text-center">จำนวน</th>
                      <th className="col-3 text-center">จำนวนเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {piTableDetail &&
                      piTableDetail.map((data, index) => (
                        <tr key={index}>
                          <td className="col-2">{index + 1}</td>
                          <td className="col-4">{data.item_name}</td>
                          <td className="col-3 text-center">
                            {`${data.unitQty} x ${data.unitPrice}`}
                          </td>
                          <td className="col-3 text-center">
                            {commaNumber(data.unitAmount)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="col-2"></th>
                      <th className="col-4"></th>
                      <th className="col-3 text-center"></th>
                      <th className="col-3 text-center">
                        {piTableDetail
                          ? commaNumber(
                              piTableDetail.reduce(
                                (a, v) => (a = a + v.unitAmount),
                                0
                              )
                            )
                          : 0}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PurchaseAddModal
        addModal={addModal}
        toggleAddModal={toggleAddModal}
        handleSearch={handleSearch}
      />
      <PurchaseEditModal
        editModal={editModal}
        toggleEditModal={toggleEditModal}
        handleSearch={handleSearch}
        editData={editData}
        handleSearchDetail={handleSearchDetail}
      />
    </BackendLayout>
  );
};

export default Purchase;
