import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../../services/backend/http";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";

const ProductGroupsAddModel = (props) => {
  const { addModal, toggleAddModal, getItemGroup } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [toggleTopping, setToggleTopping] = useState(false);
  const [togglePrice, setTogglePrice] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsToppingGroup, setInputFieldsToppingGroup] = useState([]);
  const [nameToppingGroup, setNameToppingGroup] = useState("");
  const [typeToppingGroup, setTypeToppingGroup] = useState("");
  const [errorTypeToppingGroup, setErrorTypeToppingGroup] = useState([]);

  const tbItem_switch_topping = useIntl().formatMessage({
    id: "tb.item_switch_topping",
  });
  const tbItem_head_topping = useIntl().formatMessage({
    id: "tb.item_head_topping",
  });
  const tbItem_group_name = useIntl().formatMessage({
    id: "tb.item_group_name",
  });
  const tbItem_head_price = useIntl().formatMessage({
    id: "tb.item_head_price",
  });
  const tbItem_yup_nameGroup = useIntl().formatMessage({
    id: "tb.item_yup_nameGroup",
  });
  const tbitem_topping_Group = useIntl().formatMessage({
    id: "tb.item_topping_Group",
  });
  const tbitem_topping_type1 = useIntl().formatMessage({
    id: "tb.item_topping_type1",
  });

  const tbitem_topping_type2 = useIntl().formatMessage({
    id: "tb.item_topping_type2",
  });

  const tbitem_yup_topping_Group = useIntl().formatMessage({
    id: "tb.item_yup_topping_Group",
  });

  const tbitem_yup_topping_GroupType = useIntl().formatMessage({
    id: "tb.item_yup_topping_GroupType",
  });
  const tbItem_unit_price = useIntl().formatMessage({
    id: "tb.item_unit_price",
  });
  const tbItem_unit_cost = useIntl().formatMessage({
    id: "tb.item_unit_cost",
  });
  const tbItem_price_unit = useIntl().formatMessage({
    id: "tb.item_price_unit",
  });
  const tbItem_no = useIntl().formatMessage({
    id: "tb.item_no",
  });

  const tbItem_unit_size = useIntl().formatMessage({
    id: "tb.item_unit_size",
  });

  const tbSorting = useIntl().formatMessage({
    id: "tb.sorting",
  });
  const tbSorting_yup = useIntl().formatMessage({
    id: "tb.sorting_yup",
  });
  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      nameGroup: yup.string().required(tbItem_yup_nameGroup),
      sorting: yup
        .number()
        .typeError(tbSorting_yup)
        .required("Please provide plan cost.")
        .min(0, tbSorting_yup),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  //---- Handle Function Start -----------------//
  const handleAdd = async (data) => {
    try {
      const newData = {
        ...data,
        branch_id: auth.branch.branch_id,
        sizing_type: togglePrice,
        sizing: inputFields,
        topping_type: toggleTopping,
        toppinggroup: inputFieldsToppingGroup,
      };

      await http.post("item_group", newData);
      getItemGroup();
      handleClickClose();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickClose = () => {
    reset();

    setInputFields([]);
    setInputFieldsToppingGroup([]);
    setToggleTopping(false);
    setTogglePrice(false);
    toggleAddModal();
    getItemGroup();
  };
  const handleClickPriceToggle = () => {
    if (togglePrice === true) {
      setInputFields([]);
    }
    setTogglePrice(!togglePrice);
  };

  const handleClickToppingToggle = async () => {
    if (toggleTopping === true) {
      setInputFieldsToppingGroup([]);
    }
    setToggleTopping(!toggleTopping);
  };

  /******** Size **** */

  const handleAddFields = () => {
    let newfield = {
      no: inputFields.length + 1,
      name: "",
    };
    setInputFields([...inputFields, newfield]);
  };

  const handleRemoveFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  /******** Size **** */

  /******** ToppingGroup **** */
  const checkError = (key) => {
    var result = errorTypeToppingGroup.findIndex((x) => x.name === key);
    if (result !== -1) {
      return errorTypeToppingGroup[result].error;
    }
  };
  const handleAddFieldsToppingGroup = async () => {
    let error = [];
    if (nameToppingGroup === "") {
      error.push({ name: "nameToppingGroup", error: tbitem_yup_topping_Group });
    }

    if (typeToppingGroup === "") {
      error.push({
        name: "typeToppingGroup",
        error: tbitem_yup_topping_GroupType,
      });
    }

    setErrorTypeToppingGroup(error);

    if (error.length !== 0) {
      return;
    }

    let newGroup = {
      no: inputFieldsToppingGroup.length + 1,
      name: nameToppingGroup,
      type: typeToppingGroup,
      topping: [],
    };

    setInputFieldsToppingGroup([...inputFieldsToppingGroup, newGroup]);
    setNameToppingGroup("");
    setTypeToppingGroup("");
  };

  const handleRemoveFieldsToppingGroup = (index) => {
    let data = [...inputFieldsToppingGroup];
    data.splice(index, 1);
    setInputFieldsToppingGroup(data);
  };

  const handleAddFieldsToppingSubGroup = async (index) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[index] };
    let arrayTopping = item.topping;

    let newfield = {
      no: arrayTopping.length + 1,
      default: false,
      name: "",
      unit_cost: 0,
      unit_price: 0,
    };

    arrayTopping = [...arrayTopping, newfield];
    item.topping = arrayTopping;
    items[index] = item;

    //console.log(items);
    setInputFieldsToppingGroup(items);
  };

  const handleRemoveFieldsTopping = (indexs, index) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[indexs] };
    let arrayTopping = item.topping;
    arrayTopping.splice(index, 1);
    item.topping = arrayTopping;
    items[indexs] = item;
    setInputFieldsToppingGroup(items);
  };

  const handleFormChangeTopping = (indexs, index, event) => {
    let items = [...inputFieldsToppingGroup];
    let item = { ...items[indexs] };
    let arrayTopping = item.topping;

    if (event.target.name === "default") {
      if (item.type === "toppingGroupType") {
        arrayTopping.map((i) => {
          i[event.target.name] = false;
        });
      }

      arrayTopping[index][event.target.name] = event.target.checked;
    } else {
      arrayTopping[index][event.target.name] = event.target.value;
    }
    item.topping = arrayTopping;
    items[indexs] = item;
    setInputFieldsToppingGroup(items);
  };

  return (
    <Modal show={addModal} onHide={toggleAddModal} backdrop="static">
      <form onSubmit={handleSubmit(handleAdd)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              <FormattedMessage id="modal.add" />
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div>
                <label className="form-label" htmlFor="nameGroup">
                  {tbItem_group_name}
                </label>
                <input
                  type="text"
                  id="nameGroup"
                  name="nameGroup"
                  className="form-control"
                  placeholder={tbItem_group_name}
                  {...register("nameGroup")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.nameGroup && errors.nameGroup.message}
              </p>
              <div>
                <label className="form-label" htmlFor="sorting">
                  {tbSorting}
                </label>
                <input
                  type="text"
                  id="sorting"
                  name="sorting"
                  placeholder={tbSorting}
                  className="form-control"
                  {...register("sorting")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.sorting && errors.sorting.message}
              </p>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="h6">
                <strong>{tbItem_head_price}</strong>
              </div>
              <div>
                <Form.Check
                  type="switch"
                  id="switch_price_toggle"
                  name="switch_price_toggle"
                  onClick={handleClickPriceToggle}
                  label={tbItem_price_unit}
                  defaultChecked={togglePrice}
                />
              </div>
              <Collapse in={togglePrice}>
                <div>
                  <div className="mb-2">
                    <Row>
                      <Col className="col-2">
                        <b>{tbItem_no}</b>
                      </Col>
                      <Col>
                        <b>{tbItem_unit_size}</b>
                      </Col>

                      <div
                        className="btn-close insert ms-1 me-2"
                        role="button"
                        onClick={handleAddFields}
                      ></div>
                    </Row>
                  </div>
                  <div>
                    {inputFields &&
                      inputFields.map((item, index) => (
                        <Row key={index} className="mb-1">
                          <Col className="col-2 ">
                            <input
                              type="text"
                              id="no"
                              name="no"
                              className="form-control"
                              placeholder={tbItem_no}
                              style={{ width: "110%" }}
                              value={item.no}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </Col>
                          <Col>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder={tbItem_unit_size}
                              style={{ width: "100%" }}
                              value={item.name}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              onFocus={(e) => e.target.select()}
                              //    {...register("description")}
                            />
                          </Col>
                          <div
                            className="btn-close  close mt-2 ms-1 me-2"
                            role="button"
                            onClick={() => handleRemoveFields(index)}
                          ></div>
                        </Row>
                      ))}
                  </div>
                </div>
              </Collapse>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="h6">
                <strong>{tbItem_head_topping}</strong>
              </div>
              <div>
                <Form.Check
                  type="switch"
                  id="switch_topping_toggle"
                  name="switch_topping_toggle"
                  onClick={handleClickToppingToggle}
                  label={tbItem_switch_topping}
                  defaultChecked={toggleTopping}
                />
              </div>
              <Collapse in={toggleTopping}>
                <div>
                  <div className="mb-2">
                    <Row>
                      <Col>
                        <input
                          type="text"
                          id="toppingGroup"
                          name="toppingGroup"
                          className="form-control"
                          placeholder={tbitem_topping_Group}
                          style={{ width: "105%" }}
                          onChange={(e) => setNameToppingGroup(e.target.value)}
                          value={nameToppingGroup}
                        />
                      </Col>
                      <Col className="d-flex">
                        <select
                          name="toppingGroupType"
                          id="toppingGroupType"
                          className="form-control me-2"
                          onChange={(e) => setTypeToppingGroup(e.target.value)}
                          value={typeToppingGroup}
                        >
                          <option value="">
                            <FormattedMessage id="btn.select" />
                          </option>
                          <option value="toppingGroupType">
                            {tbitem_topping_type1}
                          </option>
                          <option value="toppingGroupTypes">
                            {tbitem_topping_type2}
                          </option>
                        </select>
                        <div
                          className="btn btn-primary"
                          onClick={handleAddFieldsToppingGroup}
                        >
                          เพิ่ม
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-danger">
                          {checkError("nameToppingGroup")}
                        </div>
                      </Col>
                      <Col>
                        <div className="text-danger">
                          {checkError("typeToppingGroup")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {inputFieldsToppingGroup &&
                    inputFieldsToppingGroup.map((item, indexs) => (
                      <div className="card " key={indexs}>
                        <div className="card-header d-flex  justify-content-between">
                          <div className="text-left">
                            <strong>{item.name}</strong>
                            <br />
                            <span>
                              {item.type === "toppingGroupType"
                                ? tbitem_topping_type1
                                : tbitem_topping_type2}
                            </span>
                          </div>
                          <div
                            role="button"
                            className="btn-close"
                            color="none"
                            onClick={() =>
                              handleRemoveFieldsToppingGroup(indexs)
                            }
                          ></div>
                        </div>

                        <div className="p-2 px-3 mb-2">
                          <Row>
                            <Col>
                              <b>{tbItem_no}</b>
                            </Col>
                            <Col>
                              <b>{tbItem_switch_topping}</b>
                            </Col>
                            <Col>
                              <b>{tbItem_unit_cost}</b>
                            </Col>
                            <Col>
                              <b>{tbItem_unit_price}</b>
                            </Col>
                            <div
                              className="btn-close insert ms-1 me-2"
                              role="button"
                              onClick={() =>
                                handleAddFieldsToppingSubGroup(indexs)
                              }
                            ></div>
                          </Row>
                        </div>

                        {item.topping.map((topping, index) => (
                          <Row key={index} className="mb-1">
                            <Col className="d-flex pe-0">
                              <Form.Check
                                type="checkbox"
                                id="default"
                                name="default"
                                className="mt-2 ms-2 me-2"
                                checked={topping.default}
                                onChange={(event) =>
                                  handleFormChangeTopping(indexs, index, event)
                                }
                              />
                              <input
                                type="text"
                                id="no"
                                name="no"
                                className="form-control"
                                placeholder={tbItem_no}
                                style={{ width: "110%" }}
                                value={topping.no}
                                onChange={(event) =>
                                  handleFormChangeTopping(indexs, index, event)
                                }
                                onFocus={(e) => e.target.select()}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder={tbItem_switch_topping}
                                style={{ width: "110%" }}
                                value={topping.name}
                                onChange={(event) =>
                                  handleFormChangeTopping(indexs, index, event)
                                }
                                onFocus={(e) => e.target.select()}
                                //    {...register("description")}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="unit_cost"
                                name="unit_cost"
                                className="form-control"
                                placeholder="ขนาด"
                                style={{ width: "110%" }}
                                value={topping.unit_cost}
                                onChange={(event) =>
                                  handleFormChangeTopping(indexs, index, event)
                                }
                                onFocus={(e) => e.target.select()}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="unit_price"
                                name="unit_price"
                                className="form-control"
                                placeholder="ขนาด"
                                style={{ width: "110%" }}
                                value={topping.unit_price}
                                onChange={(event) =>
                                  handleFormChangeTopping(indexs, index, event)
                                }
                                onFocus={(e) => e.target.select()}
                                //    {...register("description")}
                              />
                            </Col>
                            <div
                              className="btn-close  close mt-2 ms-1 me-4"
                              role="button"
                              onClick={() =>
                                handleRemoveFieldsTopping(indexs, index)
                              }
                            ></div>
                          </Row>
                        ))}
                      </div>
                    ))}
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClickClose}>
            <FormattedMessage id="btn.close" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            name="submit"
            disabled={auth && auth.newAuth.user.role === "user" ? true : false}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ProductGroupsAddModel;
