import { GET_AUTH } from './../actions/AuthAction';
const initState = {
    auth: null
}


const authReducer = (state = initState, action) => {
    switch (action.type){
        case GET_AUTH:
            return {
                ...state,
                auth: action.payload.auth
            }
        default:
            return state
    }
    
}

export default authReducer