import React, { useState } from "react";
import { Link,useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import http from "../../../services/auth/http";
import Swal from "sweetalert2";

import AuthLayout from "../../../components/layouts/auth/AuthLayout";
const Forgetpassword = () => {
  const authCompany = useSelector((state) => state.AuthCompanyReducer.company);
  let { com } = useParams();
  var [isLoading, setIsLoading] = useState(false);
  //---------yub Start -----------//
  const schema = yup
    .object({
      username: yup
        .string()
        .required("The email field is required")
        .email("The email field Invalid Email Format"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---------yub End -----------//

  //---------handle Function Start -----------//
  const handleForgetPassword = async (data) => {
    try {
      setIsLoading(true);
      const newData = {
        email: data.username,
        company_id:com
      };

      var alert = document.getElementById("alert").classList;

      if (String(alert.value).includes("d-none")) {
      } else {
        alert.add("d-none");
      }
      const resp = await http.post("mail/forgetpassword", newData);
      if (resp.data.message === "Success") {
        setValue("username", "", { shouldDirty: true });

        if (String(alert.value).includes("d-none")) {
          alert.remove("d-none");
        }
      } else {
        console.log(resp.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Login infomation",
          text: error.response.data.error.message,
          timer: 2500,
        });
      }
      setIsLoading(false);
    }
  };

  //---------handle Function End-----------//

  return (
    <>
      <AuthLayout title={`Forget password | ${process.env.REACT_APP_NAME}`}>
        <div className="card-body p-5 text-center">
          <h4 className="mb-3">Forget Password</h4>
          <hr></hr>
          <form onSubmit={handleSubmit(handleForgetPassword)}>
            <div className="form-outline mb-4 text-start">
              <label className="form-label" htmlFor="username">
                Email
              </label>
              <input
                type="text"
                id="username"
                className="form-control form-control"
                placeholder="Email"
                {...register("username")}
              />
              <p className="text-danger mt-1">
                {errors.username && errors.username.message}
              </p>
            </div>

            <div id="alert" className="alert alert-success d-none" role="alert">
              Please check your email to reset your password
            </div>

            <div className="d-flex justify-content-start mt-4">
              <Link className="form-check-label" to={`/login/${authCompany}`}>
                Login
              </Link>
            </div>
            {isLoading ? (
              <div className="spinner-border text-primary" role="status" />
            ) : (
              <button className="btn btn-primary btn-block" type="submit">
                Submit
              </button>
            )}
          </form>
        </div>
      </AuthLayout>
    </>
  );
};

export default Forgetpassword;
