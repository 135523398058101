import { FormattedMessage } from "react-intl";

const data = [
  {
    id: "1",
    icon: "bx bx-archive-out",
    label: <FormattedMessage id="menu.sales" />,
    to: "/backend/salestable",
  },
  {
    id: "2",
    icon: "bx bx-archive-in",
    label: <FormattedMessage id="menu.purchase" />,
    to: "/backend/purchase",
  },
  {
    id: "10",
    icon: "bx bx-chart",
    label: <FormattedMessage id="menu.reports" />,
    subs: [
      {
        id: 11,
        icon: "",
        label: <FormattedMessage id="menu.reports.dashboards" />,
        to: "/backend/reportsdashboard",
      },
      {
        id: 12,
        icon: "",
        label: <FormattedMessage id="menu.reports.sales" />,
        to: "/backend/reportssalesReport",
      },
      {
        id: 13,
        icon: "",
        label: <FormattedMessage id="menu.reports.balance" />,
        to: "/backend/reportsbalanceReport",
      },
      {
        id: 14,
        icon: "",
        label: <FormattedMessage id="menu.reports.salesitem" />,
        to: "/backend/reportssalesreportitem",
      },
      {
        id: 15,
        icon: "",
        label: <FormattedMessage id="menu.reports.purchaseitem" />,
        to: "/backend/reportspurchasereportitem",
      },
    ],
  },
  {
    id: "50",
    icon: "bx bx-bar-chart",
    label: <FormattedMessage id="menu.reportsum" />,
    subs: [
      {
        id: 51,
        icon: "",
        label: <FormattedMessage id="menu.reportsum.month" />,
        to: "/backend/reportsummonth",
      },
      {
        id: 52,
        icon: "",
        label: <FormattedMessage id="menu.reportsum.year" />,
        to: "/backend/reportsumyear",
      },
      {
        id: 53,
        icon: "",
        label: <FormattedMessage id="menu.reportsum.year.compare" />,
        to: "/backend/reportsumyearcompare",
      },
    ],
  },
  {
    id: "20",
    icon: "bx bx-data",
    label: <FormattedMessage id="menu.stocks" />,
    subs: [
      {
        id: 21,
        icon: "",
        label: <FormattedMessage id="menu.stocks.items" />,
        to: "/backend/products",
      },
      {
        id: 22,
        icon: "",
        label: <FormattedMessage id="menu.stocks.itemsGroup" />,
        to: "/backend/productgroups",
      },
    ],
  },
  {
    id: "30",
    icon: "bx bx-user-pin",
    label: <FormattedMessage id="menu.users" />,
    to: "/backend/users",
  },
  {
    id: "40",
    icon: "bx bx-key",
    label: <FormattedMessage id="menu.setting" />,
    to: "/backend/setting",
    subs: [
      {
        id: 41,
        icon: "",
        label: <FormattedMessage id="menu.setting.document" />,
        to: "/backend/settingdocument",
      },
      {
        id: 42,
        icon: "",
        label: <FormattedMessage id="menu.setting.table" />,
        to: "/backend/settingtable",
      },
      {
        id: 43,
        icon: "",
        label: <FormattedMessage id="menu.setting.payment" />,
        to: "/backend/settingtransfer",
      },
      {
        id: 44,
        icon: "",
        label: <FormattedMessage id="menu.setting.linenoti" />,
        to: "/backend/settinglinenoti",
      },
      {
        id: 45,
        icon: "",
        label: <FormattedMessage id="menu.setting.printer" />,
        to: "/backend/settingprinter",
      },
    ],
  },
];
export default data;
