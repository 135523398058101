import React, { useEffect, useState } from "react";
import BackendLayout from "../../../../components/layouts/backend/BackendLayout";
import DateRangePicker from "react-bootstrap-daterangepicker";
import http from "../../../../services/backend/http";
import commaNumber from "comma-number";
import "bootstrap-daterangepicker/daterangepicker.css";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import socketIO from "socket.io-client";

const SalesReport = (props) => {
  const { socketRef } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.reports.sales" });
  const [reportDate, setReportDate] = useState("D");
  const [reportTable, setReportTable] = useState(null);
  const [reportTableDetail, setReportTableDetail] = useState(null);
  const [reportTableDetailSelect, setReportTableDetailSelect] = useState(null);
  const [totolDate, setTotalDate] = useState({ totalDays: 0, totalWork: 0 });
  const [tablePurchaseDetail, setTablePurchaseDetail] = useState(null);

  const [sdate, setSdate] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edate, setEdate] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );

  const [sdateSelect, setSdateSelect] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edateSelect, setEdateSelect] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );

  useEffect(() => {
    socketRef.current = socketIO.connect(process.env.REACT_APP_BASE_URL_IO);
    socketRef.current.on("order-done", (message) => {
      const { company_id, branch_id } = message;
      if (
        company_id === auth.newAuth.user.company_id &&
        branch_id === auth.branch.branch_id
      ) {
        // reportPreview();
        document.getElementById("reportPreview").click();
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const getDateSelect = (start, end, label) => {
    const ddStart = start._d.getDate().toString().padStart(2, "0");
    const mmStart = (start._d.getMonth() + 1).toString().padStart(2, "0");
    const yyStart = start._d.getFullYear();
    const dateStart = `${ddStart}-${mmStart}-${yyStart}`;

    const ddEnd = end._d.getDate().toString().padStart(2, "0");
    const mmEnd = (end._d.getMonth() + 1).toString().padStart(2, "0");
    const yyEnd = end._d.getFullYear();
    const dateEnd = `${ddEnd}-${mmEnd}-${yyEnd}`;

    setSdate(start);
    setEdate(end);
    setSdateSelect(dateStart);
    setEdateSelect(dateEnd);
  };

  const reportPreview = async () => {
    try {
      let sd = moment(sdateSelect, "DD-MM-YYYY");
      let ed = moment(edateSelect, "DD-MM-YYYY");

      const newData = {
        branch_id: auth.branch.branch_id,
        reportDate: reportDate,
        sdateSelect: moment(sd).format("DD-MM-YYYY"),
        edateSelect: moment(ed).format("DD-MM-YYYY"),
      };

      const resp = await http.get(`report/sales/summary`, { params: newData });
      const respPurchase = await http.get(`report/purchase/summary`, {
        params: newData,
      });
      let diffDay = ed.diff(sd, "days");

      setTotalDate({
        totalDays: diffDay + 1,
        totalWork: Object.keys(resp.data.data.amount).length,
      });

      let nums = [];
      for (let i = 0; i <= diffDay; i++) {
        let new_date = sd;

        const foundPurchase = respPurchase.data.data.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const found = resp.data.data.amount.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCash = resp.data.data.cash.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundTransfer = resp.data.data.transfer.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundFD = resp.data.data.fd.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
          new Date(moment(new_date)).getDay()
        ];

        let newData1 = {
          dateweekday: `${moment(new_date).format("DD-MM-YYYY")} #${weekday}`,
          date: moment(new_date).format("DD-MM-YYYY"),
          purchase: foundPurchase ? foundPurchase.amount : 0,
          amount: found ? found.amount : 0,
          cash: foundCash ? foundCash.amount : 0,
          transfer: foundTransfer ? foundTransfer.amount : 0,
          fd: foundFD ? foundFD.amount : 0,
          cost: found ? found.cost : 0,
          profit: found ? found.amount - found.cost : 0,
        };

        nums.push(newData1);
        new_date = sd.add(1, "days");
      }
      console.log(nums);
      setReportTable(nums);
    } catch (error) {
      console.log(error);
    }
  };

  const reportPreviewDetail = async (date) => {
    const newData = {
      branch_id: auth.branch.branch_id,
      reportDate: reportDate,
      sdateSelect: date,
    };

    setReportTableDetailSelect(date);
    const resp = await http.get(`report/sales/summary/detail`, {
      params: newData,
    });

    setReportTableDetail(resp.data.data);
    getPurchaseDetail(date);
  };

  const getPurchaseDetail = async (date) => {
    const newData = {
      branch_id: auth.branch.branch_id,
      date: date,
    };
    let dateNew = "";
    setTablePurchaseDetail(null);

    const resp = await http.get(`report/purchase/pidatedetailbydate`, {
      params: newData,
    });

    setTablePurchaseDetail(resp.data.data);
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
        <div className="col fw-bold">
          <div className="card radius-10 bg-info bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">วันทำงาน</p>
                  <h4 className="my-1 text-white fw-bold">
                    {`${totolDate.totalWork}/${totolDate.totalDays}`}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col fw-bold">
          <div className="card radius-10 bg-warning bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เงินสด</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportTable
                      ? commaNumber(
                          reportTable.reduce((a, v) => (a = a + v.cash), 0)
                        )
                      : 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col fw-bold">
          <div className="card radius-10 bg-success bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เงินโอน</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportTable
                      ? commaNumber(
                          reportTable.reduce((a, v) => (a = a + v.transfer), 0)
                        )
                      : 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-primary bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">จำนวนเงินรวม</p>
                  <h4 className="my-1 text-white fw-bold">
                    {reportTable
                      ? commaNumber(
                          reportTable.reduce((a, v) => (a = a + v.amount), 0)
                        )
                      : 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {auth && auth.newAuth.user.role === "admin" && (
        <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
          <div className="col fw-bold">
            <div className="card radius-10 bg-info bg-gradient">
              <div className="card-body">
                <div className="text-end">
                  <div>
                    <p className="mb-0 text-white font-18">FD</p>
                    <h4 className="my-1 text-white fw-bold">
                      {reportTable
                        ? commaNumber(
                            reportTable.reduce((a, v) => (a = a + v.fd), 0)
                          )
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col fw-bold">
            <div className="card radius-10 bg-warning bg-gradient">
              <div className="card-body">
                <div className="text-end">
                  <div>
                    <p className="mb-0 text-white font-18">หน้าร้าน</p>
                    <h4 className="my-1 text-white fw-bold">
                      {reportTable
                        ? commaNumber(
                            reportTable.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            ) - reportTable.reduce((a, v) => (a = a + v.fd), 0)
                          )
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col fw-bold">
            <div className="card radius-10 bg-success bg-gradient">
              <div className="card-body">
                <div className="text-end">
                  <div>
                    <p className="mb-0 text-white font-18">ต้นทุน</p>
                    <h4 className="my-1 text-white fw-bold">
                      {reportTable
                        ? commaNumber(
                            reportTable.reduce((a, v) => (a = a + v.cost), 0)
                          )
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col fw-bold">
            <div className="card radius-10 bg-primary bg-gradient">
              <div className="card-body">
                <div className="text-end">
                  <div>
                    <p className="mb-0 text-white font-18">กำไรสุทธิ</p>
                    <h4 className="my-1 text-white fw-bold">
                      {reportTable
                        ? commaNumber(
                            reportTable.reduce((a, v) => (a = a + v.profit), 0)
                          )
                        : 0}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header mt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 col-lg-2">
                <div className="input-group">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setReportDate(e.target.value)}
                  >
                    <option value="D">วัน</option>
                    <option value="M">เดือน</option>
                    <option value="Y">ปี</option>
                  </Form.Select>
                </div>
              </div>
              <div className="col-md-6 col-lg-8">
                <DateRangePicker
                  initialSettings={{
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                    startDate: sdate,
                    endDate: edate,
                  }}
                  //    onEvent={(event, picker) => console.log(picker.startDate)}
                  onCallback={(start, end, label) =>
                    getDateSelect(start, end, label)
                  }
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>

              <div className="col-md-4 col-lg-2 text-center">
                <button
                  type="button"
                  className="btn btn-success"
                  id="reportPreview"
                  onClick={() => reportPreview()}
                >
                  ค้นหา
                </button>
                <button type="button" className="btn btn-primary ms-2">
                  นำออก
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  className="table table-striped table-hover"
                  style={{ userSelect: "none" }}
                >
                  <thead>
                    <tr>
                      <th>วันที่</th>
                      <th className="text-center">สด</th>
                      <th className="text-center">โอน</th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">หน้าร้าน</th>
                          <th className="text-center">FD</th>
                        </>
                      )}

                      <th className="text-center bg-success text-white">
                        รายรับ
                      </th>
                      <th className="text-center bg-secondary text-white">
                        รายจ่าย
                      </th>
                      <th className="text-center bg-primary text-white">
                        สุทธิ
                      </th>

                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">ต้นทุน</th>
                          <th className="text-center">กำไร</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {reportTable &&
                      reportTable.map((data, index) => (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => reportPreviewDetail(data.date)}
                        >
                          <td>{`${index + 1}) ${data.dateweekday}`}</td>

                          <td className="text-center">
                            {commaNumber(data.cash)}
                          </td>
                          <td className="text-center">
                            {commaNumber(data.transfer)}
                          </td>
                          {auth && auth.newAuth.user.role === "admin" && (
                            <>
                              <td className="text-center">
                                {commaNumber(data.amount - data.fd)}
                              </td>
                              <td className="text-center">
                                {commaNumber(data.fd)}
                              </td>
                            </>
                          )}

                          <td className="text-center bg-success text-white">
                            {commaNumber(data.amount)}
                          </td>
                          <td className="bg-secondary text-white text-center">
                            {commaNumber(data.purchase)}
                          </td>
                          <td
                            className={
                              data.amount - data.purchase >= 0
                                ? "text-center bg-primary text-white"
                                : "text-center bg-danger text-white"
                            }
                          >
                            {commaNumber(data.amount - data.purchase)}
                          </td>

                          {auth && auth.newAuth.user.role === "admin" && (
                            <>
                              <td className="text-center">
                                {commaNumber(data.cost)}
                              </td>
                              <td className="text-center">
                                {commaNumber(data.profit)}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th rowSpan="2" className="align-middle">
                        รวม
                      </th>
                      <th className="text-center bg-light">สด</th>
                      <th className="text-center bg-light">โอน</th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">หน้าร้าน</th>
                          <th className="text-center">FD</th>
                        </>
                      )}
                      <th className="text-center bg-success text-white">
                        รายรับ
                      </th>
                      <th className="text-center bg-secondary text-white">
                        รายจ่าย
                      </th>
                      <th className="text-center bg-primary text-white">
                        สุทธิ
                      </th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">ต้นทุน</th>
                          <th className="text-center">กำไร</th>
                        </>
                      )}
                    </tr>
                    <tr>
                      <th className="text-center">
                        {reportTable &&
                          commaNumber(
                            reportTable.reduce((a, v) => (a = a + v.cash), 0)
                          )}
                      </th>
                      <th className="text-center">
                        {reportTable &&
                          commaNumber(
                            reportTable.reduce(
                              (a, v) => (a = a + v.transfer),
                              0
                            )
                          )}
                      </th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">
                            {reportTable &&
                              commaNumber(
                                reportTable.reduce(
                                  (a, v) => (a = a + v.amount),
                                  0
                                ) -
                                  reportTable.reduce(
                                    (a, v) => (a = a + v.fd),
                                    0
                                  )
                              )}
                          </th>
                          <th className="text-center">
                            {reportTable &&
                              commaNumber(
                                reportTable.reduce((a, v) => (a = a + v.fd), 0)
                              )}
                          </th>
                        </>
                      )}

                      <th className="text-center bg-success text-white">
                        {reportTable &&
                          commaNumber(
                            reportTable.reduce((a, v) => (a = a + v.amount), 0)
                          )}
                      </th>
                      <th className="text-center bg-secondary text-white">
                        {reportTable &&
                          commaNumber(
                            reportTable.reduce(
                              (a, v) => (a = a + v.purchase),
                              0
                            )
                          )}
                      </th>
                      <th
                        className={
                          reportTable
                            ? reportTable.reduce(
                                (a, v) => (a = a + (v.amount - v.purchase)),
                                0
                              ) >= 0
                              ? "text-center bg-primary text-white"
                              : "text-center bg-danger text-white"
                            : "text-center bg-primary text-white"
                        }
                      >
                        {reportTable &&
                          commaNumber(
                            reportTable.reduce(
                              (a, v) => (a = a + (v.amount - v.purchase)),
                              0
                            )
                          )}
                      </th>

                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">
                            {reportTable &&
                              commaNumber(
                                reportTable.reduce(
                                  (a, v) => (a = a + v.cost),
                                  0
                                )
                              )}
                          </th>
                          <th className="text-center">
                            {reportTable &&
                              commaNumber(
                                reportTable.reduce(
                                  (a, v) => (a = a + v.profit),
                                  0
                                )
                              )}
                          </th>
                        </>
                      )}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>รายงาน #{reportTableDetailSelect}</th>
                      <th className="text-center">จำนวน</th>
                      <th className="text-center">รายรับ</th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">ต้นทุน</th>
                          <th className="text-center">กำไร</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {reportTableDetail &&
                      reportTableDetail.map((data, index) => (
                        <tr key={index}>
                          <td>{data._id.item_name}</td>
                          <td className="text-center">{`${data.unitQty} x ${data._id.unitPrice}`}</td>
                          <td className="text-center">
                            {commaNumber(data.unitPriceTotal)}
                          </td>

                          {auth && auth.newAuth.user.role === "admin" && (
                            <>
                              <td className="text-center">
                                {commaNumber(data.unitQty * data._id.unitCost)}
                              </td>
                              <td className="text-center">
                                {commaNumber(
                                  data.unitPriceTotal -
                                    data.unitQty * data._id.unitCost
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>จำนวนเงินรวม</th>
                      <th></th>
                      <th className="text-center">
                        {reportTableDetail &&
                          commaNumber(
                            reportTableDetail.reduce(
                              (a, v) => (a = a + v.unitPriceTotal),
                              0
                            )
                          )}
                      </th>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <>
                          <th className="text-center">
                            {reportTableDetail &&
                              commaNumber(
                                reportTableDetail.reduce(
                                  (a, v) =>
                                    (a = a + v.unitQty * v._id.unitCost),
                                  0
                                )
                              )}
                          </th>
                          <th className="text-center">
                            {reportTableDetail &&
                              commaNumber(
                                reportTableDetail.reduce(
                                  (a, v) =>
                                    (a =
                                      a +
                                      (v.unitPriceTotal -
                                        v.unitQty * v._id.unitCost)),
                                  0
                                )
                              )}
                          </th>
                        </>
                      )}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>รายงาน #{reportTableDetailSelect}</th>
                      <th className="text-center">จำนวน</th>
                      <th className="text-center">รายรับ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tablePurchaseDetail &&
                      tablePurchaseDetail.map((data, index) => (
                        <tr key={index}>
                          <td>{data._id.item_name}</td>
                          <td className="text-center">{`${
                            data.unitQty
                          } x ${commaNumber(data._id.unitPrice)}`}</td>
                          <td className="text-center">
                            {commaNumber(data.unitAmount)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>จำนวนเงินรวม</th>
                      <th></th>
                      <th className="text-center">
                        {tablePurchaseDetail &&
                          commaNumber(
                            tablePurchaseDetail.reduce(
                              (a, v) => (a = a + v.unitAmount),
                              0
                            )
                          )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default SalesReport;
