import Axios from "axios";
import { store } from "../../redux/configureStore";

const Http = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    // "Content-type": "multipart/form-data",
    Accept: "application/json",
  },
});

Http.interceptors.request.use((config) => {
  try {
    const state = store.getState();
    const token = state.AuthReducer.auth.newAuth.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  } catch (error) {
    console.log(error);
  }
  return config;
});

export default Http;
