export const GET_COMPANY = "GET_COMPANY";

export const updateAuthCompany = (company) => {
  return {
    type: GET_COMPANY,
    payload: {
      company: company,
    },
  };
};
