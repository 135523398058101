import React, { useEffect, useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import { trackPromise } from "react-promise-tracker";
import http from "../../../services/backend/http";
import LoadingIndicator from "../../../components/loading/LoadingIndicator";
import ProductGroupsAddModel from "./ProductGroupsAddModel";
import ProductGroupsEditModel from "./ProductGroupsEditModel";
import { useSelector } from "react-redux";

const ProductGroups = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchText, setSearchText] = useState("");

  const title = useIntl().formatMessage({ id: "menu.stocks.itemsGroup" });
  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const btnEdit = useIntl().formatMessage({ id: "btn.edit" });
  const btnDelete = useIntl().formatMessage({ id: "btn.delete" });
  const btnAdd = useIntl().formatMessage({ id: "btn.add" });
  const btnClear = useIntl().formatMessage({ id: "btn.clear" });
  const tbItem_group_name = useIntl().formatMessage({
    id: "tb.item_group_name",
  });

  useEffect(() => {
    getItemGroup();
  }, []);

  //---- call API Start ----//
  const getItemGroup = async () => {
    try {
      setTable(null);
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await trackPromise(
        http.get("item_group", { params: newData })
      );

      ///branch_id/${auth.branch.branch_id}
      setTable(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //---- API Search Start ----//
  const getItemGroupSearch = async () => {
    try {
      setTable(null);
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await trackPromise(
        http.get(`item_group/search/${searchText}`, { params: newData })
      );

      setTable(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //---- call API End ----//

  //---- handleClearSearch Start ----//
  const handleClearSearch = () => {
    // if (searchText !== "") {
    setLoading(true);
    setTable(null);
    setSearchText("");
    getItemGroup();
    // }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText !== "") {
      getItemGroupSearch();
    } else {
      getItemGroup();
    }
  };

  //---- ToggleAddModal Start ----//
  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  ///---- Element Start-----------------------------------//
  const TableElement = () => {
    return (
      table &&
      table.map((item, index) => (
        <tr key={index} className="text-wrap">
          <td>{item.name}</td>
          <td style={{ textAlign: "right" }}>
            <a
              href="#"
              className="btn btn-sm btn-warning"
              onClick={() => toggleEditModal(item._id)}
            >
              {btnEdit}
            </a>
            &nbsp;
            <a
              href="#"
              className="btn btn-sm btn-danger"
              //   onClick={() => handleDeleteClick(item.id)}
            >
              {btnDelete}
            </a>
          </td>
        </tr>
      ))
    );
  };
  ///---- Element End-----------------------------------//

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${btnSearch} : ${tbItem_group_name}`}
                        // placeholder={`${btnSearch} : ${tbItem_id} ${tbItem_name}  ${tbItem_barcode}`}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="submit"
                          className="btn btn-sm btn-success me-2"
                          onClick={(e) => handleSearch(e)}
                        >
                          {btnSearch}
                        </button>
                        <a
                          type="button"
                          className="btn btn-sm btn-white me-2"
                          onClick={handleClearSearch}
                        >
                          {btnClear}
                        </a>
                        <a
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={toggleAddModal}
                        >
                          {btnAdd}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table mb-3 table-hover">
              <thead>
                <tr>
                  <th
                    className="cursor-pointer"
                    // onClick={() => applySorting("item_id", !sorting.ascending)}
                  >
                    {tbItem_group_name}
                    {/* <Sorticon sorting={sorting} id="item_id" /> */}
                  </th>
                  <th style={{ textAlign: "right" }}>{btnManage}</th>
                </tr>
              </thead>
              <tbody>
                <TableElement />
              </tbody>
            </table>
            {loading === true && <LoadingIndicator />}
          </div>
        </div>
      </div>

      <ProductGroupsAddModel
        addModal={addModal}
        toggleAddModal={toggleAddModal}
        getItemGroup={getItemGroup}
      />

      <ProductGroupsEditModel
        editModal={editModal}
        editData={editData}
        toggleEditModal={toggleEditModal}
        getItemGroup={getItemGroup}
      />
    </BackendLayout>
  );
};

export default ProductGroups;
