const Sorticon = (props) => {
  const { sorting,id } = props;
  return (
    <>
      {sorting.field === id ? (
        sorting.ascending === false ? (
          <span className="ms-2">
            <i className="bx bx-sort-up"></i>
          </span>
        ) : (
          <span className="ms-2">
            <i className="bx bx-sort-down"></i>
          </span>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default Sorticon;
