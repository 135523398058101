import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../../services/backend/http";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { userrole } from "../../../constants/default/default";

const UsersAddModel = (props) => {
  const { addModal, setAddModal, getUsers, tableBranch } = props;
  // const auth = useSelector((state) => state.AuthReducer.auth);

  const tbuser_head_username = useIntl().formatMessage({
    id: "tb.user_head_username",
  });

  const tbuser_head_name = useIntl().formatMessage({ id: "tb.user_head_name" });
  const tbuser_head_role = useIntl().formatMessage({ id: "tb.user_head_role" });
  const tbuser_head_status = useIntl().formatMessage({
    id: "tb.user_head_status",
  });

  const tbuser_password = useIntl().formatMessage({
    id: "tb.user_password",
  });
  const tbuser_password2 = useIntl().formatMessage({
    id: "tb.user_password2",
  });
  const tbuser_showpassword = useIntl().formatMessage({
    id: "tb.user_showpassword",
  });

  const tbuser_yup_username = useIntl().formatMessage({
    id: "tb.user_yup_username",
  });
  const tbuser_yup_name = useIntl().formatMessage({
    id: "tb.user_yup_name",
  });

  const tbuser_yup_role = useIntl().formatMessage({
    id: "tb.user_yup_role",
  });

  const tbuser_yup_password = useIntl().formatMessage({
    id: "tb.user_yup_password",
  });

  const tbuser_yup_passwordmin = useIntl().formatMessage({
    id: "tb.user_yup_passwordmin",
  });

  const tbuser_head_branch = useIntl().formatMessage({
    id: "tb.user_head_branch",
  });

  const tbuser_yup_branch = useIntl().formatMessage({
    id: "tb.user_yup_branch",
  });
  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      username: yup.string().required(tbuser_yup_username),
      name: yup.string().required(tbuser_yup_name),
      password: yup
        .string()
        .required(tbuser_yup_password)
        .min(6, tbuser_yup_passwordmin),
      password2: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      role: yup.string().required(tbuser_yup_role),
      branch: yup.string().when(["role"], {
        is: (role) => role === "user",
        then: yup.string().required(tbuser_yup_branch),
      }),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  //---- Handle Function Start -----------------//
  const handleAdd = async (data) => {
    try {
      const newData = {
        ...data,
        email: data.username,
        branch_id: data.role === "user" ? data.branch : "",
      };

      // console.log(newData);
      await http.post("user", newData);
      // console.log(resp.data.data);
      handleClickClose();
      getUsers();
      reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.error.message,
      });
    }
  };

  const handleClickClose = () => {
    reset();
    setAddModal(false);
  };

  const toggleShowPassword = (event) => {
    var x = document.getElementById("password");
    var y = document.getElementById("password2");
    if (event.target.checked === true) {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }
  };

  return (
    <Modal show={addModal} onHide={() => setAddModal(false)} backdrop="static">
      <form onSubmit={handleSubmit(handleAdd)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              <FormattedMessage id="modal.add" />
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="username">
                  {tbuser_head_username}
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  placeholder={tbuser_head_username}
                  {...register("username")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.username && errors.username.message}
              </p>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="name">
                  {tbuser_head_name}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder={tbuser_head_name}
                  {...register("name")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.name && errors.name.message}
              </p>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="password">
                  {tbuser_password}
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder={tbuser_password}
                  {...register("password")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.password && errors.password.message}
              </p>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="password2">
                  {tbuser_password2}
                </label>
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  className="form-control"
                  placeholder={tbuser_password2}
                  {...register("password2")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.password2 && errors.password2.message}
              </p>
              <div className="form-outline  mb-2  d-flex">
                <Form.Check
                  type="switch"
                  className="me-1"
                  onChange={(event) => toggleShowPassword(event)}
                  defaultChecked={false}
                  //checked={topping.default}
                  // onChange={(event) =>
                  //   handleFormChangeTopping(indexs, index, event)
                  // }
                />
                <label className="form-label" htmlFor="description">
                  {tbuser_showpassword}
                </label>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="description">
                  {tbuser_head_role}
                </label>
                <select
                  className="form-control"
                  {...register("role")}
                  // onChange={(e) => setItemGroup(e.target.value)}
                  // value={itemGroup}
                >
                  <option value="">
                    <FormattedMessage id="btn.select" />
                  </option>
                  {userrole &&
                    userrole.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
                <div className="text-danger mt-1">
                  {errors.role && errors.role.message}
                </div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="description">
                  {tbuser_head_branch}
                </label>
                <select
                  className="form-control"
                  {...register("branch")}
                  // onChange={(e) => setItemGroup(e.target.value)}
                  // value={itemGroup}
                >
                  <option value="">
                    <FormattedMessage id="btn.select" />
                  </option>
                  {tableBranch &&
                    tableBranch.map((data, index) => (
                      <option key={index} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                </select>
                <div className="text-danger mt-1">
                  {errors.branch && errors.branch.message}
                </div>
              </div>
              <div className="form-outline  mb-2  d-flex">
                <Form.Check
                  type="switch"
                  className="me-1"
                  {...register("status")}
                  defaultChecked={true}
                  // onChange={(event) =>
                  //   handleFormChangeTopping(indexs, index, event)
                  // }
                />

                <label className="form-label" htmlFor="description">
                  {tbuser_head_status}
                </label>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClickClose}>
            <FormattedMessage id="btn.close" />
          </Button>
          <Button variant="primary" type="submit" name="submit">
            <FormattedMessage id="btn.save" />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UsersAddModel;
