import React, { useState, useEffect } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { FormattedMessage } from "react-intl";
import http from "../../../../services/backend/http";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useIntl } from "react-intl";

const GroupEditModal = (props) => {
  const {
    groupEditModal,
    setGroupEditModal,
    toggleGroupEditModal,
    editData,
    getItemGroup,
  } = props;

  const [id, setId] = useState("");

  const tbItem_group_name = useIntl().formatMessage({
    id: "tb.item_group_name",
  });

  const tbItem_yup_nameGroup = useIntl().formatMessage({
    id: "tb.item_yup_nameGroup",
  });

  useEffect(() => {
    setId("");
    if (editData !== undefined) {
      if (editData) {
        setId(editData.id);
        setValue("nameGroup", editData.name, { shouldDirty: true });
      }
    }
  }, [editData]);

  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      nameGroup: yup.string().required(tbItem_yup_nameGroup),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  //---- Handle Function Start -----------------//
  const handleEdit = async (data) => {
    try {
      const body = {
        name: data.nameGroup,
        description: "",
      };
      await http.put(`item_group/${id}`, body);
      await getItemGroup();
      reset();
      toggleGroupEditModal();
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickClose = () => {
    reset();
    toggleGroupEditModal();
  };

  return (
    <MDBModal
      staticBackdrop
      show={groupEditModal}
      setShow={setGroupEditModal}
      tabIndex="-1"
    >
      <MDBModalDialog scrollable>
        <form onSubmit={handleSubmit(handleEdit)}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <strong>{tbItem_group_name}</strong>
              </MDBModalTitle>
              <a
                className="btn-close"
                color="none"
                onClick={handleClickClose}
              ></a>
            </MDBModalHeader>

            <MDBModalBody>
              <div>
                <input
                  type="text"
                  id="nameGroup"
                  name="nameGroup"
                  className="form-control"
                  placeholder={tbItem_group_name}
                  {...register("nameGroup")}
                />
              </div>
              <p className="text-danger mt-1">
                {errors.nameGroup && errors.nameGroup.message}
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <a
                className="btn btn-sm btn-secondary"
                onClick={handleClickClose}
              >
                <FormattedMessage id="btn.close" />
              </a>
              <button
                className="btn btn-sm btn-primary"
                type="submit"
                name="submit"
              >
                <FormattedMessage id="btn.save" />
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </form>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default GroupEditModal;
