import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import Card from "react-bootstrap/Card";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import commaNumber from "comma-number";
import http from "../../../services/backend/http";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment/moment";
import { purchaseType } from "../../../constants/default/default";
import "react-bootstrap-typeahead/css/Typeahead.css";

const PurchaseAddModal = (props) => {
  const { addModal, toggleAddModal, handleSearch } = props;
  const btnDelete = useIntl().formatMessage({ id: "btn.delete" });
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [selected, setSelected] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [item, setItem] = useState([]);
  const [unit, setUnit] = useState([]);

  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      poType: yup.string().required("กรุณาเลือกประเภท"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //---- yub End ---------------------------//

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      //console.log(selected);
      handleAddItemLine(selected);
      setSelected([]);
    }
  }, [selected]);

  useEffect(() => {
    if (addModal === true) {
      getProducts();
      getUnits();
    }
  }, [addModal]);

  //---- API -----------------------Start
  const getProducts = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get("item/searchpurchase", { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setItem(resp.data.data);
    }
  };

  const getUnits = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get("item_unit", { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setUnit(resp.data.data);
    }
  };

  // ----API -----------------End

  //---- Handle Function Start -----------------//
  const handleAdd = async (data) => {
    const amount = inputFields.reduce((a, v) => (a = a + v.unitAmount), 0);
    if (amount > 0) {
      const newData = {
        amount: amount,
        date: moment(startDate).format("DD-MM-YYYY"),
        branch_id: auth.branch.branch_id,
        purchaseType: data.poType,
        item: inputFields,
      };
      try {
        await http.post("purchaseline", newData);
        handleClickClose();
        handleSearch();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClickClose = () => {
    reset();
    setStartDate(new Date());
    setInputFields([]);
    toggleAddModal();
    // getItemGroup();
  };

  const handleAddItemLine = async (data) => {
    const unit_id = data[0].unit;
    const findUnit = unit.find((obj) => {
      return obj._id === unit_id.toString();
    });
    let newfield = {
      //  no: inputFields.length + 1,
      item_id: data[0].item_id,
      item_name: data[0].label,
      unit: findUnit ? findUnit.name : "",
      unitQty: 1,
      unitPrice: 0,
      unitAmount: 0,
    };

    setInputFields([...inputFields, newfield]);
  };
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = Number(event.target.value);
    const qty = data[index]["unitQty"];
    const price = data[index]["unitPrice"];
    data[index]["unitAmount"] = Number(qty * price);
    setInputFields(data);
  };
  const handleRemoveFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <Modal
      show={addModal}
      onHide={handleClickClose}
      size="xl"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(handleAdd)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              <FormattedMessage id="modal.add" />
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col-xl-2 col-md-6 col-xs-12">
                  <label className="form-label" htmlFor="tdate">
                    <b>วันที่</b>
                  </label>

                  <DatePicker
                    name="tdate"
                    id="tdate"
                    className="form-control"
                    todayButton="Today"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <div className="col-xl-2 col-md-6 col-xs-12">
                  <label className="form-label" htmlFor="tdate">
                    <b>ประเภท</b>
                  </label>

                  <select
                    name="poType"
                    id="poType"
                    className="form-control"
                    {...register("poType")}
                    //  onChange={(e) => setItemUnit(e.target.value)}
                  >
                    <option value="">
                      <FormattedMessage id="btn.select" />
                    </option>
                    {purchaseType &&
                      purchaseType.map((data, index) => (
                        <option key={index} value={data._id}>
                          {data._name}
                        </option>
                      ))}
                  </select>
                  <p className="text-danger mt-1">
                    {errors.poType && errors.poType.message}
                  </p>
                </div>

                <div className="col-xl-8 col-md-12 col-xs-12">
                  <div>
                    <label className="form-label" htmlFor="item">
                      <b>รายการสินค้า</b>
                    </label>
                    <Typeahead
                      highlightOnlyResult={true}
                      minLength={0}
                      id="item"
                      onChange={setSelected}
                      options={item}
                      placeholder="ค้นหารายการสินค้า..."
                      selected={selected}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <table className="table mb-3 table-hover">
                  <thead>
                    <tr>
                      <th className="col-1">ลำดับ</th>
                      <th className="col-2">รายการสินค้า</th>
                      <th className="col-1 text-center">หน่วย</th>
                      <th className="col-3 text-center">จำนวน</th>
                      <th className="col-3 text-center">ราคา</th>
                      <th className="col-1 text-center">จำนวนเงินรวม</th>
                      <th className="col-1 text-center">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields &&
                      inputFields.map((item, index) => (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{item.item_name}</td>
                          <td className="text-center">{item.unit}</td>
                          <td>
                            <input
                              min={0}
                              id="unitQty"
                              name="unitQty"
                              type="number"
                              className="form-control text-center"
                              value={item.unitQty}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </td>
                          <td>
                            <input
                              min={0}
                              id="unitPrice"
                              name="unitPrice"
                              type="number"
                              className="form-control text-center"
                              value={item.unitPrice}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </td>
                          <td className="text-center">
                            {commaNumber(item.unitAmount)}
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveFields(index)}
                            >
                              {btnDelete}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-center">
                        {inputFields
                          ? commaNumber(
                              inputFields.reduce(
                                (a, v) => (a = a + v.unitAmount),
                                0
                              )
                            )
                          : 0}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-2"
            variant="secondary"
            onClick={handleClickClose}
          >
            <FormattedMessage id="btn.close" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            name="submit"
            disabled={auth && auth.newAuth.user.role === "user" ? true : false}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PurchaseAddModal;
