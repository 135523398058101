import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);

  if (auth===null) {
    return <Navigate to="/login" />;
  }

  // if (!localStorage.getItem("userlogin")) {
  //   return <Navigate to="/login" />;
  // }

  return <Outlet />;
};

export default PrivateRoute;
