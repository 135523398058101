export const GET_LANG = 'GET_LANG'

export const updateLang = (lang) => {
    return {
        type:GET_LANG,
        payload:{
            lang:lang
        }
    }

}