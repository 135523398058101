import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import http from "../../../services/backend/http";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";

const Resetpassword = () => {
    const auth = useSelector((state) => state.AuthReducer.auth);
  const [isLoading, setIsLoading] = useState(false);
  //---------yub Start -----------//
  const schema = yup
    .object({
      password: yup
        .string()
        .required("The password field is required")
        .min(6, "Should be 6 chars minimum"),
      password2: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---------yub End -----------//

    //---- Handle Function Start -----------------//
    const handleResetPassword = async (data) => {
       
        try {
          setIsLoading(true);
            const newData = {
              password:data.password,
            };
    
            var alert = document.getElementById("alert").classList;
    
            if (String(alert.value).includes("d-none")) {
            } else {
              alert.add("d-none");
            }
    
       
             const resp = await http.put(`/user/resetpassword/${auth.newAuth.user.id}`, newData);
             if (resp.data.message === "Success") {
              setValue("password", "", { shouldDirty: true });
              setValue("password2", "", { shouldDirty: true });
              if (String(alert.value).includes("d-none")) {
                alert.remove("d-none");
              }
            } else {
              console.log(resp.data);
            }
            setIsLoading(false);
        } catch (error) {
          console.log(error);
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: "Login infomation",
              text: error.response.data.error.message,
            });
          }
          setIsLoading(false);
        }
      };

  const toggleShowPassword = (event) => {
    var x = document.getElementById("password");
    var y = document.getElementById("password2");
    if (event.target.checked === true) {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }
  };


  return (
    <BackendLayout title={`Reset password | ${process.env.REACT_APP_NAME}`}>
    <div className="card-body p-5 text-center">
    <h4 className="mb-3">Reset Password</h4>
    <hr></hr>
    <form onSubmit={handleSubmit(handleResetPassword)}>
      <div className="form-outline mb-4 text-start">
        <label className="form-label" htmlFor="password">
          Password
        </label>
        <input
          type="password"
          id="password"
          className="form-control form-control"
          placeholder="Password"
          {...register("password")}
        />
        <p className="text-danger mt-1">
          {errors.password && errors.password.message}
        </p>
      </div>
      <div className="form-outline mb-4 text-start">
        <label className="form-label" htmlFor="password2">
          Password confirm
        </label>
        <input
          type="password"
          id="password2"
          className="form-control form-control"
          placeholder="Password confirm"
          {...register("password2")}
        />
        <p className="text-danger mt-1">
          {errors.password2 && errors.password2.message}
        </p>
      </div>
      <div className="form-outline  mb-2  d-flex">
        <Form.Check
          type="switch"
          className="me-1"
          onChange={(event) => toggleShowPassword(event)}
          defaultChecked={false}
        />
        <label className="form-label" htmlFor="description">
          Show password
        </label>
      </div>
      <div id="alert" className="alert alert-success d-none" role="alert">
          Reset your password success
        </div>


      {isLoading ? (
          <div className="spinner-border text-primary" role="status" />
        ) : (
          <button className="btn btn-primary btn-block" type="submit">
          Submit
        </button>
        )}


    </form>
  </div>
  </BackendLayout>
  );
};

export default Resetpassword;
