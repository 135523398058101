import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import SalesOrderModel from "./SalesOrderModel";
import SalesPaymentModel from "./SalesPaymentModel";
import SalesTransferModel from "./SalesTransferModel";
import SalesFDModel from "./SalesFDModel";
import commaNumber from "comma-number";
import http from "../../../services/backend/http";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";

import { useSelector } from "react-redux";
const SalesListModel = (props) => {
  const { sendEmit,socketRef } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const { table, saleListModal, setSaleListModal, socket } = props;
  const [saleOrderModal, setSaleOrderModal] = useState(false);
  const [salePaymentModal, setSalePaymentModal] = useState(false);
  const [saleTransferModal, setSaleTransferModal] = useState(false);
  const [saleFDModal, setSaleFDModal] = useState(false);
  const [saleTable, setSaleTable] = useState(null);
  const [saleTableNumber, setSaleTableNumber] = useState(0);
  const [saleTableNumberSelect, setSaleTableNumberSelect] = useState(null);
  const [saleTableSumCart, setSaleTableSumCart] = useState({
    total: 0,
    grandtotal: 0,
  });

  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });

  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const saleTableno = useIntl().formatMessage({ id: "sale.tableno" });
  const saleNo = useIntl().formatMessage({ id: "sale.no" });
  const saleAmt = useIntl().formatMessage({ id: "sale.amt" });
  const saleGrandamt = useIntl().formatMessage({ id: "sale.grandamt" });
  const saleList = useIntl().formatMessage({ id: "sale.list" });
  const saleListno = useIntl().formatMessage({ id: "sale.listno" });
  const saleListorder = useIntl().formatMessage({ id: "sale.listorder" });
  const [heightModalHeader, setHeightModalHeader] = useState(0);

  useEffect(() => {
    if (saleListModal === true) {
      let ModalHeader = document.getElementById("ModalHeader");
      setHeightModalHeader(ModalHeader.offsetHeight + 97);
      getSaleTable(table);
      setSaleTableNumber(0);
    } else {
      handleClear();
    }
  }, [saleListModal]);

  //---- Call API -----------------//
  const getSaleTable = async (table) => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`salesline/table/${table}`, {
        params: newData,
      });

      if (Object.keys(resp.data.data).length !== 0) {
        setSaleTable(resp.data.data);
        setSaleTableNumberSelect(resp.data.data[0].item);
        const total = {
          total: resp.data.data[0].amount,
          grandtotal: resp.data.data.reduce(
            (a, b) => a + parseInt(b.amount),
            0
          ),
        };
        setSaleTableSumCart(total);
      } else {
        setSaleTable(null);
        setSaleTableNumberSelect(null);
        setSaleTableSumCart({ total: 0, grandtotal: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClear = () => {
    setSaleTable(null);
    setSaleTableNumberSelect(null);
    setSaleTableNumber(0);
    const total = {
      total: 0,
      grandtotal: 0,
    };
    setSaleTableSumCart(total);
  };

  const handleTableNumberSelect = (index) => {
    setSaleTableNumberSelect(saleTable[index].item);
    setSaleTableNumber(index);
    const total = {
      total: saleTable[index].amount,
      grandtotal: saleTable.reduce((a, b) => a + parseInt(b.amount), 0),
    };
    setSaleTableSumCart(total);
  };

  const removeRecord = (id) => {
    Swal.fire({
      title: swalDelete,
      // text: "ยืนยันการลบรายการนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnConfrim,
      cancelButtonText: btnCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = {
          branch_id: auth.branch.branch_id,
        };

        http
          .delete(`salesline/${id}`, { params: newData })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "ลบรายการเรียบร้อย",
              timer: 1500,
            });

            getSaleTable(table);
          })
          .catch((error) => {
            if (error) {
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  text: error.message,
                  timer: 2500,
                });
              }
            }
          });
      }
    });

    // console.log(saleTable[index].item);
  };

  const order = () => {
    setSaleOrderModal(true);
  };

  const paymentToggle = () => {
    if (saleTableSumCart.grandtotal > 0) {
      setSalePaymentModal(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "ไม่พบรายการสั่งซื้อ",
      });
    }
  };

  const transferToggle = () => {
    if (saleTableSumCart.grandtotal > 0) {
      setSaleTransferModal(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "ไม่พบรายการสั่งซื้อ",
      });
    }
  };

  const FDToggle = () => {
    if (saleTableSumCart.grandtotal > 0) {
      setSaleFDModal(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "ไม่พบรายการสั่งซื้อ",
      });
    }
  };

  return (
    <>
      <Modal
        show={saleListModal}
        onHide={() => setSaleListModal(false)}
        backdrop="static"
        fullscreen
      >
        <Modal.Header id="ModalHeader" closeButton>
          <Modal.Title>
            <strong>{`${saleTableno} ${table}`}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title fw-bold">{btnManage}</div>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      height: `calc(100vh - ${heightModalHeader}px)`,
                      overflowX: "hidden",
                      overflowY: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="row h4 g-3 mt-0">
                      <div className="col-12 text-end mb-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg form-control"
                          onClick={() => order()}
                        >
                          <h2 className="text-white">สั่งอาหาร</h2>
                        </button>
                      </div>
                      <div className="col-md-4 col-3  text-start">สุทธิ</div>
                      <div className="col-md-8 col-9  text-end">
                        <h1 className="text-primary fw-bold">
                          {saleTableSumCart && saleTableSumCart.grandtotal}
                        </h1>
                      </div>

                      <div className="col-12 text-end">
                        <button
                          type="button"
                          className="btn btn-success btn-lg form-control"
                          //onClick={() => setSalePaymentModal(true)}
                          onClick={() => paymentToggle()}
                        >
                          <h2 className="text-white">ชำระเงินสด</h2>
                        </button>
                      </div>
                      <div className="col-12 text-end">
                        <button
                          type="button"
                          className="btn btn-info btn-lg form-control"
                          //onClick={() => setSalePaymentModal(true)}
                          onClick={() => transferToggle()}
                        >
                          <h2 className="text-white">ชำระเงินโอน</h2>
                        </button>
                      </div>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <div className="col-12 text-end">
                          <button
                            type="button"
                            className="btn btn-danger btn-lg form-control"
                            //onClick={() => setSalePaymentModal(true)}
                            onClick={() => FDToggle()}
                          >
                            <h2 className="text-white">ชำระเงิน FD</h2>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title fw-bold">{saleListno}</div>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      height: `calc(100vh - ${heightModalHeader}px)`,
                      overflowX: "hidden",
                      overflowY: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="table-responsive">
                      <table className="table table-striped table-hover fs-6">
                        <thead>
                          <tr>
                            <th>{saleNo}</th>
                            <th className="text-end">{saleAmt}</th>
                            <th className="text-end">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saleTable &&
                            saleTable.map((data, index) => (
                              <tr
                                key={index}
                                onClick={() => handleTableNumberSelect(index)}
                                style={{ cursor: "pointer" }}
                              >
                                <td className="text-startalign-middle align-middle">
                                  {/* <Form.Check
                                    type="checkbox"
                                    id="default"
                                    name="default"
                                    className="ms-2 me-2"
                                    // checked={data1.default}
                                  /> */}
                                  {index + 1}
                                </td>
                                <td className="text-end">
                                  {commaNumber(data.amount)}
                                </td>
                                <td className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeRecord(data._id)}
                                  >
                                    ลบรายการ
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>{saleGrandamt}</th>
                            <th className="text-end">
                              {saleTableSumCart && saleTableSumCart.grandtotal}
                            </th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title fw-bold">{`${saleListorder} ${saleNo} ${
                      saleTableNumber + 1
                    }`}</div>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      height: `calc(100vh - ${heightModalHeader}px)`,
                      overflowX: "hidden",
                      overflowY: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="table-responsive">
                      <table className="table table-striped table-hover fs-6">
                        <thead>
                          <tr>
                            <th>{saleList}</th>
                            <th className="text-end">{saleAmt}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saleTableNumberSelect &&
                            saleTableNumberSelect.map((data, index) => (
                              <tr key={index}>
                                <td className="text-start align-middle">
                                  {data.item_name}
                                </td>
                                <td className="text-end">
                                  {`${commaNumber(data.unitPrice)} x ${
                                    data.unitQty
                                  } = ${commaNumber(data.unitPriceTotal)}`}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>{saleGrandamt}</th>
                            <th className="text-end">
                              {saleTableSumCart && saleTableSumCart.total}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SalesOrderModel
        table={table}
        saleOrderModal={saleOrderModal}
        setSaleOrderModal={setSaleOrderModal}
        getSaleTable={getSaleTable}
      />
      <SalesPaymentModel
        sendEmit={sendEmit}
        setSaleListModal={setSaleListModal}
        salePaymentModal={salePaymentModal}
        setSalePaymentModal={setSalePaymentModal}
        saleTableSumCart={saleTableSumCart}
        setSaleTableSumCart={setSaleTableSumCart}
        table={table}
      />

      <SalesTransferModel
        sendEmit={sendEmit}
        setSaleListModal={setSaleListModal}
        saleTransferModal={saleTransferModal}
        setSaleTransferModal={setSaleTransferModal}
        saleTableSumCart={saleTableSumCart}
        setSaleTableSumCart={setSaleTableSumCart}
        saleTable={saleTable}
        table={table}
        socketRef={socketRef}
      />

      <SalesFDModel
        sendEmit={sendEmit}
        setSaleListModal={setSaleListModal}
        saleTransferModal={saleFDModal}
        setSaleTransferModal={setSaleFDModal}
        saleTableSumCart={saleTableSumCart}
        setSaleTableSumCart={setSaleTableSumCart}
        table={table}
      />
    </>
  );
};

export default SalesListModel;
