/* eslint-disable import/no-anonymous-default-export */
  /***************  Function Start *********************/
  const activeMenu = (location) => {
    let elementMenu = document.getElementById("menu");
    let elementA = elementMenu.getElementsByTagName("a");

    elementA.forEach((data, index) => {
      if (data.getAttribute("href") === location) {
        data.parentNode.setAttribute("class", "mm-active");
        if (String(data.parentNode.parentNode.getAttribute("class")).includes("mm-collapse")) 
        {
          data.parentNode.parentNode.setAttribute("class","mm-collapse mm-show");
          data.parentNode.parentNode.parentNode.setAttribute("class","mm-active");
        }
      }
    });

  };

  /***************  Function End *********************/

   /***************  Handle Start *********************/
   const toggleMenu = () => {
    let elementWrapper = document.getElementById("wrapper").classList;
    if (String(elementWrapper.value).includes("toggled")) {
      elementWrapper.remove("toggled");
    } else {
      elementWrapper.add("toggled");
    }
  };

  const toggleMenuHover = () => {
    let elementWrapper = document.getElementById("wrapper").classList;
    if (String(elementWrapper.value).includes("toggled")) {
      elementWrapper.add("sidebar-hovered");
    }
  };
  const toggleMenuLeave = () => {
    let elementWrapper = document.getElementById("wrapper").classList;
    if (String(elementWrapper.value).includes("sidebar-hovered")) {
      elementWrapper.remove("sidebar-hovered");
    }
  };

  const toggleMenuDropDown = (event) => {
    let elementCurrent = event.currentTarget;
    let elementUL = elementCurrent.getElementsByTagName("ul")[0];
    let elementA = elementCurrent.getElementsByTagName("a")[0];
    let isMenuOpen = String(elementCurrent.classList.value);

    /*********** Close all ***************/
    let elementMenu = document.getElementById("menu");
    let elementMenuLi = elementMenu.getElementsByTagName("li");
    elementMenuLi.forEach((data, index) => {
      if (data.getAttribute("id") === "menutitle") {
        data.setAttribute("class", "");
      }
    });
    let elementMenuA = elementMenu.getElementsByTagName("a");
    elementMenuA.forEach((data, index) => {
      if (data.getAttribute("id") === "hasArrow") {
        data.setAttribute("aria-expanded", "false");
      }
    });

    let elementMenuUl = elementMenu.getElementsByTagName("ul");
    elementMenuUl.forEach((data, index) => {
      data.classList.remove("mm-show");
    });
    /*********** Close all ***************/

    if (String(elementUL.classList.value).includes("mm-collapse")) {
      elementCurrent.className = "mm-active";
      elementA.setAttribute("aria-expanded", "true");
      elementUL.setAttribute("class", "mm-collapse mm-show");
    }

    if (isMenuOpen === "mm-active") {
      elementCurrent.className = "";
      elementA.setAttribute("aria-expanded", "false");
      elementUL.setAttribute("class", "mm-collapse");
    }
  };
  /***************  Handle End *********************/

  export default {
    activeMenu,
    toggleMenu,
    toggleMenuHover,
    toggleMenuLeave,
    toggleMenuDropDown
  }