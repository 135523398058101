import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import QRCode from "qrcode.react";
import http from "../../../services/backend/http";
import SalesSlip from "./SalesSlip";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
//import socketIOClient from "socket.io-client";
const generatePayload = require("promptpay-qr");
const SalesFDModel = (props) => {
  const {
    saleTransferModal,
    setSaleTransferModal,
    saleTableSumCart,
    setSaleListModal,
    setSaleTableSumCart,
    table,
    sendEmit,
  } = props;
  // const socket = socketIOClient(process.env.REACT_APP_BASE_URL_IO);
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [salesSlip, setSalesSlip] = useState(false);
  const [doc, setDoc] = useState("");
  const [change, setChange] = useState(0);

  const [ppIDDetail, setppIDDetail] = useState("");
  const [ppIDDetail1, setppIDDetail1] = useState("");
  const [isLineNoti, setIsLineNoti] = useState(false);
  const [qrCode, setqrCode] = useState("");
  const [isLoading, setIsLoading] = useState({
    payment: false,
  });
  useEffect(() => {
    if (saleTransferModal === true) {
      getPPID(saleTableSumCart.grandtotal);
      getSetting();
    } else {
      setppIDDetail("");
      setppIDDetail1("");
      setqrCode("");
    }
  }, [saleTransferModal]);
  const getSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/settings`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      setIsLineNoti(resp.data.data[0].line_notify_type);
    }
  };
  const getPPID = async (amount) => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`setting/settings`, { params: newData });
      if (Object.keys(resp.data.data).length > 0) {
        // setppID(resp.data.data[0].ppid);

        const ppid = resp.data.data[0].ppid;

        if (ppid) {
          const pp = ppid.filter((data) => data.default === true);
          if (pp) {
            const ppID = `${pp[0].ppid.substring(
              0,
              pp[0].ppid.length - 5
            )}xxxxx`;
            const ppName = `${pp[0].name.substring(
              0,
              pp[0].name.length - 5
            )}xxxxx`;
            setppIDDetail(ppID);
            setppIDDetail1(ppName);
            setqrCode(generatePayload(pp[0].ppid, { amount }));
          }
        }
        //
      }
    } catch (error) {
      console.log(error);
    }
  };

  const lineNotify = async () => {
    try {
      let msg = `เงินโอน => ${commaNumber(saleTableSumCart.grandtotal)}`;

      const data = {
        message: msg,
      };
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      await http.post("helper/linenotify", data, { params: newData });
    } catch (error) {
      console.log(error);
    }
  };

  const payment = async () => {
    try {
      let newIsLoading = { ...isLoading, payment: true };
      setIsLoading(newIsLoading);
      const newData = {
        branch_id: auth.branch.branch_id,
        //amount: total,
        payment: "FD",
      };

      setChange(0);
      const resp = await http.put(`salesline/table/${table}`, newData);

      setDoc(resp.data.data);
      newIsLoading = { ...isLoading, payment: false };
      setIsLoading(newIsLoading);
      setSalesSlip(true);
      setSaleTransferModal(false);
      sendEmit();
      if (isLineNoti === true) {
        lineNotify();
      }
      //  socket.emit("order-sent", orderSent);
      // setSaleListModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={saleTransferModal}
        onHide={() => setSaleTransferModal(false)}
        backdrop="static"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="ms-3">
              <strong className="text-danger">ชำระเงิน FOOD DELIVERY</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-0">
            <div className="col-12">
              <div className="paymenttotal fs-1 fw-bold d-flex align-items-center justify-content-end border p-3">
                <div>{commaNumber(saleTableSumCart.grandtotal)}</div>
              </div>
            </div>
          </div>
          {/* <div className="row g-0">
            <div className="col-12 h6 text-center pt-3">
              <div className="m-2">{ppIDDetail}</div>
              <div className="m-2">{ppIDDetail1}</div>
            </div>
          </div>
          <div className="row g-0">
            {qrCode !== "" && (
              <div className="col-12 text-center p-4">
                <QRCode value={qrCode} />
              </div>
            )}
          </div> */}
          <div className="row g-0">
            <div className="col-12">
              {isLoading && isLoading.payment ? (
                <div className="fw-bold d-flex align-items-center justify-content-center border p-4">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              ) : (
                <div
                  className="bg-danger text-white fw-bold d-flex align-items-center justify-content-center border p-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => payment()}
                >
                  <div className="fs-3">ตกลง</div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SalesSlip
        setSalesSlip={setSalesSlip}
        salesSlip={salesSlip}
        doc={doc}
        setSaleListModal={setSaleListModal}
        setChange={setChange}
        change={change}
      />
    </>
  );
};

export default SalesFDModel;
