import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { Oval } from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "30",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {/* <Circles type="ThreeDots" color="gray" height="50" width="50" /> */}
        <Oval
          height={60}
          width={60}
          color="gray"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#gray"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    )
  );
};

export default LoadingIndicator;
