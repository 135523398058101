import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import http from "../../../services/backend/http";
import SalesOrderItemDetailModel from "./SalesOrderItemDetailModel";
import commaNumber from "comma-number";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
const SalesOrderModel = (props) => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const { saleOrderModal, setSaleOrderModal, table, getSaleTable } = props;
  const [salesOrderItemDetailModel, setSalesOrderItemDetailModel] =
    useState(false);
  const [heightModalHeader, setHeightModalHeader] = useState(0);
  const [heightRowItemGroup, setHeightRowItemGroup] = useState(0);
  const [heightRowTotal, setHeightRowTotal] = useState(0);

  const [itemData, setItemData] = useState(null);
  const [tableItemGroup, setTableItemGroup] = useState(null);
  const [tableItem, setTableItem] = useState(null);
  const [findItemGroup, setFindItemGroup] = useState("");
  const [cartTable, setCartTable] = useState([]);
  const [sumCart, setSumCart] = useState(0);
  const [sumCost, setSumCost] = useState(0);
  const [addType, setAddType] = useState([]);

  const [isLoading, setIsLoading] = useState({
    addOrder: false,
  });

  const saleTableno = useIntl().formatMessage({ id: "sale.tableno" });

  useEffect(() => {
    getItemGroup();
  }, []);
  useEffect(() => {
    // console.log(findItemGroup);
    getItem();
  }, [findItemGroup]);
  useEffect(() => {
    calSumCart();
  }, [cartTable]);

  useEffect(() => {
    if (saleOrderModal === true) {
      let rowItemGroup = document.getElementById("rowItemGroup");
      setHeightRowItemGroup(rowItemGroup.offsetHeight);
      let ModalHeader = document.getElementById("ModalHeader");
      setHeightModalHeader(ModalHeader.offsetHeight + 35);
      let rowTotal = document.getElementById("rowTotal");
      setHeightRowTotal(rowTotal.offsetHeight);
    }

    if (saleOrderModal === false) {
      setCartTable([]);
      setSumCart(0);
    }
  }, [saleOrderModal]);

  /// Handle Function
  const calSumCart = () => {
    let sum = cartTable.reduce(function (prev, current) {
      return prev + +current.price.unitPriceTotal;
    }, 0);
    setSumCart(sum);

    let cost = cartTable.reduce(function (prev, current) {
      return prev + +(current.price.unitCost * current.price.unitQty);
    }, 0);

    setSumCost(cost);
  };

  const addCart = (data, event) => {
    let cartIcon = document.getElementById("cartIcon");
    // cartIcon.style.animation= 'shake 0.5s'
    cartIcon.style.cssText = "animation:shake 0.5s;";
    setTimeout(() => {
      cartIcon.style.cssText = "";
    }, 300);

    // const item = event.currentTarget.querySelector("img");
    // item.style.cssText = "animation:shake 0.5s;";
    // setTimeout(() => {
    //   item.style.cssText = "";
    // }, 300);
    setAddType(["add", 0]);
    setItemData(data);
    setSalesOrderItemDetailModel(true);
  };

  const addOrder = async () => {
    if (cartTable.length === 0) {
      return;
    }

    const newIsLoading = { ...isLoading, addOrder: true };
    setIsLoading(newIsLoading);

    var today = new Date();
    const ddStart = today.getDate().toString().padStart(2, "0");
    const mmStart = (today.getMonth() + 1).toString().padStart(2, "0");
    const yyStart = today.getFullYear();
    const date = `${ddStart}-${mmStart}-${yyStart}`;

    let item = [];

    cartTable.map((data, index) => {
      let tempdata = {
        item_id: data._id,
        group_id: data.group_id,
        sizing: data.sizing,
        toppinggroup: data.toppinggroup,
        item_name: data.name,
        unitPrice: data.price.unitPrice,
        unitQty: data.price.unitQty,
        unitPriceSize: data.price.unitPriceSize,
        unitPriceTopping: data.price.unitPriceTopping,
        unitPriceTotal: data.price.unitPriceTotal,
        unitCost: data.price.unitCost,
        unitCostSize: data.price.unitCostSize,
        unitCostTopping: data.price.unitCostTopping,
      };

      item[index] = tempdata;
    });

    const newData = {
      branch_id: auth.branch.branch_id,
      table_no: table.toString(),
      date: date,
      amount: sumCart,
      cost: sumCost,
      item: item,
    };

    // console.log(newData);

    try {
      await http.post("salesline", newData);
      const newIsLoading = { ...isLoading, addOrder: false };
      setIsLoading(newIsLoading);
      getSaleTable(table);
      setCartTable([]);
      setSaleOrderModal(false);
    } catch (error) {
      console.log(error);
    }

    setCartTable([]);
  };

  const editCart = (data, index) => {
    setAddType(["edit", index]);
    setItemData(data);
    setSalesOrderItemDetailModel(true);
  };

  const delCart = (i) => {
    let cartIcon = document.getElementById("cartIcon");
    // cartIcon.style.animation= 'shake 0.5s'
    cartIcon.style.cssText = "animation:shake 0.5s;";
    setTimeout(() => {
      cartIcon.style.cssText = "";
    }, 300);

    let data = [...cartTable];
    data.splice(i, 1);
    setCartTable(data);
  };

  //////  call api Start
  const getItemGroup = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };

      setTableItemGroup(null);
      const resp = await http.get(`item_group`, { params: newData });
      setTableItemGroup(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getItem = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const searchgroup = findItemGroup === "" ? "a" : findItemGroup;
      setTableItem(null);
      const resp = await http.get(`item/searchgroup/${searchgroup}/search/a/`, {
        params: newData,
      });
      setTableItem(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  /////  call api End

  return (
    <>
      <Modal
        show={saleOrderModal}
        onHide={() => setSaleOrderModal(false)}
        backdrop="static"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-row w-100 justify-content-between">
            <div className="ms-3">
              <strong>{`${saleTableno} ${table}`}</strong>
            </div>
            <div className="me-3">
              <span className="text-primary fw-bold h3 me-3">
                {commaNumber(sumCart)}
              </span>
              <img
                id="cartIcon"
                src={process.env.REACT_APP_ICON_BASKET}
                alt="cart"
                height={heightModalHeader - 70}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="container-fluid"
            style={{
              height: `calc(100vh - ${heightModalHeader}px)`,
            }}
          >
            <div className="row">
              <div className="col-md-8 col-lg-9">
                <div id="rowItemGroup" className="row">
                  <div className="card mb-1">
                    <ul className="nav nav-pills " role="tablist">
                      <li
                        className="nav-item fw-bold"
                        onClick={() => setFindItemGroup("")}
                      >
                        <a
                          className={
                            findItemGroup === ""
                              ? "nav-link active"
                              : "nav-link"
                          }
                          href="#"
                        >
                          <i className="fa fa-tags" /> ทั้งหมด
                        </a>
                      </li>
                      {tableItemGroup &&
                        tableItemGroup.map((data, index) => (
                          <li
                            className="nav-item fw-bold"
                            key={index}
                            onClick={() => setFindItemGroup(data._id)}
                          >
                            <a
                              className={
                                findItemGroup === data.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              href="#"
                            >
                              <i className="fa fa-tags " /> {data.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="flex-column m-0 p-0"
                  style={{
                    height: `calc(100vh - ${heightModalHeader}px - ${heightRowItemGroup}px  )`,
                    overflowX: "hidden",
                    overflowY: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="row g-2 mt-0">
                    {tableItem &&
                      tableItem.map((data, index) => (
                        <div
                          id="item_cart"
                          key={index}
                          className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2"
                          onClick={(event) => addCart(data, event)}
                        >
                          <figure className="card card-product">
                            <div className="img-wrap">
                              <img
                                src={data.photo_url}
                                crossOrigin="anonymous"
                                alt={data.name}
                              />
                              {/* <a className="btn-overlay" href="#">
                            <i className="fa fa-search-plus"></i>เพิ่มสินค้า
                          </a> */}
                            </div>
                            <figcaption className="info-wrap text-center fw-bold fs-5">
                              <a href="#" className="title noselect">
                                {data.name}
                              </a>
                            </figcaption>
                          </figure>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-3">
                <div className="card">
                  <div
                    id="rowMainTotal"
                    className="row ms-1"
                    style={{
                      height: `calc(100vh - ${heightModalHeader}px  - 2px )`,
                      overflow: "hidden",
                    }}
                  >
                    <div id="rowTotal" className="row h4 g-3 mt-0">
                      <div className="col-12 text-end">
                        <h3 className="text-primary fw-bold">
                          {commaNumber(sumCart)}
                        </h3>
                      </div>
                      <div className="col-12 text-center">
                        {isLoading && isLoading.addOrder ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary btn-lg form-control"
                            onClick={() => addOrder()}
                          >
                            <h2 className="text-white">ทำรายการ</h2>
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex-column"
                      style={{
                        height: `calc(100vh - ${heightModalHeader}px - ${heightRowTotal}px  )`,
                        width: "98%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-striped table-hover fs-6">
                              <tbody>
                                {cartTable &&
                                  cartTable.map((data, index) => (
                                    <tr key={index}>
                                      <td className="text-start align-middle fw-bold">
                                        {`${index + 1} ${data.name}`}
                                        <br />
                                        {data.sizing.map(
                                          (data1, index1) =>
                                            data1.default === true && (
                                              <span
                                                key={index1}
                                                className="fw-normal"
                                              >{` (${data1.name})`}</span>
                                            )
                                        )}
                                        <br />
                                        <div className="input-group">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            style={{ width: "55px" }}
                                            onClick={() => delCart(index)}
                                          >
                                            ลบ
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ width: "55px" }}
                                            onClick={() =>
                                              editCart(data, index)
                                            }
                                          >
                                            แก้ไข
                                          </button>
                                        </div>
                                      </td>
                                      <td className="text-end align-middle">
                                        <>
                                          <span>{data.price.unitPrice}</span>
                                          <br />
                                          <span className="text-decoration-underline">{`${data.price.unitQty}`}</span>
                                          <br />
                                          <span className="fw-bold">
                                            {data.price.unitPriceTotal}
                                          </span>
                                        </>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SalesOrderItemDetailModel
        salesOrderItemDetailModel={salesOrderItemDetailModel}
        setSalesOrderItemDetailModel={setSalesOrderItemDetailModel}
        itemData={itemData}
        cartTable={cartTable}
        setCartTable={setCartTable}
        calSumCart={calSumCart}
        addType={addType}
      />
    </>
  );
};

export default SalesOrderModel;
