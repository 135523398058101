/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import commaNumber from "comma-number";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { trackPromise } from "react-promise-tracker";
import { useIntl } from "react-intl";
import http from "../../../services/backend/http";
import LoadingIndicator from "../../../components/loading/LoadingIndicator";
import Swal from "sweetalert2";
import Paginate from "../../../components/paginate/Paginate";
import Sorticon from "../../../components/sorticon/Sorticon";
import ProductAddModal from "./ProductAddModal";
import ProductEditModal from "./ProductEditModal";
import { paginatePageDefault } from "../../../constants/default/default";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const Products = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [paginatePage, setPaginatePage] = useState(paginatePageDefault);
  const [tableItemGroup, setTableItemGroup] = useState(null);
  const [table, setTable] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sorting, setSorting] = useState({
    field: "item_id",
    ascending: false,
  });
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchGroup, setSearchGroup] = useState("a");
  const title = useIntl().formatMessage({ id: "menu.stocks.items" });
  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });
  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const btnEdit = useIntl().formatMessage({ id: "btn.edit" });
  const btnDelete = useIntl().formatMessage({ id: "btn.delete" });
  const btnAdd = useIntl().formatMessage({ id: "btn.add" });
  const btnClear = useIntl().formatMessage({ id: "btn.clear" });

  const tbItem_id = useIntl().formatMessage({ id: "tb.item_id" });
  const tbItem_barcode = useIntl().formatMessage({ id: "tb.item_barcode" });
  const tbItem_name = useIntl().formatMessage({ id: "tb.item_name" });

  const tbSorting = useIntl().formatMessage({ id: "tb.sorting" });

  const tbItem_unit_price = useIntl().formatMessage({
    id: "tb.item_unit_price",
  });
  const tbItem_head_image = useIntl().formatMessage({
    id: "tb.item_head_image",
  });

  const tbItem_unit_stock = useIntl().formatMessage({
    id: "tb.item_unit_stock",
  });
  const tbItem_group = useIntl().formatMessage({
    id: "tb.item_group",
  });

  // From //

  useEffect(() => {
    if (searchText !== "") {
      getProductsSearch();
    } else {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, paginatePage, sorting]);

  useEffect(() => {
    if (searchText !== "") {
      getProductsSearch();
    } else {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroup]);

  useEffect(() => {
    getItemGroup();
  }, []);

  ///---- Function Start------------------------------------//

  //---- getProducts Start ----//
  const getProducts = () => {
    const sortKey = sorting.field;
    const ascending = sorting.ascending === false ? "-1" : "1";
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    trackPromise(
      http
        .get(
          `item/searchgroup/${searchGroup}/paginate/${paginatePage}/page/${pageNumber}/sort/${sortKey}/${ascending}`,
          { params: newData }
        )
        .then((resp) => {
          //  console.log(resp.data.data);
          setTable(resp.data.data.docs);
          setTotal(resp.data.data.totalDocs);
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                text: error.message,
                timer: 2500,
              });
            }
          }
        })
    );
  };

  //---- getProductsSearch Start ----//
  const getProductsSearch = () => {
    const sortKey = sorting.field;
    const ascending = sorting.ascending === false ? "-1" : "1";
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    trackPromise(
      http
        .get(
          `item/search/${searchText}/searchgroup/${searchGroup}/paginate/${paginatePage}/page/${pageNumber}/sort/${sortKey}/${ascending}`,
          { params: newData }
        )
        .then((resp) => {
          setTable(resp.data.data.docs);
          setTotal(resp.data.data.totalDocs);
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                text: error.message,
                timer: 2500,
              });
            }
          }
        })
    );

    // setTable(null);
    // setTotal(0);
  };

  const getItemGroup = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      setTableItemGroup(null);
      const resp = await http.get("item_group", { params: newData });
      setTableItemGroup(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //---- PageChange Start ----//
  const handlePageChange = (pageNumber) => {
    setLoading(false);
    setPageNumber(pageNumber);
  };

  //---- PaginatePageChange Start ----//
  const handlePaginatePageChange = (value) => {
    setLoading(true);
    setPaginatePage(value);
    setPageNumber(1);
  };

  //---- Table Sorting Start ----//
  const applySorting = (key, ascending) => {
    setLoading(true);
    setPageNumber(1);
    setTable(null);
    setSorting({ field: key, ascending: ascending });
  };

  //---- ToggleAddModal Start ----//
  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  //---- ToggleEditModal Start ----//
  const toggleEditModal = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  //---- handleClearSearch Start ----//
  const handleClearSearch = () => {
    // if (searchText !== "") {
    setLoading(true);
    setTable(null);
    setSearchText("");
    setSearchGroup("a");
    getItemGroup();
    setPageNumber(1);
    setSorting({ field: "item_id", ascending: false });
    // }
  };

  //---- handleSearch Start ----//
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText !== "") {
      getProductsSearch();
    } else {
      getProducts();
    }
  };

  //---- ToggleAddModal Start ----//

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: swalDelete,
      // text: "ยืนยันการลบรายการนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnConfrim,
      cancelButtonText: btnCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = {
          branch_id: auth.branch.branch_id,
        };

        http
          .delete(`item/${id}`, { params: newData })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "ลบรายการเรียบร้อย",
              timer: 1500,
            });

            getProducts();
          })
          .catch((error) => {
            if (error) {
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  text: error.message,
                  timer: 2500,
                });
              }
            }
          });
      }
    });
  };

  ///---- Function End------------------------------------//

  ///---- Element Start-----------------------------------//

  const TableElement = () => {
    return (
      table &&
      table.map((product, index) => (
        <tr key={index} className="text-wrap">
          <td>{product.item_id}</td>
          <td>
            <img
              crossOrigin="anonymous"
              src={product.photo_url}
              alt=""
              className="user-img"
            />
          </td>
          <td>{product.name}</td>
          <td>{product.group_id.name}</td>
          {/* {product.group_id.name} */}
          <td>{product.barcode}</td>
          <td>{product.stock_onhand}</td>
          <td>{product.sorting}</td>
          <td style={{ textAlign: "right" }}>
            <button
              //   href="#"
              className="btn btn-sm btn-warning me-1"
              onClick={() => toggleEditModal(product.id)}
            >
              {btnEdit}
            </button>
            <button
              // href="#"
              className="btn btn-sm btn-danger"
              onClick={() => handleDeleteClick(product.id)}
              disabled={
                auth && auth.newAuth.user.role === "user" ? true : false
              }
            >
              {btnDelete}
            </button>
          </td>
        </tr>
      ))
    );
  };

  const PaginateElement = () => {
    return (
      table !== null && (
        <Paginate
          paginatePage={paginatePage}
          handlePaginatePageChange={handlePaginatePageChange}
          pageNumber={pageNumber}
          total={total}
          handlePageChange={handlePageChange}
        />
      )
    );
  };

  ///---- Element End-----------------------------------//

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
        <div className="col">
          <div className="card radius-10 bg-primary bg-gradient">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-white font-18">สินค้าทั้งหมด</p>
                  <h4 className="my-1 text-white">
                    {commaNumber(total)} รายการ
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 bg-success bg-gradient">
            <div className="card-body">
              <div className="d-flex align-items-center font-18">
                <div>
                  <p className="mb-0 text-white">มูลค่าสินค้า</p>
                  <h4 className="my-1 text-white">52,152 บาท</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 bg-warning bg-gradient">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-dark font-18">สินค้าใกล้หมด</p>
                  <h4 className="text-dark my-1">5 รายการ</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 bg-danger bg-gradient">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-white font-18">สินค้าหมด</p>
                  <h4 className="my-1 text-white">2 รายการ</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col-2">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setSearchGroup(e.target.value)}
                    >
                      <option value="a">{tbItem_group}</option>
                      {tableItemGroup &&
                        tableItemGroup.map((data, index) => (
                          <option key={index} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                  <div className="col-10 ">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${btnSearch} : ${tbItem_id} ${tbItem_name}  ${tbItem_barcode}`}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="submit"
                          className="btn btn-sm btn-success me-2"
                          onClick={(e) => handleSearch(e)}
                        >
                          {btnSearch}
                        </button>
                        <a
                          type="button"
                          className="btn btn-sm btn-white me-2"
                          onClick={handleClearSearch}
                        >
                          {btnClear}
                        </a>
                        <a
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={toggleAddModal}
                        >
                          {btnAdd}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table mb-3 table-hover">
              <thead>
                <tr>
                  <th
                    className="cursor-pointer"
                    onClick={() => applySorting("item_id", !sorting.ascending)}
                  >
                    {tbItem_id}
                    <Sorticon sorting={sorting} id="item_id" />
                  </th>
                  <th>{tbItem_head_image}</th>
                  <th
                    className="cursor-pointer"
                    onClick={() => applySorting("name", !sorting.ascending)}
                  >
                    {tbItem_name}
                    <Sorticon sorting={sorting} id="name" />
                  </th>
                  <th>{tbItem_group}</th>
                  <th>{tbItem_barcode}</th>
                  <th>{tbItem_unit_stock}</th>
                  <th
                    className="cursor-pointer"
                    onClick={() => applySorting("sorting", !sorting.ascending)}
                  >
                    {tbSorting}
                  </th>
                  <th style={{ textAlign: "right" }}>{btnManage}</th>
                </tr>
              </thead>
              <tbody>
                <TableElement />
              </tbody>
            </table>
            {loading === true && <LoadingIndicator />}
            <PaginateElement
              paginatePage={paginatePage}
              handlePaginatePageChange={handlePaginatePageChange}
              pageNumber={pageNumber}
              total={total}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <ProductAddModal
        addModal={addModal}
        setAddModal={setAddModal}
        toggleAddModal={toggleAddModal}
        setPageNumber={setPageNumber}
        setSorting={setSorting}
        getProducts={getProducts}
      />
      <ProductEditModal
        editModal={editModal}
        setEditModal={setEditModal}
        toggleEditModal={toggleEditModal}
        editData={editData}
        setSorting={setSorting}
        getProductsSearch={getProductsSearch}
        getProducts={getProducts}
        searchText={searchText}
        setPageNumber={setPageNumber}
      />
    </BackendLayout>
  );
};

export default Products;
