import React from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../../redux/actions/AuthAction";
const LoginBranchModal = (props) => {
  const { branchModal, setBranchModal, newAuth } = props;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const Login = (data) => {
    const branch = { branch_id: data._id, branch_name: data.name };
    const newAuthLogin = { branch, newAuth };

    setBranchModal(!branchModal);
    localStorage.setItem("userlogin", true);
    dispatch(updateAuth(newAuthLogin));
    navigate("/backend/salestable");
  };

  return (
    <Modal
      show={branchModal}
      onHide={() => setBranchModal(!branchModal)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">
          {newAuth && newAuth.user.company_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <tbody>
              <tr
                onDoubleClick={() => Login("0")}
                style={{ cursor: "pointer" }}
              >
                <td>0. แดชบอร์ด</td>
              </tr>
              {newAuth &&
                newAuth.user.company_branch.map((data, index) => (
                  <tr
                    key={index}
                    onDoubleClick={() => Login(data)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{`${index + 1}. ${data.name}`}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginBranchModal;
