module.exports = {
  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.Menu */
  "menu.sales": "ขายสินค้า",
  "menu.purchase": "ซื้อสินค้า",
  "menu.reports": "รายงาน",
  "menu.reports.dashboards": "แดชบอร์ด",
  "menu.reports.sales": "รายงานการขาย",
  "menu.reports.balance": "รายงานรายรับ-รายจ่าย",
  "menu.reports.purchase": "รายงานการซื้อ",
  "menu.reports.purchaseitem": "รายงานการซื้อตามสินค้า",
  "menu.reports.salesitem": "รายงานการขายตามสินค้า",

  "menu.reportsum": "รายงานสรุป",
  "menu.reportsum.month": "รายงานรายวัน",
  "menu.reportsum.year": "รายงานรายเดือนตามประเภท",
  "menu.reportsum.year.compare": "รายงานรายเดือนสรุป",

  "menu.dashboards": "แดชบอร์ด",
  "menu.dashboards01": "แดชบอร์ด01",
  "menu.dashboards02": "แดชบอร์ด02",
  "menu.users": "ผู้ใช้",
  "menu.users01": "users01",
  "menu.users02": "users02",

  "menu.setting": "ตั้งค่า",
  "menu.setting.table": "ตั้งค่าโต๊ะอาหาร",
  "menu.setting.printer": "ตั้งค่าเครื่องปริ้น",
  "menu.setting.document": "ตั้งค่าเอกสาร",
  "menu.setting.payment": "ตั้งค่าโอนเงิน",
  "menu.setting.linenoti": "ตั้งค่าแจ้งเตือนไลน์",

  "menu.stocks": "สต๊อก",
  "menu.stocks.items": "สินค้า",
  "menu.stocks.itemsGroup": "กลุ่มสินค้า",

  /* 03.Navbar */
  "navbar.profile": "ข้อมูลส่วนตัว",
  "navbar.resetpassword": "เปลี่ยนรหัสผ่าน",
  "navbar.logout": "ออกจากระบบ",

  /* 04 btn */
  "btn.add": "เพิ่ม",
  "btn.edit": "แก้ไข",
  "btn.delete": "ลบ",
  "btn.save": "บันทึก",
  "btn.close": "ปิด",
  "btn.search": "ค้นหา",
  "btn.clear": "ล้าง",
  "btn.cancel": "ยกเลิก",
  "btn.confrim": "ยืนยัน",
  "btn.select": "กรุณาเลือก",
  "btn.manage": "จัดการ",
  "btn.loadmaster_group": "โหลดข้อมูลมาตรฐานตามกลุ่มสินค้า",

  /* Sales  */
  "sale.no": "ครั้งที่",
  "sale.amt": "จำนวนเงิน",
  "sale.grandamt": "จำนวนเงินรวม",
  "sale.list": "รายการ",
  "sale.listno": "รายการที่สั้ง",
  "sale.listorder": "รายการอาหาร",

  "sale.table": "โต๊ะ",
  "sale.tableno": "โต๊ะ",
  "sale.disconut": "ลด",
  "sale.total": "รวม",
  "sale.grandtotal": "รวมสุทธิ",
  "sale.pay": "ชำระเงิน",
  "sale.order": "สั่งอาหาร",

  "sale.tabletotal": "โต๊ะทั้งหมด",
  "sale.tablefree": "โต๊ะว่าง",
  "sale.tableuse": "โต๊ะใช้งาน",
  "sale.tablecounter": "เคาน์เตอร์",

  /* Modal alert */
  "modal.add": "เพิ่มข้อมูล",
  "modal.edit": "แก้ไขข้อมูล",

  /* Sweet alert */
  "swal.delete": "ยืนยันการลบรายการนี้",

  "tb.item_type": "ประเภทสินค้า",
  "tb.item_type_value0": "ขาย",
  "tb.item_type_value1": "ซื้อ",
  "tb.item_type_value2": "ขาย/ซื้อ",
  "tb.item_type_value3": "FD",

  /* label */
  "tb.sorting": "เรียงลำดับ",
  "tb.sorting_yup": "ป้อนเรียงลำดับ",
  "tb.item_head_item": "รายละเอียดสินค้า",
  "tb.item_head_price": "รายละเอียดราคา",
  "tb.item_head_stock": "รายละเอียดสต๊อก",
  "tb.item_head_topping": "รายละเอียดท๊อปปิ้ง",
  "tb.item_head_other": "รายละเอียดอื่น",
  "tb.item_head_image": "รูปภาพ",

  "tb.item_id": "รหัสสินค้า",
  "tb.item_name": "ชื่อสินค้า",
  "tb.item_description": "รายละเอียดสินค้า",
  "tb.item_barcode": "บาร์โค้ค",
  "tb.item_groupAdd": "(+เพิ่มกลุ่มสินค้า)",
  "tb.item_group": "กลุ่มสินค้า",
  "tb.item_group_name": "ชื่อกลุ่มสินค้า",
  "tb.item_price_unit": "ราคาตามขนาด",
  "tb.item_unit_price": "ราคา",
  "tb.item_unit_cost": "ต้นทุน",
  "tb.item_unit_size": "ขนาด",
  "tb.item_no": "ลำดับ",
  "tb.item_discount": "ส่วนลด",
  "tb.item_unit_stock": "จำนวนสต๊อก",
  "tb.item_unit_safetystock": "จำนวนสต๊อกขั้นต่ำ",
  "tb.item_unit": "หน่วยสินค้า",
  "tb.item_unitAdd": "(+เพิ่มหน่วยสินค้า)",

  "tb.item_switch_stock": "สต๊อคแบบไม่จำกัด (งานบริการ)",
  "tb.item_switch_topping": "ท๊อปปิ้ง",
  "tb.item_switch_vat": "ภาษีมูลค่าเพิ่ม",

  "tb.item_topping_Group": "กลุ่มท๊อปปิ้ง",
  "tb.item_topping_type1": "เลือกได้ 1 ตัวเลือก",
  "tb.item_topping_type2": "เลือกได้หลายตัวเลือก",

  "tb.item_yup_topping_Group": "ป้อนกลุ่มท๊อปปิ้ง",
  "tb.item_yup_topping_GroupType": "ป้อนประเภทกลุ่มท๊อปปิ้ง",

  "tb.item_yup_name": "ป้อนชื่อสินค้าก่อน",
  "tb.item_yup_description": "ป้อนรายละเอียดก่อน",
  "tb.item_yup_itemUnit": "ป้อนรายละเอียดหน่วยก่อน",
  "tb.item_yup_itemType": "ป้อนรายละเอียดประเภทสินค้าก่อน",
  "tb.item_yup_unit_cost": "ป้อนต้นทุนก่อน",
  "tb.item_yup_unit_cost_min": "ป้อนต้นทุนมากว่า 0",
  "tb.item_yup_unit_price": "ป้อนราคาก่อน",
  "tb.item_yup_unit_price_min": "ป้อนราคามากว่า 0",
  "tb.item_yup_nameGroup": "ป้อนชื่อกลุ่มสินค้าก่อน",

  //// Users
  "tb.user_head_username": "ชื่อผู้ใช้",
  "tb.user_head_name": "ชื่อ",
  "tb.user_head_role": "สิทธิ",
  "tb.user_head_status": "สถานะ",
  "tb.user_head_branch": "สาขา",

  "tb.user_password": "รหัสผ่าน",
  "tb.user_password2": "รหัสผ่านยืนยัน",
  "tb.user_showpassword": "แสดงรหัสผ่าน",

  "tb.user_yup_username": "ป้อนชื่อผู้ใช้ก่อน",
  "tb.user_yup_name": "ป้อนชื่อก่อน",
  "tb.user_yup_role": "ป้อนสิทธิก่อน",
  "tb.user_yup_password": "ป้อนรหัสผ่านก่อน",
  "tb.user_yup_password2": "ป้อนรหัสผ่านยืนยันก่อน",
  "tb.user_yup_passwordmin": "ป้อนรหัสผ่านอย่างน้อย 6 ตัวอักษร",
  "tb.user_yup_checkpassword": "รหัสผ่าน รหัสผ่านยืนยันไม่ถูกต้อง",
  "tb.user_yup_branch": "ป้อนสาขาก่อน",
};
