import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);

  if (auth !== null) {
    return <Navigate to="/backend/salestable" />;
  }

  // if (localStorage.getItem("userlogin")) {
  //   return <Navigate to="/backend/dashboard" />;
  // }

  return <Outlet />;
};

export default PublicRoute;
