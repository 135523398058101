// import { createStore } from 'redux'
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import { encryptTransform  } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './reducers/index'
 
const secretKey = process.env.REACT_APP_PERSIST_ENCRYPT


const persistConfig = {
  key: 'root',
  storage,
//  whitelist: ['AuthReducer'],
  transforms: [
    encryptTransform({
      secretKey: secretKey,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})
  
export const persistor = persistStore(store)