import React, { useState } from "react";
import BackendLayout from "../../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";

import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import http from "../../../../services/backend/http";
import commaNumber from "comma-number";

const SalesReportItem = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [tableSales, setTableSales] = useState(null);
  const [tableSalesDetail, setTableSalesDetail] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [sdate, setSdate] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edate, setEdate] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );

  const [sdateSelect, setSdateSelect] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edateSelect, setEdateSelect] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const title = useIntl().formatMessage({ id: "menu.reports.salesitem" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const btnClear = useIntl().formatMessage({ id: "btn.clear" });
  const getDateSelect = (start, end, label) => {
    const ddStart = start._d.getDate().toString().padStart(2, "0");
    const mmStart = (start._d.getMonth() + 1).toString().padStart(2, "0");
    const yyStart = start._d.getFullYear();
    const dateStart = `${ddStart}-${mmStart}-${yyStart}`;

    const ddEnd = end._d.getDate().toString().padStart(2, "0");
    const mmEnd = (end._d.getMonth() + 1).toString().padStart(2, "0");
    const yyEnd = end._d.getFullYear();
    const dateEnd = `${ddEnd}-${mmEnd}-${yyEnd}`;

    setSdate(start);
    setEdate(end);
    setSdateSelect(dateStart);
    setEdateSelect(dateEnd);
  };

  const handleClearSearch = async () => {
    setTableSales(null);
    setSearchText("");
  };
  const handleSearch = async () => {
    try {
      let sd = moment(sdateSelect, "DD-MM-YYYY");
      let ed = moment(edateSelect, "DD-MM-YYYY");

      const newData = {
        branch_id: auth.branch.branch_id,
        reportDate: "",
        sdateSelect: moment(sd).format("DD-MM-YYYY"),
        edateSelect: moment(ed).format("DD-MM-YYYY"),
        searchText: searchText,
      };

      const resp = await http.get(`report/sales/salesdateitem`, {
        params: newData,
      });

      setTableSales(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchDetail = async (id) => {
    try {
      let sd = moment(sdateSelect, "DD-MM-YYYY");
      let ed = moment(edateSelect, "DD-MM-YYYY");

      const newData = {
        branch_id: auth.branch.branch_id,
        reportDate: "",
        sdateSelect: moment(sd).format("DD-MM-YYYY"),
        edateSelect: moment(ed).format("DD-MM-YYYY"),
        id: id,
      };

      const resp = await http.get(`report/sales/salesdateitemdetail`, {
        params: newData,
      });
      setTableSalesDetail(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col-4">
                    <DateRangePicker
                      initialSettings={{
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                        startDate: sdate,
                        endDate: edate,
                      }}
                      onCallback={(start, end, label) =>
                        getDateSelect(start, end, label)
                      }
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </div>
                  <div className="col-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={btnSearch}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-success me-2"
                          onClick={() => handleSearch()}
                        >
                          {btnSearch}
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-white me-2"
                          onClick={() => handleClearSearch()}
                        >
                          {btnClear}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div className="table-responsive">
                <table
                  className="table table-striped mb-3 table-hover"
                  style={{ userSelect: "none" }}
                >
                  <thead>
                    <tr>
                      <th className="col-2">ลำดับ</th>
                      <th className="col-4">รายการ</th>
                      <th className="col-2 text-center">จำนวนเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableSales &&
                      tableSales.map((data, index) => (
                        <tr
                          key={index}
                          onClick={() => handleSearchDetail(data._id.item_id)}
                        >
                          <td>{index + 1}</td>
                          <td>{data._id.item_name}</td>
                          <td className="text-center">
                            {commaNumber(data.unitAmount)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th></th>
                      <th className="text-center">
                        {tableSales &&
                          commaNumber(
                            tableSales.reduce(
                              (a, v) => (a = a + v.unitAmount),
                              0
                            )
                          )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div className="table-responsive">
                <table className="table table-striped mb-3 table-hover">
                  <thead>
                    <tr>
                      <th className="col-2">ลำดับ</th>
                      <th className="col-4">รายการ</th>
                      <th className="col-2">ราคา</th>
                      <th className="col-2 text-center">จำนวน</th>
                      <th className="col-2 text-center">จำนวนเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableSalesDetail &&
                      tableSalesDetail.map((data, index) => (
                        <tr key={index}>
                          <td>{`${index + 1}`}</td>
                          <td>{data._id.item_name}</td>
                          <td>{commaNumber(data._id.unitPrice)}</td>
                          <td className="text-center">{`${data.unitQty}`}</td>
                          <td className="text-center">
                            {commaNumber(data.unitPriceTotal)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-center">
                        {tableSalesDetail
                          ? commaNumber(
                              tableSalesDetail.reduce(
                                (a, v) => (a = a + v.unitQty),
                                0
                              )
                            )
                          : 0}
                      </th>
                      <th className="text-center">
                        {tableSalesDetail
                          ? commaNumber(
                              tableSalesDetail.reduce(
                                (a, v) => (a = a + v.unitPriceTotal),
                                0
                              )
                            )
                          : 0}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default SalesReportItem;
