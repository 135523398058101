import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import http from "../../../services/backend/http";
import commaNumber from "comma-number";
import { useSelector } from "react-redux";
const SalesOrderItemDetailModel = (props) => {
  const {
    salesOrderItemDetailModel,
    setSalesOrderItemDetailModel,
    itemData,
    cartTable,
    setCartTable,
    addType,
  } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [id, setId] = useState("");
  const [itemSelect, setItemSelect] = useState(null);
  const [unitQty, setUnitQty] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [unitPriceSize, setUnitPriceSize] = useState(0);
  const [unitPriceTopping, setUnitPriceTopping] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const [unitCostSize, setUnitCostSize] = useState(0);
  const [unitCostTopping, setUnitCostTopping] = useState(0);
  const [sizing, setSizing] = useState([]);
  const [toppingGroup, setToppingGroup] = useState([]);
  const [itemType, setItemType] = useState("");
  const [unitPriceFB, setUnitPriceFB] = useState(0);
  const [isLoading, setIsLoading] = useState({
    addOrder: false,
  });
  useEffect(() => {
    if (salesOrderItemDetailModel === false) {
      clearScreen();
    }
    if (salesOrderItemDetailModel === true) {
      if (addType[0] == "add") {
        getDataForAdd(itemData._id);
      } else {
        getDataForEdit(itemData);
      }
    }
  }, [salesOrderItemDetailModel]);

  useEffect(() => {
    if (salesOrderItemDetailModel === true) {
      if (itemType === 3) {
        let item = [...sizing];
        item[0].unit_price = unitPriceFB * 1;
        item[0].unit_cost = unitPriceFB / 2;
        // console.log(item);
        setSizing(item);
        setUnitPriceSize(unitPriceFB * 1);
        setUnitPrice(unitPriceFB * 1);
        setUnitCostSize(unitPriceFB / 2);
        setUnitCost(unitPriceFB / 2);
      }
    }
  }, [unitPriceFB]);

  //---- Call API -----------------//
  const getDataForEdit = (data) => {
    setSizing(data.sizing);
    setToppingGroup(data.toppinggroup);
    setItemSelect(data);

    setUnitPrice(data.price.unitPrice);
    setUnitPriceSize(data.price.unitPriceSize);
    setUnitPriceTopping(data.price.unitPriceTopping);

    setUnitCost(data.price.unitCost);
    setUnitCostSize(data.price.unitCostSize);
    setUnitCostTopping(data.price.unitCostTopping);

    setUnitQty(data.price.unitQty);
    setItemType(data.item_type);
  };

  const getDataForAdd = async (id) => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      const resp = await http.get(`item/${id}`, { params: newData });

      let item = [...resp.data.data[0].sizing];
      item.map((i, index) => {
        if (index === 0) {
          i["default"] = true;
        }
      });
      setItemType(resp.data.data[0].item_type);
      setUnitPriceFB(0);

      setSizing(item);
      setToppingGroup(resp.data.data[0].toppinggroup);

      let itemSelect = resp.data.data[0];
      setItemSelect(itemSelect);

      let price = 0;
      let unitPriceVar = 0;
      let cost = 0;
      let costVar = 0;
      itemSelect.sizing.map((data) => {
        if (data.default === true) {
          price += data.unit_price;
          cost += data.unit_cost;
        }
      });

      unitPriceVar = price + unitPriceTopping;
      setUnitPriceSize(price);
      setUnitPrice(unitPriceVar);

      costVar = cost + unitCostTopping;
      setUnitCostSize(cost);
      setUnitCost(costVar);
    } catch (error) {
      console.log(error);
    }
  };

  //----Handle Start------//
  const clearScreen = () => {
    setSizing([]);
    setToppingGroup([]);
    setUnitPrice(0);
    setUnitPriceSize(0);
    setUnitPriceTopping(0);
    setUnitCost(0);
    setUnitCostSize(0);
    setUnitCostTopping(0);
    setUnitQty(1);
    setItemType("");
  };

  const calUnitPriceSize = (qty) => {
    let price = 0;
    let unitPriceVar = 0;
    let cost = 0;
    let costVar = 0;
    itemSelect.sizing.map((data) => {
      if (data.default === true) {
        price += data.unit_price;
        cost += data.unit_cost;
      }
    });

    unitPriceVar = price + unitPriceTopping;
    setUnitPriceSize(price);
    setUnitPrice(unitPriceVar * qty);

    costVar = cost + unitCostTopping;
    setUnitCostSize(cost);
    setUnitCost(costVar);
  };

  const calUnitPriceTopping = (qty) => {
    let price = 0;
    let unitPriceVar = 0;
    let cost = 0;
    let costVar = 0;
    itemSelect.toppinggroup.map((data) => {
      data.topping.map((data1) => {
        if (data1.default === true) {
          price += data1.unit_price;
          cost += data1.unit_cost;
        }
      });
    });

    unitPriceVar = price + unitPriceSize;
    setUnitPriceTopping(price);
    setUnitPrice(unitPriceVar * qty);

    costVar = cost + unitCostSize;
    setUnitCostTopping(cost);
    setUnitCost(costVar);
  };

  const addOrderDetail = () => {
    let newIsLoading = { ...isLoading, payment: true };
    setIsLoading(newIsLoading);

    const price = {
      unitPriceTotal: unitPrice,
      unitPriceSize: unitPriceSize,
      unitPriceTopping: unitPriceTopping,
      unitQty: unitQty,
      unitPrice: unitPriceSize + unitPriceTopping,
      unitCost: unitCost,
      unitCostSize: unitCostSize,
      unitCostTopping: unitCostTopping,
    };

    //  console.log(price);

    const item = { ...itemSelect, price };
    if (addType[0] == "add") {
      let cart = [...cartTable, item];
      setCartTable(cart);
    } else {
      let carts = [...cartTable];
      let cart = { ...carts[addType[1]] };
      cart = item;
      carts[addType[1]] = cart;
      setCartTable(carts);
    }

    newIsLoading = { ...isLoading, payment: false };
    setIsLoading(newIsLoading);
    setSalesOrderItemDetailModel(false);
  };

  const handleFormChangeSizing = (data, index, event) => {
    let item = [...sizing];

    item.map((i) => {
      i[event.target.name] = false;
    });

    item[index][event.target.name] = event.target.checked;
    setSizing(item);

    calUnitPriceSize(unitQty);
  };

  const handleFormChangeTopping = (data, i, data1, i1, event) => {
    let item = [...toppingGroup];

    item.map((items, index) => {
      if (index === i) {
        items.topping.map((subItems, index1) => {
          if (items.type === "toppingGroupType") {
            subItems[event.target.name] = false;
          }
          if (index1 === i1) {
            subItems[event.target.name] = event.target.checked;
          }
        });
      }
    });

    setToppingGroup(item);
    calUnitPriceTopping(unitQty);
  };

  const inceaseUnitQty = () => {
    let qty = unitQty + 1;
    setUnitQty(qty);
    calUnitPriceSize(qty);
    calUnitPriceTopping(qty);
  };

  const deceaseUnitQty = () => {
    if (unitQty > 1) {
      let qty = unitQty - 1;
      setUnitQty(qty);
      calUnitPriceSize(qty);
      calUnitPriceTopping(qty);
    }
  };

  return (
    itemData && (
      <Modal
        show={salesOrderItemDetailModel}
        onHide={() => setSalesOrderItemDetailModel(false)}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-row w-100 justify-content-between">
            <div className="ms-3">
              <strong>{itemSelect && itemSelect.name}</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="img-wrap">
                  <img
                    className="border-secondary"
                    style={{ borderRadius: "25px", border: "3px solid" }}
                    src={itemSelect ? itemSelect.photo_url : ""}
                    crossOrigin="anonymous"
                    alt={itemSelect && itemSelect.name}
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  <h3 className="text-primary fw-bold">
                    {commaNumber(unitPrice)}
                  </h3>
                </div>
              </div>
              <div className="row p-2 mt-4 g-2">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary form-control  fw-bold fs-4"
                    onClick={() => inceaseUnitQty()}
                  >
                    +
                  </button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <input
                    type="text"
                    className="form-control text-center fs-4"
                    value={unitQty}
                    disabled
                    onFocus={(e) => e.target.select()}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <button
                    type="button"
                    className="btn btn-danger form-control  fw-bold fs-4"
                    onClick={() => deceaseUnitQty()}
                  >
                    -
                  </button>
                </div>
              </div>

              <div className="row p-2 mt-5">
                {isLoading && isLoading.addOrder ? (
                  <div className="fw-bold d-flex align-items-center justify-content-center border p-4">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success btn-lg form-control"
                    onClick={() => addOrderDetail()}
                  >
                    <h2 className="text-white">สั่งอาหาร</h2>
                  </button>
                )}
              </div>
            </div>
            <div className="col-9 fs-6">
              {sizing && (
                <>
                  <div className="row me-1 mb-4">
                    <span className="fw-bold">ขนาด</span>
                    <div
                      className="d-flex justify-content-between rounded-3 border-secondary"
                      style={{ borderStyle: "dashed", borderWidth: "1px" }}
                    >
                      {sizing.map((data, index) => (
                        <div className="flex text-center" key={index}>
                          <div className="row">
                            <div className="col text-center">{data.name}</div>
                          </div>
                          <div className="row">
                            <div className="col text-center text-primary">
                              {data.unit_price}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col text-center">
                              <Form.Check
                                type="checkbox"
                                id="default"
                                name="default"
                                className="mt-2 ms-2 me-2"
                                checked={data.default}
                                onChange={(event) =>
                                  handleFormChangeSizing(data, index, event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {toppingGroup && (
                <>
                  <div className="row me-1 mb-4">
                    <span className="fw-bold">ท๊อปปิ้ง</span>
                    <div
                      className="rounded-3 border-secondary"
                      style={{ borderStyle: "dashed", borderWidth: "1px" }}
                    >
                      {toppingGroup.map((data, index) => (
                        <div key={index}>
                          <div className="row me-1 mb-2 mt-2">
                            <span className="fw-bold">{data.name}</span>
                          </div>
                          <div
                            className="rounded-3 border-secondary mt-2 mb-2 p-2"
                            style={{
                              borderStyle: "dashed",
                              borderWidth: "1px",
                            }}
                          >
                            <div className="justify-content-between">
                              <div className="d-flex text-center justify-content-between">
                                {data.topping.map((data1, index1) => (
                                  <div key={index1}>
                                    <div className="row">
                                      <div className="col text-center">
                                        {data1.name}
                                        {data1.unit_price > 0
                                          ? data1.unit_price
                                          : ""}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col text-center">
                                        <Form.Check
                                          type="checkbox"
                                          id="default"
                                          name="default"
                                          className="mt-2 ms-2 me-2"
                                          checked={data1.default}
                                          onChange={(event) =>
                                            handleFormChangeTopping(
                                              data,
                                              index,
                                              data1,
                                              index1,
                                              event
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {itemType === 3 && (
                <div className="row me-1 mb-4">
                  <span className="fw-bold">ราคา</span>
                  <div className="col-12">
                    <input
                      type="number"
                      className="form-control text-center fs-5"
                      value={unitPriceFB}
                      onChange={(e) => setUnitPriceFB(e.target.value)}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
};

export default SalesOrderItemDetailModel;
