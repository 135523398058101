import { useState } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import ImageMarker from "react-image-marker";
const LayoutTables = () => {
  const [imgURL, setImgURL] = useState("");
  const [markers, setMarkers] = useState([]);

  const title = useIntl().formatMessage({ id: "menu.setting.table" });
  const tbItem_head_image = useIntl().formatMessage({
    id: "tb.item_head_image",
  });

  const onFileImgChange = async (e) => {
    const file = e.target.files[0];
    const imgUrl = URL.createObjectURL(file);
    // const base64File = await getBase64(file);
    // setBase64URL(base64File);
    setImgURL(imgUrl);
    // setImgFile(file);
  };

  const CustomMarker = () => {
    return (
      <div
        className="image-marker__marker image-marker__marker--default"
        data-testid="marker"
      ></div>
    );
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <h5>{title}</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-2 text-end mt-2">{tbItem_head_image} : </div>
            <div className="col-8">
              <input
                type="file"
                id="file"
                name="file"
                className="form-control"
                accept="image/png, image/jpg, image/jpeg"
                //   {...register("file")}
                onChange={onFileImgChange}
              />
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row mt-5">
            <div className="col">
              {/* <img src={imgURL} alt="" className="img-fluid w-100" /> */}
              {imgURL && (
                <ImageMarker
                  src={imgURL}
                  markers={markers}
                  onAddMarker={(marker) =>
                    setMarkers((prev) => [...prev, marker])
                  }
                  markerComponent={() => CustomMarker()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default LayoutTables;
