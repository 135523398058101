import { useState, useEffect, useRef } from "react";
import http from "../../../services/backend/http";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import SalesListModel from "./SalesListModel";
import { useIntl } from "react-intl";
import commaNumber from "comma-number";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
//import { io } from "socket.io-client";
import socketIO from "socket.io-client";
//import { updateSetting } from "../../../redux/actions/SettingAction";
const SalesTable = (props) => {
  const { socketRef } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.setting.table" });
  const saleTable = useIntl().formatMessage({ id: "sale.table" });
  const saleTablecounter = useIntl().formatMessage({ id: "sale.tablecounter" });
  const saleTableno = useIntl().formatMessage({ id: "sale.tableno" });
  const saleTabletotal = useIntl().formatMessage({ id: "sale.tabletotal" });
  const saleTablefree = useIntl().formatMessage({ id: "sale.tablefree" });
  const saleTableuse = useIntl().formatMessage({ id: "sale.tableuse" });
  const [sumTable, setSumTable] = useState(null);
  const [table, setTable] = useState("");
  const [tableData, setTableData] = useState("");
  const [countTable, setCountTable] = useState(0);
  const [useTable, setUseTable] = useState(0);
  const [saleListModal, setSaleListModal] = useState(false);
  // const [tableColor, setTableColor] = useState("bg-danger");
  // const [tableColorArray, setTableColorArray] = useState(null);

  useEffect(() => {
    socketRef.current = socketIO.connect(process.env.REACT_APP_BASE_URL_IO);
    if (socketRef.current) {
      socketRef.current.removeAllListeners("transferdone");
    }
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (saleListModal === false) {
      getCountTable();
      //getSumTable();
    }
  }, [saleListModal]);

  const sendEmit = () => {
    const orderSent = {
      company_id: auth.newAuth.user.company_id,
      branch_id: auth.branch.branch_id,
    };
    socketRef.current.emit("order-sent", orderSent);
  };

  //////  call api Start
  const getCountTable = async () => {
    try {
      const newData = {
        branch_id: auth.branch.branch_id,
      };
      let table_number = 0;
      const resp = await http.get(`setting/settings`, { params: newData });
      // dispatch(updateSetting(resp.data.data[0]));

      if (Object.keys(resp.data.data).length > 0) {
        if (resp.data.data[0].table_type === true) {
          table_number = resp.data.data[0].table_number;
        }
      }

      setCountTable(table_number);

      const resp1 = await http.get(`salesline/table`, { params: newData });

      setSumTable(resp1.data.data);
      setUseTable(Object.keys(resp1.data.data).length);

      let nums = [];
      for (let i = 0; i <= table_number; i++) {
        const found = resp1.data.data.find((obj) => {
          return obj._id === i.toString();
        });

        let newData = {
          table: i,
          amount: found ? found.amount : 0,
          bg: found ? "bg-danger" : "bg-success",
        };
        nums.push(newData);
      }

      setTableData(nums);
    } catch (error) {
      console.log(error);
    }
  };

  //////  call api End

  //////  handle start

  const orderTable = (i) => {
    setTable(i);
    setSaleListModal(true);
  };
  //////  handle end

  const TableElement = () => {
    return (
      tableData &&
      tableData.map(
        (data, index) =>
          index !== 0 && (
            <div
              className="col-4 col-sm-4 col-md-3 col-lg-2"
              key={index}
              onClick={() => orderTable(data.table)}
            >
              <div
                className={`card noselect hover-shadow fw-bold text-white ${data.bg}`}
                style={{ cursor: "pointer", height: "120px" }}
              >
                <div className="card-header text-end">
                  {commaNumber(data.amount)}
                </div>
                <div className="card-body">
                  <div className="row h-100">
                    <div className="col align-self-center fw-bold text-center h2  text-white">
                      {`${saleTableno} ${data.table}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      )
    );
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="container-fluid">
        <div className="card noselect">
          <div className="card-header fw-bold">
            <span>{`${saleTabletotal} ${countTable} ${saleTable}`}</span>
            <br />
            <span className="text-success">{`${saleTablefree} ${
              countTable - useTable
            } ${saleTable}`}</span>
            <br />
            <span className="text-danger">{`${saleTableuse} ${useTable} ${saleTable}`}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12" onClick={() => orderTable(0)}>
            <div
              className="card hover-shadow fw-bold bg-warning text-white"
              style={{ cursor: "pointer", height: "120px" }}
            >
              <div className="card-header text-end">
                {tableData && tableData[0].amount}
              </div>
              <div className="card-body">
                <div className="row h-100">
                  <div className="col noselect align-self-center fw-bold text-center h2  text-white">
                    {saleTablecounter}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableElement />
        </div>
      </div>
      <SalesListModel
        sendEmit={sendEmit}
        table={table}
        saleListModal={saleListModal}
        setSaleListModal={setSaleListModal}
        socketRef={socketRef}
      />
    </BackendLayout>
  );
};

export default SalesTable;
