import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import http from "../../../services/backend/http";
const SettingTransferAddModel = (props) => {
  const { addModal, toggleAddModal, getSetting } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);

  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      ppid: yup.string().required("กรุณากรอกเลขพร้อมเพย์"),
      ppname: yup.string().required("กรุณากรอกชื่อพร้อมเพย์"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  const handleAdd = async (data) => {
    try {
      const newData = {
        ...data,
        branch_id: auth.branch.branch_id,
      };
      await http.post(`setting/settings/payment`, newData);
      getSetting();
      handleClickClose();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickClose = () => {
    reset();
    toggleAddModal();
  };

  return (
    <Modal show={addModal} onHide={toggleAddModal} backdrop="static">
      <form onSubmit={handleSubmit(handleAdd)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              <FormattedMessage id="modal.add" />
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <div>
                <Form.Check
                  type="switch"
                  id="switch_pp_toggle"
                  name="switch_pp_toggle"
                  //   onChange={() => togglePP()}
                  //   checked={settingPP}
                  {...register("pptype")}
                  label="เปิดใช้งาน"
                />
              </div>
              <div>
                <label className="form-label" htmlFor="ppid">
                  เลขพร้อมเพย์
                </label>
                <input
                  type="text"
                  id="ppid"
                  name="ppid"
                  className="form-control"
                  placeholder="เลขพร้อมเพย์"
                  {...register("ppid")}
                />
                <p className="text-danger mt-1">
                  {errors.ppid && errors.ppid.message}
                </p>
              </div>
              <div>
                <label className="form-label" htmlFor="ppname">
                  ชื่อพร้อมเพย์
                </label>
                <input
                  type="text"
                  id="ppname"
                  name="ppname"
                  className="form-control"
                  placeholder="ชื่อพร้อมเพย์"
                  {...register("ppname")}
                />
                <p className="text-danger mt-1">
                  {errors.ppname && errors.ppname.message}
                </p>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClickClose}>
            <FormattedMessage id="btn.close" />
          </Button>
          <Button variant="primary" type="submit" name="submit">
            <FormattedMessage id="btn.save" />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SettingTransferAddModel;
