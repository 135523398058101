import React, { useEffect, useState } from "react";
import BackendLayout from "../../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import commaNumber from "comma-number";
import http from "../../../../services/backend/http";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import socketIO from "socket.io-client";

const BalanceReport = (props) => {
  const { socketRef } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [totolDate, setTotalDate] = useState({ totalDays: 0, totalWork: 0 });
  const [tablePurchaseDetail, setTablePurchaseDetail] = useState(null);
  const [tableSalesDetail, setTableSalesDetail] = useState(null);
  const [isLoading, setIsLoading] = useState({
    purchaseTooltipLoading: false,
    purchaseTooltip: "",
    salesTooltipLoading: false,
    salesTooltip: "",
  });
  const [sdate, setSdate] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edate, setEdate] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );

  const [sdateSelect, setSdateSelect] = useState(
    "01/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const [edateSelect, setEdateSelect] = useState(
    new Date().getDate() +
      "/" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date().getFullYear()
  );
  const title = useIntl().formatMessage({ id: "menu.reports.balance" });
  const btnSearch = useIntl().formatMessage({ id: "btn.search" });
  const [reportSalesTable, setReportSalesTable] = useState(null);

  useEffect(() => {
    socketRef.current = socketIO.connect(process.env.REACT_APP_BASE_URL_IO);
    socketRef.current.on("order-done", (message) => {
      const { company_id, branch_id } = message;
      if (
        company_id === auth.newAuth.user.company_id &&
        branch_id === auth.branch.branch_id
      ) {
        // reportPreview();
        document.getElementById("reportPreview").click();
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const reportPreview = async () => {
    try {
      let sd = moment(sdateSelect, "DD-MM-YYYY");
      let ed = moment(edateSelect, "DD-MM-YYYY");

      const newData = {
        branch_id: auth.branch.branch_id,
        reportDate: "",
        sdateSelect: moment(sd).format("DD-MM-YYYY"),
        edateSelect: moment(ed).format("DD-MM-YYYY"),
      };

      const resp = await http.get(`report/sales/summary`, { params: newData });

      const respPurchase = await http.get(`report/purchase/summary/v2`, {
        params: newData,
      });

      let diffDay = ed.diff(sd, "days");

      setTotalDate({
        totalDays: diffDay + 1,
        totalWork: Object.keys(resp.data.data.amount).length,
      });

      let nums = [];
      for (let i = 0; i <= diffDay; i++) {
        let new_date = sd;

        const foundPurchase = respPurchase.data.data.total.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCost1 = respPurchase.data.data.cost1.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCost2 = respPurchase.data.data.cost2.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCost3 = respPurchase.data.data.cost3.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCost4 = respPurchase.data.data.cost4.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const found = resp.data.data.amount.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundCash = resp.data.data.cash.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundTransfer = resp.data.data.transfer.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        const foundFD = resp.data.data.fd.find((obj) => {
          return obj._id.date === moment(new_date).format("DD-MM-YYYY");
        });

        let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
          new Date(moment(new_date)).getDay()
        ];

        let newData1 = {
          dateweekday: `${moment(new_date).format("DD-MM-YYYY")} #${weekday}`,
          date: moment(new_date).format("DD-MM-YYYY"),
          purchase: foundPurchase ? foundPurchase.amount : 0,
          cost1: foundCost1 ? foundCost1.amount : 0,
          cost2: foundCost2 ? foundCost2.amount : 0,
          cost3: foundCost3 ? foundCost3.amount : 0,
          cost4: foundCost4 ? foundCost4.amount : 0,
          amount: found ? found.amount : 0,
          cash: foundCash ? foundCash.amount : 0,
          transfer: foundTransfer ? foundTransfer.amount : 0,
          fd: foundFD ? foundFD.amount : 0,
          cost: found ? found.cost : 0,
          profit: found ? found.amount - found.cost : 0,
        };

        nums.push(newData1);
        new_date = sd.add(1, "days");
      }

      setReportSalesTable(nums);
    } catch (error) {
      console.log(error);
    }
  };

  const getPurchaseDetail = async (date) => {
    const newData = {
      branch_id: auth.branch.branch_id,
      date: date,
    };
    let dateNew = "";
    setTablePurchaseDetail(null);
    if (isLoading.purchaseTooltip !== date) {
      dateNew = date;

      const resp = await http.get(`report/purchase/pidatedetailbydate`, {
        params: newData,
      });

      setTablePurchaseDetail(resp.data.data);
    }
    let newIsLoading = { ...isLoading, purchaseTooltip: dateNew };

    setIsLoading(newIsLoading);
  };

  const getSalesDetail = async (date) => {
    const newData = {
      branch_id: auth.branch.branch_id,
      reportDate: "D",
      sdateSelect: date,
    };
    let dateNew = "";
    setTableSalesDetail(null);

    if (isLoading.salesTooltip !== date) {
      dateNew = date;
      const resp = await http.get(`report/sales/summary/detail`, {
        params: newData,
      });

      setTableSalesDetail(resp.data.data);
    }

    let newIsLoading = { ...isLoading, salesTooltip: dateNew };

    setIsLoading(newIsLoading);
  };

  const getDateSelect = (start, end, label) => {
    const ddStart = start._d.getDate().toString().padStart(2, "0");
    const mmStart = (start._d.getMonth() + 1).toString().padStart(2, "0");
    const yyStart = start._d.getFullYear();
    const dateStart = `${ddStart}-${mmStart}-${yyStart}`;

    const ddEnd = end._d.getDate().toString().padStart(2, "0");
    const mmEnd = (end._d.getMonth() + 1).toString().padStart(2, "0");
    const yyEnd = end._d.getFullYear();
    const dateEnd = `${ddEnd}-${mmEnd}-${yyEnd}`;

    setSdate(start);
    setEdate(end);
    setSdateSelect(dateStart);
    setEdateSelect(dateEnd);
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3">
          <div className="row">
            <div className="col">
              <form>
                <div className="d-flex">
                  <div className="col-4">
                    <DateRangePicker
                      initialSettings={{
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                        startDate: sdate,
                        endDate: edate,
                      }}
                      //   onEvent={(event, picker) => console.log(picker.startDate)}
                      onCallback={(start, end, label) =>
                        getDateSelect(start, end, label)
                      }
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </div>
                  <div className="col-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        //   placeholder={`${btnSearch} : ${tbItem_id} ${tbItem_name}  ${tbItem_barcode}`}
                        //   onChange={(e) => setSearchText(e.target.value)}
                        //   value={searchText}
                      />
                      <div className="input-group-append ms-2 mb-1 mt-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-success me-2"
                          id="reportPreview"
                          onClick={() => reportPreview()}
                        >
                          {btnSearch}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="table-responsive">
                <table
                  className="table table-striped mb-3 table-hover"
                  style={{ userSelect: "none" }}
                >
                  <thead>
                    <tr
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "black",
                        borderTopStyle: "solid",
                      }}
                    >
                      <th rowSpan="2" className="align-middle">
                        วันที่
                      </th>

                      <th className="text-center bg-light" colSpan="5">
                        รายรับ
                      </th>
                      <th className="text-center bg-light" colSpan="5">
                        รายจ่าย
                      </th>
                      <th
                        className="text-center align-middle bg-primary text-white"
                        rowSpan="2"
                      >
                        คงเหลือ
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center bg-light">สด</th>
                      <th className="text-center bg-light">โอน</th>
                      <th className="text-center bg-light">หน้าร้าน</th>
                      <th className="text-center bg-light">FD</th>
                      <th className="text-center bg-success text-white">
                        รายรับ
                      </th>
                      <th className="text-center bg-light">วัตถุดิบ</th>
                      <th className="text-center bg-light">วัตถุดิบ 1</th>
                      <th className="text-center bg-light">สาธารณูปโภค</th>
                      <th className="text-center bg-light">เงินเดือน</th>
                      <th className="text-center bg-secondary text-white">
                        รายจ่าย
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportSalesTable &&
                      reportSalesTable.map((data, index) => (
                        <tr key={index} style={{ cursor: "pointer" }}>
                          <td>{`${index + 1}) ${data.dateweekday}`}</td>
                          {/* รายรับ */}
                          <td className="text-center bg-light">
                            {commaNumber(data.cash)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.transfer)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.amount - data.fd)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.fd)}
                          </td>
                          <td
                            className="text-center bg-success text-white"
                            onClick={() => getSalesDetail(data.date)}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              trigger="click"
                              overlay={
                                isLoading &&
                                isLoading.salesTooltip === data.date ? (
                                  <Tooltip>
                                    <table>
                                      <thead></thead>
                                      <tbody>
                                        {tableSalesDetail &&
                                          tableSalesDetail.map(
                                            (data, index) => (
                                              <tr key={index}>
                                                <td align="left">
                                                  {data._id.item_name}
                                                </td>
                                                <td>{`${
                                                  data.unitQty
                                                }x${commaNumber(
                                                  data._id.unitPrice
                                                )}`}</td>
                                                <td>
                                                  =
                                                  {commaNumber(
                                                    data.unitPriceTotal
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                      <tfoot></tfoot>
                                    </table>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <span>{commaNumber(data.amount)}</span>
                            </OverlayTrigger>
                          </td>

                          <td className="text-center bg-light">
                            {commaNumber(data.cost1)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.cost4)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.cost2)}
                          </td>
                          <td className="text-center bg-light">
                            {commaNumber(data.cost3)}
                          </td>

                          <td
                            onClick={() => getPurchaseDetail(data.date)}
                            className="bg-secondary text-white text-center"
                          >
                            <OverlayTrigger
                              placement="bottom"
                              trigger="click"
                              overlay={
                                isLoading &&
                                isLoading.purchaseTooltip === data.date ? (
                                  <Tooltip>
                                    <table>
                                      <thead></thead>
                                      <tbody>
                                        {tablePurchaseDetail &&
                                          tablePurchaseDetail.map(
                                            (data, index) => (
                                              <tr key={index}>
                                                <td align="left">
                                                  {data._id.item_name}
                                                </td>
                                                <td>{`${
                                                  data.unitQty
                                                }x${commaNumber(
                                                  data._id.unitPrice
                                                )}`}</td>
                                                <td>
                                                  =
                                                  {commaNumber(data.unitAmount)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                      <tfoot></tfoot>
                                    </table>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <span>{commaNumber(data.purchase)}</span>
                            </OverlayTrigger>
                          </td>
                          <td
                            className={
                              data.amount - data.purchase >= 0
                                ? "text-center bg-primary text-white"
                                : "text-center bg-danger text-white"
                            }
                          >
                            {commaNumber(data.amount - data.purchase)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th rowSpan="2" className="align-middle">
                        รวม
                      </th>
                      <th className="text-center bg-light">สด</th>
                      <th className="text-center bg-light">โอน</th>
                      <th className="text-center bg-light">หน้าร้าน</th>
                      <th className="text-center bg-light">FD</th>
                      <th className="text-center bg-success text-white">
                        รายรับ
                      </th>
                      <th className="text-center bg-light">วัตถุดิบ</th>
                      <th className="text-center bg-light">วัตถุดิบ 1</th>
                      <th className="text-center bg-light">สาธารณูปโภค</th>
                      <th className="text-center bg-light">เงินเดือน</th>
                      <th className="text-center bg-secondary text-white">
                        รายจ่าย
                      </th>
                      <th className="text-center bg-primary text-white">
                        {reportSalesTable &&
                          `${commaNumber(
                            parseFloat(
                              reportSalesTable.reduce(
                                (a, v) => (a = a + v.amount - v.purchase),
                                0
                              ) /
                                reportSalesTable.reduce(
                                  (a, v) => (a = a + v.amount),
                                  0
                                )
                            ).toFixed(2)
                          )}%`}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.cash),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.transfer),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            ) -
                              reportSalesTable.reduce(
                                (a, v) => (a = a + v.fd),
                                0
                              )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce((a, v) => (a = a + v.fd), 0)
                          )}
                      </th>
                      <th className="text-center bg-success text-white">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.cost1),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.cost4),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.cost2),
                              0
                            )
                          )}
                      </th>
                      <th className="text-center bg-light">
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.cost3),
                              0
                            )
                          )}
                      </th>
                      <th className="bg-secondary text-white text-center">
                        {reportSalesTable &&
                          `${commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.purchase),
                              0
                            )
                          )}`}
                      </th>
                      <th
                        //className="text-center bg-primary text-white"
                        className={
                          reportSalesTable
                            ? reportSalesTable.reduce(
                                (a, v) => (a = a + v.amount),
                                0
                              ) -
                                reportSalesTable.reduce(
                                  (a, v) => (a = a + v.purchase),
                                  0
                                ) >=
                              0
                              ? "text-center bg-primary text-white"
                              : "text-center bg-danger text-white"
                            : "text-center bg-primary text-white"
                        }
                      >
                        {reportSalesTable &&
                          commaNumber(
                            reportSalesTable.reduce(
                              (a, v) => (a = a + v.amount),
                              0
                            ) -
                              reportSalesTable.reduce(
                                (a, v) => (a = a + v.purchase),
                                0
                              )
                          )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default BalanceReport;
