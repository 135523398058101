/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuItems from "../../../constants/menus/menu";
import menuAdminItems from "../../../constants/menus/menuadmin";
import SidebarJS from "./SidebarJS";
import { useSelector } from "react-redux";
const Sidebar = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [menu, setMenu] = useState(null);
  useEffect(() => {
    SidebarJS.activeMenu(location.pathname);

    if (auth.newAuth.user.role === "user") {
      setMenu(menuItems);
    }
    if (auth.newAuth.user.role === "admin") {
      setMenu(menuAdminItems);
    }
  }, []);

  return (
    <>
      {/*sidebar wrapper */}
      <div
        onMouseEnter={SidebarJS.toggleMenuHover}
        onMouseLeave={SidebarJS.toggleMenuLeave}
        className="sidebar-wrapper"
        data-simplebar="true"
      >
        <div className="sidebar-header">
          <div>
            <img
              src="/assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text fw-bold">POS</h4>
          </div>
          <div onClick={SidebarJS.toggleMenu} className="toggle-icon ms-auto">
            <i className="bx bx-arrow-to-left" />
          </div>
        </div>
        {/*navigation*/}
        <ul className="metismenu" id="menu">
          {menu &&
            menu.map((data) =>
              data.subs ? (
                <li
                  id="menutitle"
                  key={data.id}
                  onClick={SidebarJS.toggleMenuDropDown}
                >
                  <a id="hasArrow" className="has-arrow" aria-expanded="false">
                    <div className="parent-icon">
                      <i className={data.icon} />
                    </div>
                    <div className="menu-title">{data.label}</div>
                  </a>
                  {data.subs && (
                    <ul className="mm-collapse">
                      {data.subs.map((sub) => (
                        <li key={sub.id}>
                          <NavLink to={sub.to}>
                            <i className={sub.icon} />
                            {sub.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ) : (
                <li id="menutitle" key={data.id}>
                  <NavLink to={data.to}>
                    <div className="parent-icon">
                      <i className={data.icon} />
                    </div>
                    <div className="menu-title">{data.label}</div>
                  </NavLink>
                </li>
              )
            )}
        </ul>
        {/*end navigation*/}
      </div>
      {/*end sidebar wrapper */}
    </>
  );
};

export default Sidebar;
