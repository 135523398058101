export const GET_AUTH = 'GET_AUTH'

export const updateAuth = (auth) => {
    return {
        type:GET_AUTH,
        payload:{            
            auth:auth
        }
    }

}