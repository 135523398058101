import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { FormattedMessage } from "react-intl";
import http from "../../../../services/backend/http";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UnitEditModal from "./UnitEditModal";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
const UnitIndexModal = (props) => {
  const {
    unitModal,
    setUnitModal,
    toggleUnitModal,
    tableItemUnit,
    getItemUnit,
  } = props;
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [unitEditModal, setUnitEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });
  const tbItem_unit = useIntl().formatMessage({ id: "tb.item_unit" });
  const btnManage = useIntl().formatMessage({ id: "btn.manage" });
  const tbItem_yup_itemUnit = useIntl().formatMessage({
    id: "tb.item_yup_itemUnit",
  });

  //---- yub Start ---------------------------//
  const schema = yup
    .object()
    .shape({
      nameGroup: yup.string().required(tbItem_yup_itemUnit),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //---- yub End ---------------------------//

  ///---- Function Start------------------------------------//

  const handleAdd = async (data) => {
    try {
      const body = {
        name: data.nameGroup,
        branch_id: auth.branch.branch_id,
      };
      await http.post("item_unit", body);
      await getItemUnit();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      const result = await Swal.fire({
        title: swalDelete,
        // text: "ยืนยันการลบรายการนี้",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: btnConfrim,
        cancelButtonText: btnCancel,
      });
      if (result.isConfirmed) {
        try {
          const newData = {
            branch_id: auth.branch.branch_id,
          };

          await http.delete(`item_unit/${id}`, { params: newData });
          Swal.fire({
            icon: "success",
            title: "ลบรายการเรียบร้อย",
            timer: 1500,
          });

          getItemUnit();
        } catch (error) {
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: error.response.data.message,
              text: error.message,
              timer: 2500,
            });
          }
        }
      }
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  };

  const handleClickClose = () => {
    reset();
    toggleUnitModal();
  };

  //---- ToggleAddModal Start ----//
  const toggleUnitEditModal = (data) => {
    setEditData(data);
    setUnitEditModal(!unitEditModal);
  };
  ///---- Function End------------------------------------//

  ///---- Element Start-----------------------------------//
  const TableElement = () => {
    return (
      tableItemUnit &&
      tableItemUnit.map((item, index) => (
        <tr key={index} className="text-wrap">
          <td>{item.name}</td>
          <td style={{ textAlign: "right" }}>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => toggleUnitEditModal(item)}
            >
              <FormattedMessage id="btn.edit" />
            </button>
            &nbsp;
            <button
              // href="#"
              className="btn btn-sm btn-danger"
              onClick={() => handleDeleteClick(item.id)}
              disabled={
                auth && auth.newAuth.user.role === "user" ? true : false
              }
            >
              <FormattedMessage id="btn.delete" />
            </button>
          </td>
        </tr>
      ))
    );
  };
  ///---- Element End-----------------------------------//

  return (
    <>
      <MDBModal
        staticBackdrop
        show={unitModal}
        setShow={setUnitModal}
        tabIndex="-1"
      >
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <strong>{tbItem_unit}</strong>
              </MDBModalTitle>
              <a
                className="btn-close"
                color="none"
                onClick={handleClickClose}
              ></a>
            </MDBModalHeader>

            <MDBModalBody>
              <form onSubmit={handleSubmit(handleAdd)}>
                <div className="input-group">
                  <input
                    type="text"
                    id="nameGroup"
                    name="nameGroup"
                    className="form-control"
                    placeholder={tbItem_unit}
                    {...register("nameGroup")}
                  />

                  <div className="input-group-append ms-2 mb-1 mt-1">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2"
                      disabled={
                        auth && auth.newAuth.user.role === "user" ? true : false
                      }
                    >
                      <FormattedMessage id="btn.add" />
                    </button>
                  </div>
                </div>
                <p className="text-danger mt-1">
                  {errors.nameGroup && errors.nameGroup.message}
                </p>
              </form>
              <div className="table-responsive mt-2">
                <table className="table mb-3 table-hover">
                  <thead>
                    <tr>
                      <th className="cursor-pointer">{tbItem_unit}</th>
                      <th style={{ textAlign: "right" }}>{btnManage}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableElement />
                  </tbody>
                </table>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <a
                className="btn btn-sm btn-secondary"
                onClick={handleClickClose}
              >
                <FormattedMessage id="btn.close" />
              </a>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <UnitEditModal
        unitEditModal={unitEditModal}
        setUnitEditModal={setUnitEditModal}
        toggleUnitEditModal={toggleUnitEditModal}
        editData={editData}
        getItemUnit={getItemUnit}
      />
    </>
  );
};

export default UnitIndexModal;
