import React from "react";
import Sidebar from "../../shared/backend/Sidebar";
import Navbar from "../../shared/backend/Navbar";
import Footer from "../../shared/backend/Footer";
import { Helmet } from "react-helmet";

const BackendLayout = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div id="wrapper" className="wrapper">
        <Sidebar />
        <Navbar />
        <div className="page-wrapper">
          <div className="page-content">{children}</div>
        </div>
        {/*start overlay*/}
        <div className="overlay toggle-icon" />
        {/*end overlay*/}
        {/*Start Back To Top Button*/}

        <a href="#" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
        {/*End Back To Top Button*/}
        <Footer />
      </div>
    </>
  );
};

export default BackendLayout;
