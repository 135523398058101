import { GET_COMPANY } from "../actions/AuthCompanyAction";

const initState = {
  company: null,
};

const AuthCompanyReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return {
        company: action.payload.company,
      };
    default:
      return state;
  }
};

export default AuthCompanyReducer;
