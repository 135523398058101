import React, { useEffect, useState } from "react";
import BackendLayout from "../../../../components/layouts/backend/BackendLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import http from "../../../../services/backend/http";
import commaNumber from "comma-number";
import moment from "moment/moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import socketIO from "socket.io-client";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
//import socket from "../../../../services/socket/socket";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels
);

//const socket = socketIO.connect("http://localhost:4001");

const Dashboard = (props) => {
  const { socketRef } = props;

  const btnConfrim = useIntl().formatMessage({ id: "btn.confrim" });
  const btnCancel = useIntl().formatMessage({ id: "btn.cancel" });
  const swalDelete = useIntl().formatMessage({ id: "swal.delete" });

  const title = useIntl().formatMessage({ id: "menu.reports.dashboards" });
  const auth = useSelector((state) => state.AuthReducer.auth);
  const [startDate, setStartDate] = useState(new Date());
  const [reportTableDetailSelect, setReportTableDetailSelect] = useState(null);
  const [reportTable, setReportTable] = useState(null);
  const [reportTableDetail, setReportTableDetail] = useState(null);
  const [dataSummary, setDataSummary] = useState({
    countOrder: 0,
    avgOrder: 0,
    amtOrder: 0,
  });
  const [toggleFD, setToggleFD] = useState(false);
  const [dataGraph, setDataGraph] = useState({
    labels: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  });

  const [dataStep, setDataStep] = useState({
    max: 0,
    step: 0,
  });

  useEffect(() => {
    socketRef.current = socketIO.connect(process.env.REACT_APP_BASE_URL_IO);
    socketRef.current.on("order-done", (message) => {
      const { company_id, branch_id } = message;
      if (
        company_id === auth.newAuth.user.company_id &&
        branch_id === auth.branch.branch_id
      ) {
        getReportInv();
        getReportHours();
        setReportTableDetail(null);
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    // getReport();
    getReportInv();
    getReportHours();
    setReportTableDetail(null);
  }, [toggleFD, startDate]);

  // Call API

  const getReportInv = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
      sdateSelect: moment(startDate).format("DD-MM-YYYY"),
      toggleFD: toggleFD,
    };

    const resp = await http.get(`report/sales/invdate`, {
      params: newData,
    });

    setReportTable(resp.data.data);

    const countOrder = Object.keys(resp.data.data).length;
    const amtOrder = resp.data.data
      ? resp.data.data.reduce((a, v) => (a = a + v.amount), 0)
      : 0;
    const avgOrder = countOrder > 0 ? Math.round(amtOrder / countOrder, 0) : 0;
    const data = {
      countOrder: countOrder,
      avgOrder: avgOrder,
      amtOrder: amtOrder,
    };

    setDataSummary(data);
  };

  const getReportInvDetail = async (inv) => {
    setReportTableDetailSelect(inv);

    const newData = {
      branch_id: auth.branch.branch_id,
      inv: inv,
    };

    const resp = await http.get(`report/sales/invdatedetail`, {
      params: newData,
    });

    setReportTableDetail(resp.data.data);
  };
  const getReport = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
      sdateSelect: moment(startDate).format("DD-MM-YYYY"),
      toggleFD: toggleFD,
    };

    const resp = await http.get(`report/sales/summarydate`, {
      params: newData,
    });

    const countOrder = Object.keys(resp.data.data).length;
    const amtOrder = resp.data.data
      ? resp.data.data.reduce((a, v) => (a = a + v.amount), 0)
      : 0;
    const avgOrder = countOrder > 0 ? Math.round(amtOrder / countOrder, 0) : 0;
    const data = {
      countOrder: countOrder,
      avgOrder: avgOrder,
      amtOrder: amtOrder,
    };

    setDataSummary(data);
  };

  const getReportHours = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
      sdateSelect: moment(startDate).format("DD-MM-YYYY"),
      toggleFD: toggleFD,
    };

    const resp = await http.get(`report/sales/summarydatedetail`, {
      params: newData,
    });

    calScales(Math.max(...resp.data.data.map((o) => o.amount)));
    let nums = [];
    for (let i = 0; i < 24; i++) {
      const hh = i.toString().padStart(2, "0");

      const found = resp.data.data.find((obj) => {
        return obj._id.hh === hh;
      });

      const newData1 = found ? found.amount : 0;
      nums.push(newData1);
    }

    let items = { ...dataGraph };
    let item = { ...items };
    item.data = nums;

    items = item;
    setDataGraph(items);
  };

  const calScales = (maxValue) => {
    const addValue = 100 - (maxValue % 100);
    const newValue = maxValue + addValue + 100;

    let scales = {
      max: 0,
      step: 0,
    };

    if (maxValue > 0) {
      scales = {
        max: newValue,
        step: newValue / 4,
      };
    }
    setDataStep(scales);
  };

  const handleClickFDToggle = () => {
    setToggleFD(!toggleFD);
  };

  const removeRecord = (id) => {
    Swal.fire({
      title: `${swalDelete}`,
      html: `${id}`,
      // text: "ยืนยันการลบรายการนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnConfrim,
      cancelButtonText: btnCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = {
          branch_id: auth.branch.branch_id,
        };

        http
          .delete(`salesline/inv/${id}`, { params: newData })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "ลบรายการเรียบร้อย",
              timer: 1500,
            });

            getReportInv();
            getReportHours();
            setReportTableDetail(null);
          })
          .catch((error) => {
            if (error) {
              if (error.response) {
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  text: error.message,
                  timer: 2500,
                });
              }
            }
          });
      }
    });

    // console.log(saleTable[index].item);
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="row row-cols-2 row-cols-md-4 row-cols-xl-4">
        <div className="col fw-bold">
          <div className="card radius-10 bg-info bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">วันที่</p>
                  <div className="my-1 text-white fw-bold">
                    <DatePicker
                      className="form-control"
                      todayButton="Today"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-warning bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">ออเดอร์</p>
                  <h4 className="my-1 text-white fw-bold">
                    {commaNumber(dataSummary.countOrder)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-success bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เฉลี่ย/บิล</p>
                  <h4 className="my-1 text-white fw-bold">
                    {commaNumber(dataSummary.avgOrder)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col fw-bold">
          <div className="card radius-10 bg-primary bg-gradient">
            <div className="card-body">
              <div className="text-end">
                <div>
                  <p className="mb-0 text-white font-18">เงินรวม</p>
                  <h4 className="my-1 text-white fw-bold">
                    {commaNumber(dataSummary.amtOrder)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {auth && auth.newAuth.user.role === "admin" && (
        <div className="row">
          <div>
            <Form.Check
              type="switch"
              id="switch_fd_toggle"
              name="switch_fd_toggle"
              label="Food Delivery"
              onClick={handleClickFDToggle}
              defaultChecked={toggleFD}
            />
          </div>
        </div>
      )}
      <div className="row">
        <Line
          options={{
            scales: {
              yAxis: {
                title: {
                  display: true,
                  text: "จำนวนเงิน",
                },
                ticks: {
                  stepSize: dataStep.step,
                },
                min: 0,
                max: dataStep.max,
              },
              xAxis: {
                title: {
                  display: true,
                  text: "ชั่วโมง",
                },
              },
            },
            responsive: true,
            plugins: {
              datalabels: {
                display: true,
                color: "black",
                formatter: (v) => (v ? commaNumber(Math.round(v)) : ""),
                anchor: "end",
                offset: -30,
                align: "start",
              },
              legend: {
                position: "top",
              },
              title: {
                display: false,
                text: "Chart.js Line Chart",
              },
            },
          }}
          data={{
            labels: dataGraph.labels,
            datasets: [
              {
                fill: true,
                label: "ขาย",
                data: dataGraph.data,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          }}
          height={100}
        />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
          <div className="table-responsive">
            <table
              className="table table-striped table-hover"
              style={{ userSelect: "none" }}
            >
              <thead>
                <tr>
                  <th className="col-1">ลำดับ</th>
                  <th className="col-2">เวลา</th>
                  <th className="col-4">เลขเอกสาร</th>
                  <th className="col-2">ประเภท</th>
                  <th className="col-2">จำนวนเงิน</th>
                  {auth && auth.newAuth.user.role === "admin" && (
                    <th className="col-1"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {reportTable &&
                  reportTable.map((data, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => getReportInvDetail(data._id.doc_no)}
                    >
                      <td>{`${index + 1} -> ${data._id.table_no}`}</td>
                      <td>{data._id.time}</td>
                      <td>{data._id.doc_no}</td>
                      <td>{data._id.payment}</td>
                      <td>{commaNumber(data.amount)}</td>
                      {auth && auth.newAuth.user.role === "admin" && (
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => removeRecord(data._id.doc_no)}
                          >
                            ลบ
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>จำนวนเงินรวม</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    {reportTable
                      ? commaNumber(
                          reportTable.reduce((a, v) => (a = a + v.amount), 0)
                        )
                      : 0}
                  </th>
                  {auth && auth.newAuth.user.role === "admin" && <th></th>}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
          <div className="table-responsive">
            <table
              className="table table-striped table-hover"
              style={{ userSelect: "none" }}
            >
              <thead>
                <tr>
                  <th className="col-2">{reportTableDetailSelect}</th>
                  <th className="col-4">รายการ</th>
                  <th className="col-4">จำนวน</th>
                  <th className="col-2">รายรับ</th>
                </tr>
              </thead>
              <tbody>
                {reportTableDetail &&
                  reportTableDetail.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data._id.item_name}</td>
                      <td>{`${data.unitQty} x ${data._id.unitPrice}`}</td>
                      <td>{commaNumber(data.unitPriceTotal)}</td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>จำนวนเงินรวม</th>
                  <th></th>
                  <th></th>
                  <th>
                    {reportTableDetail &&
                      commaNumber(
                        reportTableDetail.reduce(
                          (a, v) => (a = a + v.unitPriceTotal),
                          0
                        )
                      )}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default Dashboard;
