import { GET_SETTING } from "../actions/SettingAction";

const initState = {
  setting: null,
};

const SettingReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SETTING:
      return {
        setting: action.payload.setting,
      };
    default:
      return state;
  }
};

export default SettingReducer;
