import { useState, useEffect } from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import http from "../../../services/backend/http";

const SettingDocument = () => {
  const auth = useSelector((state) => state.AuthReducer.auth);
  const title = useIntl().formatMessage({ id: "menu.setting.document" });
  const [isLoading, setIsLoading] = useState(false);
  const [settingDocITEM, setSettingDocITEM] = useState(null);
  const [settingDocINV, setSettingDocINV] = useState(null);
  const [settingDocSO, setSettingDocSO] = useState(null);
  const [settingDocPI, setSettingDocPI] = useState(null);

  useEffect(() => {
    getSetting();
  }, []);
  const getSetting = async () => {
    const newData = {
      branch_id: auth.branch.branch_id,
    };
    const resp = await http.get(`setting/doc`, { params: newData });
    if (Object.keys(resp.data.data).length > 0) {
      const foundITEM = resp.data.data.find((obj) => {
        return obj.doc_name === "item";
      });
      const foundINV = resp.data.data.find((obj) => {
        return obj.doc_name === "sales";
      });
      const foundSO = resp.data.data.find((obj) => {
        return obj.doc_name === "so";
      });
      const foundPI = resp.data.data.find((obj) => {
        return obj.doc_name === "purchase";
      });

      setSettingDocITEM(foundITEM);
      setSettingDocINV(foundINV);
      setSettingDocSO(foundSO);
      setSettingDocPI(foundPI);
    }
  };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <div className="card">
        <div className="card-header mt-3 fs-5">{title}</div>

        <div className="card-body">
          <div className="row g-3">
            <div className="col-sm-12 px-5">
              <div className="row">
                <div className="card">
                  <div className="card-header mt-3 fs-5">รหัสสินค้า</div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-3">
                        <label htmlFor="doc_prefix">รหัสขึ้นต้น</label>
                        <input
                          id="doc_prefix"
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            settingDocITEM && settingDocITEM.doc_prefix
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_digit">จำนวนตัวอักษร</label>
                        <input
                          disabled
                          id="doc_digit"
                          min={0}
                          type="number"
                          className="form-control"
                          defaultValue={
                            settingDocITEM && settingDocITEM.doc_digit
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_type">รูปแบบ (ปีเดือน)</label>
                        <input
                          id="doc_type"
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            settingDocITEM &&
                            `${settingDocITEM.doc_year}${settingDocITEM.doc_month}`
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_sample">ตัวอย่าง</label>
                        <input
                          id="doc_sample"
                          type="text"
                          disabled
                          className="form-control"
                          defaultValue={
                            settingDocITEM && settingDocITEM.doc_sample
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header mt-3 fs-5">รหัสจองขาย</div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-3">
                        <label htmlFor="doc_prefix">รหัสขึ้นต้น</label>
                        <input
                          disabled
                          id="doc_prefix"
                          type="text"
                          className="form-control"
                          defaultValue={settingDocSO && settingDocSO.doc_prefix}
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_digit">จำนวนตัวอักษร</label>
                        <input
                          disabled
                          id="doc_digit"
                          min={0}
                          type="number"
                          className="form-control"
                          defaultValue={settingDocSO && settingDocSO.doc_digit}
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_type">รูปแบบ (ปีเดือน)</label>
                        <input
                          id="doc_type"
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            settingDocSO &&
                            `${settingDocSO.doc_year}${settingDocSO.doc_month}`
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_sample">ตัวอย่าง</label>
                        <input
                          id="doc_sample"
                          type="text"
                          disabled
                          className="form-control"
                          defaultValue={
                            settingDocSO &&
                            `${settingDocSO.doc_prefix}${settingDocSO.doc_year}${settingDocSO.doc_month}${settingDocSO.doc_sample}`
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header mt-3 fs-5">รหัสขาย</div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-3">
                        <label htmlFor="doc_prefix">รหัสขึ้นต้น</label>
                        <input
                          disabled
                          id="doc_prefix"
                          type="text"
                          className="form-control"
                          defaultValue={
                            settingDocINV && settingDocINV.doc_prefix
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_digit">จำนวนตัวอักษร</label>
                        <input
                          disabled
                          id="doc_digit"
                          min={0}
                          type="number"
                          className="form-control"
                          defaultValue={
                            settingDocINV && settingDocINV.doc_digit
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_type">รูปแบบ (ปีเดือน)</label>
                        <input
                          id="doc_type"
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            settingDocINV &&
                            `${settingDocINV.doc_year}${settingDocSO.doc_month}`
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_sample">ตัวอย่าง</label>
                        <input
                          id="doc_sample"
                          type="text"
                          disabled
                          className="form-control"
                          defaultValue={
                            settingDocINV &&
                            `${settingDocINV.doc_prefix}${settingDocINV.doc_year}${settingDocINV.doc_month}${settingDocINV.doc_sample}`
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header mt-3 fs-5">รหัสซื้อ</div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-3">
                        <label htmlFor="doc_prefix">รหัสขึ้นต้น</label>
                        <input
                          disabled
                          id="doc_prefix"
                          type="text"
                          className="form-control"
                          defaultValue={settingDocPI && settingDocPI.doc_prefix}
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_digit">จำนวนตัวอักษร</label>
                        <input
                          disabled
                          id="doc_digit"
                          min={0}
                          type="number"
                          className="form-control"
                          defaultValue={settingDocPI && settingDocPI.doc_digit}
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_type">รูปแบบ (ปีเดือน)</label>
                        <input
                          id="doc_type"
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            settingDocPI &&
                            `${settingDocPI.doc_year}${settingDocPI.doc_month}`
                          }
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="doc_sample">ตัวอย่าง</label>
                        <input
                          id="doc_sample"
                          type="text"
                          disabled
                          className="form-control"
                          defaultValue={
                            settingDocPI &&
                            `${settingDocPI.doc_prefix}${settingDocPI.doc_year}${settingDocPI.doc_month}${settingDocPI.doc_sample}`
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackendLayout>
  );
};

export default SettingDocument;
