import { GET_LANG } from '../actions/LangAction'

const initState = {
    lang:'EN'
}

const LangReducer = (state = initState, action) => {
    switch (action.type){
        case GET_LANG:
            return {
                lang: action.payload.lang
            }
        default:
            return state
    }
    
}

export default LangReducer