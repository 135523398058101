import React from "react";
import BackendLayout from "../../../components/layouts/backend/BackendLayout";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Setting = () => {
  const title = useIntl().formatMessage({ id: "menu.setting" });

  //---- yub Start ---------------------------//
  const schema = yup
    .object({
      staff_username: yup.string().required("ชื่อสินค้า"),
      product_description: yup.string().required("รายละเอียดสินค้า"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAdd = (data) => {
    console.log(data);
  };
  //---- yub End ---------------------------//

  // const handleClickClose = () => {
  //   reset();
  // };

  return (
    <BackendLayout title={`${title} | ${process.env.REACT_APP_NAME}`}>
      <h1 className="h3 mb-3">Setting Page</h1>
      <form onSubmit={handleSubmit(handleAdd)}>
        <div className="form-outline mb-4 text-start">
          <label className="form-label" htmlFor="staff_username">
            Username
          </label>
          <input
            type="text"
            id="staff_username"
            name="staff_username"
            {...register("staff_username")}
            className="form-control form-control"
            placeholder="Username"
          />
          <p className="text-danger mt-1">
            {errors.staff_username && errors.staff_username.message}
          </p>
        </div>
        <div className="form-outline mb-2 text-start">
          <label className="form-label" htmlFor="product_description">
            Description
          </label>
          <input
            type="text"
            id="product_description"
            name="product_description"
            className="form-control"
            placeholder="Description"
            {...register("product_description")}
          />
          <p className="text-danger mt-1">
            {errors.product_description && errors.product_description.message}
          </p>
        </div>
        <a
          className="btn btn-sm btn-secondary"
          onClick={() => reset()}
          //onClick={handleClickClose}
        >
          Close
        </a>

        <button className="btn btn-sm btn-primary" type="submit" name="submit">
          Save
        </button>
      </form>
    </BackendLayout>
  );
};

export default Setting;
